import {customElement} from "lit/decorators.js";
import Styles from "./sectorIdentifier.lit.scss";
import {html, LitElement, type TemplateResult} from "lit";
import {NavigationRootItem, NavModel} from "./common/navModel";
import {resolve} from "../../../container";

@customElement("eop-sector-identifier")
export class EopSectorIdentifier extends LitElement {

    public static readonly styles = Styles;

    private navRoot: NavigationRootItem;

    public constructor(navModel: NavModel = resolve(NavModel)) {
        super();
        this.navRoot = navModel.makeNavRoot();
    }

    public render(): TemplateResult | null {
        const activeSector = this.navRoot.getActiveSector();
        const sectorIdentifier = activeSector?.sectorIdentifier;

        if (!sectorIdentifier) {
            return null;
        }

        return html`
            <a class="link logo-sector" data-eventelement="logo-sector" href=${activeSector.href} title=${sectorIdentifier}>
                <div class="sector-identifier">${sectorIdentifier}</div>
            </a>
        `;
    }
}