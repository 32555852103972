export type RenewableNumberResponse = {
    value: number;
};

export class RenewableNumberRequest {
    public constructor(
        public technology: string,
        public unit: string,
        public precision: number
    ) {
    }
}