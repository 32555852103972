import type {PropertyMap} from "../../common/utils/objects";
import {divContaining} from "../../common/utils/html";
import type {EopSurveyStatistics} from "./surveyStatistics";
import {Dictionary} from "../../page/elements/dictionary";
import {schedule} from "../../common/utils/promises";


export type QuestionCoordinates<OPTIONS extends PropertyMap<string> = PropertyMap<string>> = {
    id: number;
    text: string;
    topic?: string;
    options: OPTIONS;
};

export interface SurveyNavigation {
    answerAndGetStatistics: (questionId: number, choice: string) => Promise<StatisticsPerOption>;

    skipQuestion: (questionId: number) => Promise<void>;

    nextQuestion: () => Promise<void>;
}

export type ElementParams = {
    overviewLink?: string;
};

export type StatisticsPerOption = PropertyMap<{
    optionText: string;
    absolute: number;
    percentage: number;
}>;

export abstract class Question<OPTIONS extends PropertyMap<string> = PropertyMap<string>> {
    protected constructor(public readonly coordinates: QuestionCoordinates<OPTIONS>) {
    }

    public abstract createInteractableElement(navigation: SurveyNavigation, params: ElementParams): SurveyAbstractQuestionElement;

    public abstract createStatisticsElement(statisticsPerOption: StatisticsPerOption): EopSurveyStatistics;
}

export abstract class SurveyAbstractQuestionElement<OPTIONS extends PropertyMap<string> = PropertyMap<string>> extends HTMLElement {
    protected promptElement: Element;

    protected constructor(
        private createPromptContent: () => Element,
        private createResultContent: (data: StatisticsPerOption) => Element,
        protected navigation: SurveyNavigation,
        protected questionData: QuestionCoordinates<OPTIONS>,
        private params: ElementParams
    ) {
        super();
    }

    public connectedCallback(): void {
        this.configureContainers();
        this.specificConnectedCallback();
    }

    private configureContainers(): void {
        const dictionary = Dictionary.of(this);
        const rootElement = divContaining(`
            <div class="survey-question-topic">${this.questionData.topic ?? ""}</div>
            <div class="survey-question-text">${this.questionData.text ?? ""}</div>
            <form class="survey-prompt">
                <div class="survey-controls">
                    <button class="link detail-link survey-skip survey-control-item">${dictionary.translate("SURVEY_SKIP")}</button>
                    <button type="submit" class="uni-button primary survey-submit survey-control-item">${dictionary.translate("SURVEY_SUBMIT")}</button>
                </div>
            </form>
            <div class="survey-result">
                <p class="survey-result-headline">${dictionary.translate("SURVEY_RESULT")}</p>
                <div class="survey-controls">
                    <span class="survey-control-item">
                        <a href="" class="link detail-link survey-overview-link">${dictionary.translate("SURVEY_TO_OVERVIEW")}</a>
                    </span>
                    <button type="button" class="uni-button primary survey-next survey-control-item">${dictionary.translate("SURVEY_NEXT")}</button>
                </div>
            </div>
        `);

        this.promptElement = this.configurePrompt(rootElement);

        this.append(...rootElement.children);

        this.enableSubmitButton(false);
    }

    private configurePrompt(rootElement: Element): Element {
        const promptContainer = rootElement.querySelector(".survey-prompt")!;
        promptContainer.prepend(this.createPromptContent());

        if (this.hasSubmitButton()) {
            promptContainer.classList.add("with-submit-button");
        }
        rootElement.querySelector("form")?.addEventListener("submit", async event => {
            event.preventDefault();
            const optionStatistics = await schedule(this.submitAndGetStatistics(event)).as("get-statistics");
            this.configureResult(optionStatistics);
        });
        promptContainer.querySelector("button.survey-skip")!
            .addEventListener("click", () => schedule(this.navigation.skipQuestion(this.questionData.id)).as("skip-question"));

        return promptContainer;
    }

    private configureResult(data: StatisticsPerOption): Element {
        const resultContainer = this.querySelector(".survey-result")!;
        resultContainer.querySelector(".survey-result-headline")!.after(this.createResultContent(data));
        resultContainer.querySelector("button.survey-next")!.addEventListener("click", () => this.navigation.nextQuestion());
        if (this.params.overviewLink) {
            const overviewLinkElement = resultContainer.querySelector("a.survey-overview-link") as HTMLLinkElement;
            overviewLinkElement.href = this.params.overviewLink;
            resultContainer.classList.add("with-overview-link");
        }
        return resultContainer;
    }

    protected enableSubmitButton(value: boolean): void {
        (this.promptElement.querySelector(".survey-submit") as HTMLButtonElement).disabled = !value;
    }

    protected abstract specificConnectedCallback(): void;

    protected abstract submitAndGetStatistics(event: SubmitEvent): Promise<StatisticsPerOption>;

    protected abstract hasSubmitButton(): boolean;
}

export function formatPercentage(value: number | undefined): string {
    return value !== undefined ? Math.round(value * 100) + "\u2006%" : "";
}