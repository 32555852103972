import {customElement, property} from "lit/decorators.js";
import {html, type TemplateResult} from "lit";
import Styles from "./navigationLevel3Block.lit.scss";
import type {NavigationItem, NavigationLevel3Item} from "../common/navModel";
import {classMap} from "lit/directives/class-map.js";
import {EopNavigationItemGroup} from "../common/navigationItemGroup";

@customElement("eop-navigation-level-3-block")
export class EopNavigationLevel3Block extends EopNavigationItemGroup {

    public static readonly styles = Styles;

    @property()
    public model: NavigationLevel3Item;

    public connectedCallback(): void {
        super.connectedCallback();

        if (this.model.hasSubItems()) {
            this.classList.add("with-sublinks");
        }
    }

    public render(): TemplateResult {
        return html`
            ${this.renderLevel3Link()}
            ${this.renderSubLinksColumn()}
        `;
    }

    private renderLevel3Link(): TemplateResult {
        const classes = classMap({highlighted: !this.hasSubLinkHighlight() && this.model.active});
        if (this.model.hasLink()) {
            return html`
                <a href=${this.model.href} target=${this.model.target} title=${this.model.label}
                   class="nav-item nav-link level-3 ${classes}">${this.model.label}</a>
            `;
        }
        return html`
            <div title=${this.model.label} class="nav-item level-3">${this.model.label}</div>
        `;
    }

    private renderSubLinksColumn(): TemplateResult | null {
        if (!this.model.hasSubItems()) {
            return null;
        }

        return html`
            <div class="nav-column">${(this.renderLinks())}</div>
        `;
    }

    private renderLinks(): TemplateResult[] {
        return this.model.getSubItems().map(item => this.renderLevel4Link(item));
    }

    private renderLevel4Link(link: NavigationItem): TemplateResult {
        const classes = classMap({highlighted: link.active});
        return html`
            <a href=${link.href} target=${link.target} title=${link.label}
               class="nav-item nav-link level-4 ${classes}">${link.label}</a>
        `;
    }

    private hasSubLinkHighlight(): boolean {
        return this.model.getSubItems().some(item => item.active);
    }
}