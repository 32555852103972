import {extendPrototype} from "../../common/utils/extend";

export interface ArraySplit<T> {
    allButLast: T[];
    last: T | undefined;
}

extendPrototype(Array, {

    chunk: function <T>(this: T[], chunkSize: number): T[][] {
        return ([] as T[][]).concat.apply([],
            this.map((element, index) => index % chunkSize ? [] : [this.slice(index, index + chunkSize)])
        );
    },

    shuffle: function <T>(this: T[]): T[] {
        for (let i = this.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [this[i], this[j]] = [this[j], this[i]];
        }
        return this;
    },

    equalsArray: function <T>(this: T[], other: T[]): boolean {
        return this.length === other.length
            && this.every((item, index) => item === other[index]);
    },

    includesAll: function <T>(this: T[], elements: T[]): boolean {
        return elements.every(element => this.includes(element));
    },

    includesAny: function <T>(this: T[], ...elements: T[]): boolean {
        return elements.some(element => this.includes(element));
    },

    first: function <T>(this: T[]): T | null {
        return this[0] ?? null;
    },

    findFirst: function <T>(this: T[], predicate: (elem: T) => boolean): T | undefined {
        return this.find(predicate);
    },

    isNotEmpty: function <T>(this: T[]): boolean {
        return this.length > 0;
    },

    isEmpty: function <T>(this: T[]): boolean {
        return this.length === 0;
    },

    last: function <T>(this: T[]): T | null {
        return this.slice(-1)[0] ?? null;
    },

    removeFirst: function <T>(this: T[], element: T): void {
        if (this.includes(element)) {
            this.splice(this.indexOf(element), 1);
        }
    },

    removeAll: function <T>(this: T[], ...elements: T[]): void {
        elements.forEach(element => {
            while (this.includes(element)) {
                this.splice(this.indexOf(element), 1);
            }
        });
    },

    removeEvery: function <T>(this: T[], filter: (item: T) => boolean): void {
        this.filter(item => filter(item))
            .forEach(item => this.removeAll(item));
    },

    distinct: function <T>(this: T[]): T[] {
        return this.reduce((acc, x) => acc.indexOf(x) > -1 ? acc : acc.concat(x), [] as T[]);
    },

    inReverseOrder: function <T>(this: T[]): T[] {
        return this.clone().reverse();
    },

    splitAtLastElement: function <T>(this: T[]): ArraySplit<T> {
        return {
            allButLast: this.slice(0, this.length - 1),
            last: this.length > 0 ? this[this.length - 1] : undefined
        };
    },

    prepend: function <T>(this: T[], element: T | T[]): T[] {
        const prefix = isArray(element)
            ? element
            : [element];

        return prefix.concat(this);
    },

    clone: function <T>(this: T[]): T[] {
        return this.slice(0);
    },

    partition: function <T>(this: T[], predicate: (it: T) => boolean): [T[], T[]] {
        const left: T[] = [];
        const right: T[] = [];
        this.forEach(it => {
            (predicate(it) ? left : right).push(it);
        });
        return [left, right];
    }
});

export function intRangeClosed(from: number, to: number): number[] {
    if (from > to) {
        return [];
    }

    return new Array(to - from + 1)
        .fill(undefined)
        .map((_, i) => from + i);
}

export function condense<T, S, TARGET>(array1: T[], array2: S[], condensor: (x: T, y: S) => TARGET): TARGET[] {
    if (array1.length !== array2.length) {
        throw new Error(`Arrays cannot be condensed since they differ in length (${array1.length} and ${array2.length})`);
    }

    const result = new Array<TARGET>(array1.length);
    for (let i = 0; i < result.length; i++) {
        result[i] = condensor(array1[i], array2[i]);
    }
    return result;
}

export function isArray<T>(value: any): value is T[] {
    return Array.isArray(value);
}

export function isEmptyArray<T>(value: any): value is T[] {
    return Array.isArray(value) && value.length === 0;
}

export function isNonEmptyArray<T>(value: any): value is T[] {
    return Array.isArray(value) && value.length > 0;
}

declare global {
    interface Array<T> {
        chunk: (chunkSize: number) => T[][];

        shuffle: () => T[];

        equalsArray: (other: T[]) => boolean;

        includesAll: (items: T[]) => boolean;

        includesAny: (...items: T[]) => boolean;

        first: () => T | null;

        findFirst: (predicate: (elem: T) => boolean) => T | undefined;

        removeFirst: (t: T) => void;

        isEmpty: () => boolean;

        isNotEmpty: () => boolean;

        last: () => T | null;

        removeAll: (...t: T[]) => void;

        removeEvery: (filter: (item: T) => boolean) => void;

        distinct: () => T[];

        inReverseOrder: () => T[];

        splitAtLastElement: () => ArraySplit<T>;

        prepend: (element: T | T[]) => T[];

        clone: () => T[];

        partition: (predicate: (elem: T) => boolean) => [T[], T[]];
    }
}