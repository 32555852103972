import {Cookies} from "./cookies";
import {autoRegister, resolve} from "../container";

@autoRegister()
export class Cookieflag {

    public constructor(private cookies: Cookies = resolve(Cookies)) {
    }

    public isSetFor(cookieName: string): boolean {
        return this.cookies.get(cookieName) === "1";
    }

    public setFor(cookieName: string): void {
        this.cookies.setForOneYear(cookieName, "1");
    }
}