import {isArray} from "../bootstrap/common/arrays";
import {autoRegister} from "../container";

export function forAllEntries(callback: (entry: IntersectionObserverEntry) => void): IntersectionObserverCallback {
    return entries => {
        for (const entry of entries) {
            callback(entry);
        }
    };
}

export function onceIntersected(callback: IntersectionObserverCallback): IntersectionObserverCallback {
    return (entries, observer) => {
        for (const entry of entries) {
            if (entry.isIntersecting) {
                callback(entries, observer);
                observer.disconnect();
                break;
            }
        }
    };
}

@autoRegister()
export class ResizeObserverFactory {
    public create(callback: Function): ResizeObserver {
        return new ResizeObserver((entries) => {
            window.requestAnimationFrame((): void | undefined => {
                if (!isArray(entries) || !entries.length) {
                    return;
                }
                callback(entries);
            });
        });
    }
}

@autoRegister()
export class MutationObserverFactory {
    public create(callback: MutationCallback): MutationObserver {
        return new MutationObserver(callback);
    }
}

@autoRegister()
export class IntersectionObserverFactory {
    public create(callback: IntersectionObserverCallback, options?: IntersectionObserverInit, testId?: string): IntersectionObserver {
        return new IntersectionObserver(callback, options);
    }
}