import {ManagingResources} from "../../../common/lifetime";
import {customElement, property} from "lit/decorators.js";
import Styles from "./header.lit.scss";
import {EventBus} from "../../../common/eventBus";
import {resolve} from "../../../container";
import {HERO_TEASER_READY_EVENT} from "../../elements/heroteaser";
import {html, LitElement, type PropertyValues, type TemplateResult} from "lit";
import {MOBILE_MENU_CLOSED, MOBILE_MENU_OPENED} from "./mobile/mobileHeaderEvents";
import {ScrollDirection, ScrollDirectionListener} from "../../../common/scroll";
import {GLOBAL} from "../../../common/globals";
import {Initializing} from "../../../common/elements";
import {STICKY_HEADER_PRIORITY, StickyElements, StickyPriorityElement} from "../../elements/sticky/stickyElements";
import {Resolution} from "../../../common/resolution";
import {BOOTSTRAP} from "../../../common/resolutionConstants";
import {NavigationRootItem, NavModel} from "./common/navModel";
import {Page} from "../../../common/page";

const STATIC_HEADER_HEIGHT = 106;
const REDUCED_HEADER_HEIGHT = 72;
const MOBILE_HEADER_HEIGHT = 48;
const SCROLL_DOWN_OFFSET = STATIC_HEADER_HEIGHT - REDUCED_HEADER_HEIGHT;
const SCROLL_UP_OFFSET = 0;

@customElement("eop-header")
export class EopHeader extends Initializing(ManagingResources(LitElement)) {

    public static readonly styles = Styles;

    @property({attribute: "with-language-switch", type: Boolean})
    public withLanguageSwitch: boolean = false;
    @property({attribute: "with-dark-mode-toggle", type: Boolean})
    public withDarkModeToggle: boolean = false;
    @property({type: Boolean})
    private transparent: boolean = false;
    @property({type: Boolean, reflect: true})
    private reduced: boolean = false;
    @property({type: Boolean, reflect: true})
    private opaque: boolean = false;
    @property({type: Boolean})
    private sticky: boolean = false;
    @property({type: Boolean})
    private fullNavigation: boolean = false;

    private navRoot: NavigationRootItem;

    public constructor(
        private page: Page = resolve(Page),
        private eventBus: EventBus = resolve(EventBus),
        private stickyElements: StickyElements = resolve(StickyElements),
        private resolution: Resolution = resolve(Resolution),
        private navModel: NavModel = resolve(NavModel)
    ) {
        super();
    }

    private stickyElement(): HTMLElement {
        return GLOBAL.bodyElement().querySelector("header")!;
    }

    public connectedCallback(): void {
        super.connectedCallback();

        this.resolution.onBootstrapBreakpointChange(() => this.requestUpdate(), this);
        this.navRoot = this.navModel.makeNavRoot();

        const scrollDirectionListener = ScrollDirectionListener.from(this, SCROLL_DOWN_OFFSET, SCROLL_UP_OFFSET);
        if (this.sticky) {
            this.stickyElements.register(new StickyPriorityElement(this.stickyElement())
                .withPriority(STICKY_HEADER_PRIORITY)
                .withStickyClass("sticky")
                .withElementHeightProvider(() => this.resolution.upTo(BOOTSTRAP.XS) ? MOBILE_HEADER_HEIGHT : REDUCED_HEADER_HEIGHT)
                .withEnableAction(() => {
                    if (this.sticky && this.fullNavigation) {
                        scrollDirectionListener.onScroll(this.scrollReductionHandler);
                    }
                    if (this.sticky && this.transparent) {
                        this.opaque = this.page.getYScrollPosition() > 0;
                        scrollDirectionListener.onScroll(this.headerDetachedHandler);
                    }
                })
                .withDisableAction(() => {
                    scrollDirectionListener.offScroll(this.scrollReductionHandler);
                    scrollDirectionListener.offScroll(this.headerDetachedHandler);
                }));
        } else {
            this.page.registerViewportOffsetProvider((scrollTarget) => {
                return Math.min(0, scrollTarget - this.clientHeight);
            });
        }

        if (this.transparent) {
            this.eventBus.on(HERO_TEASER_READY_EVENT, () => {
                this.initialized();
            });

            this.addEventListener(MOBILE_MENU_OPENED, () => this.opaque = true);
            this.addEventListener(MOBILE_MENU_CLOSED, () => this.opaque = (this.page.getYScrollPosition() > 0));
        }
    }

    private scrollReductionHandler = (direction: ScrollDirection): void => {
        this.reduced = direction === ScrollDirection.SCROLL_DOWN;
    };

    private headerDetachedHandler = (_: ScrollDirection, scrollY: number): void => {
        this.opaque = scrollY > 0;
    };

    public disconnectedCallback(): void {
        this.stickyElements.unregister(this.stickyElement());
        this.page.unregisterViewportOffsetProvider();
        super.disconnectedCallback();
    }

    protected firstUpdated(_changedProperties: PropertyValues): void {
        if (!this.transparent) {
            this.initialized();
        }
    }

    public render(): TemplateResult {
        return html`
            <div class="header-v2-area">
                <slot name="brand-elements" class="header-v2-brand-area"></slot>
                <div class="header-v2-menu-area">
                    ${this.renderHeaderMenu()}
                </div>
            </div>
        `;
    }

    private renderHeaderMenu(): TemplateResult | null {
        if (this.resolution.upTo(BOOTSTRAP.MD)) {
            return this.renderMobileHeaderMenu();
        } else {
            return this.renderDesktopHeaderMenu();
        }
    }

    private shouldRenderSearchLink(): boolean {
        return !!this.navModel.getSearchHref() || !!this.navRoot.getActiveSector()?.searchHref;
    }

    private renderMobileHeaderMenu(): TemplateResult | null {
        if (!this.shouldRenderSearchLink()
            && !this.navModel.hasMetaLinks()
            && this.navRoot.countSectors() <= 1
            && !(this.navRoot.getActiveSector()?.hasSubItems())
            && !this.withLanguageSwitch
        ) {
            return this.withDarkModeToggle
                ? html`
                        <eop-color-scheme-toggle inside-sheet="false"></eop-color-scheme-toggle>`
                : null;
        }

        return html`
            <eop-mobile-header-menu
                    ?with-language-switch=${this.withLanguageSwitch}
                    ?with-dark-mode-toggle=${this.withDarkModeToggle}
                    ?sticky=${this.sticky}
            ></eop-mobile-header-menu>`;
    }

    private renderDesktopHeaderMenu(): TemplateResult {
        return html`
            <eop-desktop-header-menu
                    ?with-language-switch=${this.withLanguageSwitch}
                    ?transparent=${this.transparent}
                    ?opaque=${this.opaque}
                    ?reduced=${this.reduced}
            ></eop-desktop-header-menu>`;
    }
}