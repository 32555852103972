import {ClientPostings} from "./pageOverlayMessages";
import {getClientHistoryState, PageOverlayClientHistory, PageOverlayHostHistory} from "./pageOverlayHistory";
import {Nav} from "../../../common/nav";
import {autoRegister, resolve} from "../../../container";
import {GLOBAL} from "../../../common/globals";
import {absoluteHrefFrom} from "../../../common/utils/url";
import {EopBackgroundLink} from "../links/backgroundLink";
import {KEYS} from "../../../common/utils/events";
import {PageFeatures} from "../pageFeatures";
import {Deferred, EOP_ERRORS} from "../../../common/utils/promises";
import {EopPageOverlay, PageOverlayTrustPolicyProvider, QUERY_PAGE_OVERLAY_HREF} from "./pageOverlay";
import {QueryParameters} from "../../../common/queryParameters";
import {afterContentLoaded} from "../elementFeatures";

type LinkAttributes = {
    href: string;
    target: string;
}

export interface PageOverlayHandler {
    openPageInsideOverlay: (pageHref: string) => void;

    closeOverlayIfHrefMatchesHost: (
        href: string,
        comparator: (href: string, hostHref: string) => boolean
    ) => boolean;

    getPredecessorURL: () => string | null;

    didOpenOutsidePageOverlay: (href: string) => boolean;
}

export class ClientHandler implements PageOverlayHandler {
    private clientPostings: ClientPostings;

    public constructor(
        private hostHref: string,
        private history: PageOverlayClientHistory,
        private nav: Nav = resolve(Nav)
    ) {
        this.clientPostings = ClientPostings.forHref(this.hostHref);
    }

    public init(): void {
        this.prepareDom();
        this.history.init();
    }

    public openPageInsideOverlay(pageHref: string): void {
        this.clientPostings.openInOverlayRequestMessage({href: pageHref});
    }

    private prepareDom(): void {
        const doc = GLOBAL.document();
        doc.querySelector("header")!.remove();
        doc.querySelector("footer")!.remove();
        doc.querySelector("eop-career-gpt")?.remove();
        doc.querySelector("body")!.classList.add("page-overlay");

        doc.addEventListener("click", (ev) => this.handleClick(ev));
        doc.addEventListener("keydown", (ev) => this.escapeKeyHandler(ev));
        doc.addEventListener("myEvent", ((ev: CustomEvent) => this.myEventHandler(ev)) as EventListener);
    }

    public closeOverlayIfHrefMatchesHost(href: string, comparator: (href: string, hostHref: string) => boolean): boolean {
        if (comparator(href, this.hostHref)) {
            this.clientPostings.closeOverlayRequestMessage({trackingEvent: false});
            return true;
        }
        return false;
    }

    private handleClick(event: MouseEvent): void {
        const eventPath: HTMLElement[] = event.composedPath()
            .filter(element => element instanceof HTMLElement)
            .map(element => element);

        for (const element of eventPath) {
            const linkAttributes = ClientHandler.extractLinkFrom(element);
            if (linkAttributes) {
                if (linkAttributes.target === "_blank" || this.nav.isJump(linkAttributes.href)) {
                    return;
                } else {
                    event.preventDefault();
                    event.stopPropagation();

                    this.requestOpenWithoutOverlayMessage(linkAttributes.href);
                    break;
                }
            }
        }
        //the handled links are not exhaustive, add other links if needed
    }

    public didOpenOutsidePageOverlay(href: string): boolean {
        this.requestOpenWithoutOverlayMessage(href);
        return true;
    }

    private requestOpenWithoutOverlayMessage(href: string): void {
        this.clientPostings.requestOpenWithoutOverlayMessage({href: absoluteHrefFrom(href)});
    }

    public static extractLinkFrom(element: HTMLElement): LinkAttributes | undefined {
        let href: string | null;
        let target: string | null;

        if (element instanceof HTMLAnchorElement || element instanceof EopBackgroundLink) {
            href = element.getAttribute("href");
            target = element.getAttribute("target");
        } else {
            return undefined;
        }

        if (href === null) {
            return undefined;
        }

        return {href: href, target: target ?? ""};
    }

    private escapeKeyHandler(evt: KeyboardEvent): void {
        if (evt.key === KEYS.ESCAPE) {
            evt.stopPropagation();
            this.clientPostings.closeOverlayRequestMessage({trackingEvent: "escapeKey"});
        }
    }

    public getPredecessorURL(): string {
        return this.hostHref;
    }

    private myEventHandler(ev: CustomEvent): void {
        this.clientPostings.requestOpenWithoutOverlayMessage({href: absoluteHrefFrom(ev.detail.href)});
    }
}

export class HostHandler implements PageOverlayHandler {
    private pageOverlay?: EopPageOverlay;

    public constructor(
        private history: PageOverlayHostHistory = resolve(PageOverlayHostHistory),
        private pageFeatures: PageFeatures = resolve(PageFeatures)
    ) {
    }

    public init(): void {
        this.history.setReturnToRootCallback(() => this.closeOverlayFromHistory());
        this.history.setReturnToPageCallback((pageHref) => this.openPageFromHistory(pageHref));
        this.history.init();
    }

    public async openPageInsideOverlay(pageHref: string): Promise<void> {
        return this.getOrCreatePageOverlay()
            .then(overlay => overlay.openPage(pageHref))
            .catch(EOP_ERRORS);
    }

    public didOpenOutsidePageOverlay(unusedHref: string): boolean {
        return false;
    }

    private async openPageFromHistory(pageHref: string): Promise<void> {
        return this.getOrCreatePageOverlay()
            .then(overlay => overlay.openPageFromHistory(pageHref))
            .catch(EOP_ERRORS);
    }

    private async closeOverlayFromHistory(): Promise<void> {
        return this.getOrCreatePageOverlay()
            .then(overlay => overlay.closeOverlayFromHistory())
            .catch(EOP_ERRORS);
    }

    private async getOrCreatePageOverlay(): Promise<EopPageOverlay> {
        if (this.pageOverlay) {
            await this.pageOverlay.updateComplete;
            return this.pageOverlay;
        }

        this.pageOverlay = GLOBAL.document().createElement("eop-page-overlay");
        this.pageFeatures.insert(this.pageOverlay);

        await this.pageOverlay.updateComplete;
        return this.pageOverlay;
    }

    public closeOverlayIfHrefMatchesHost(): boolean {
        return false;
    }

    public getPredecessorURL(): string {
        return GLOBAL.documentReferrer();
    }
}

@autoRegister()
export class PageOverlayHandlerFactory {
    public createHostHandler(): PageOverlayHandler {
        const hostHistory = new PageOverlayHostHistory();
        const pageOverlayHost = new HostHandler(hostHistory);
        pageOverlayHost.init();
        return pageOverlayHost;
    }

    public createClientHandler(hostHref: string): PageOverlayHandler {
        const clientHistory = new PageOverlayClientHistory(hostHref);
        const pageOverlayClient = new ClientHandler(hostHref, clientHistory);
        pageOverlayClient.init();
        return pageOverlayClient;
    }
}

@autoRegister()
export class PageOverlayHandlerProvider {
    private handler: Deferred<PageOverlayHandler>;

    public constructor(
        private queryParameters: QueryParameters = resolve(QueryParameters),
        private factory: PageOverlayHandlerFactory = resolve(PageOverlayHandlerFactory),
        private trustPolicyProvider: PageOverlayTrustPolicyProvider = resolve(PageOverlayTrustPolicyProvider)
    ) {
        this.handler = new Deferred<PageOverlayHandler>();
    }

    public init(): void {
        this.handler.resolve(this.createHandler());
    }

    public getPageOverlayHandler(): Promise<PageOverlayHandler> {
        return this.handler.promise;
    }

    private createHandler(): PageOverlayHandler {
        if (isInOverlayContext()) {
            const queryHostHref = this.queryParameters.getString(QUERY_PAGE_OVERLAY_HREF);
            if (queryHostHref && this.trustPolicyProvider.get().isTrustedHref(queryHostHref)) {
                return this.factory.createClientHandler(queryHostHref);
            }
            const clientHistoryState = getClientHistoryState();
            if (clientHistoryState) {
                return this.factory.createClientHandler(clientHistoryState.hostHref);
            }
        }
        return this.factory.createHostHandler();
    }
}

export function isInOverlayContext(): boolean {
    return GLOBAL.window().parent !== GLOBAL.window();
}

afterContentLoaded(() => {
    resolve(PageOverlayTrustPolicyProvider).provideDefault();
    resolve(PageOverlayHandlerProvider).init();
});