import {GLOBAL} from "../../common/globals";
import {HERO_TEASER_READY_EVENT} from "./heroteaser";
import {EventBus} from "../../common/eventBus";
import {resolve} from "../../container";

export class EopArticleHeader extends HTMLElement {

    private readonly AVERAGE_WORDS_PER_MINUTE = 200;

    public constructor(private eventBus: EventBus = resolve(EventBus)) {
        super();
    }

    public connectedCallback(): void {
        const readingTimeElement = this.querySelector(".reading-time")!;
        readingTimeElement.textContent = this.determineReadingTime();
        this.querySelector<HTMLElement>(".article-header-bottom")!.style.visibility = "visible";

        const articleHeaderImage = this.querySelector<HTMLElement>(".article-header-image")!;
        const imageEl = articleHeaderImage.querySelector<HTMLImageElement>("img");

        if (!imageEl?.complete) {
            this.addEventListener("imageLoaded", () => this.triggerAnimation());
        } else {
            this.triggerAnimation();
        }
    }

    private determineReadingTime(): string {
        const text = GLOBAL.bodyElement().querySelector("main")?.innerText?.trim() ?? "";
        const numberOfWords = text.split(/\s+/).length;
        return Math.ceil(numberOfWords / this.AVERAGE_WORDS_PER_MINUTE) + " min";
    }

    private triggerAnimation(): void {
        this.eventBus.dispatchEvent(HERO_TEASER_READY_EVENT, {});
    }
}

customElements.define("eop-article-header", EopArticleHeader);