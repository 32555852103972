export function newUUID(): string {
    return p8(false) + p8(true) + p8(true) + p8(false);
}

function p8(s: boolean): string {
    const p = (Math.random().toString(16) + "000000000").substring(2, 10);
    return s ? "-" + p.substring(0, 4) + "-" + p.substring(4, 8) : p;
}

export function emptyUUID(): string {
    return "00000000-0000-0000-0000-000000000000";
}