import {ScrollService} from "../../common/scroll";
import {HorizontalOverflow, HorizontalOverflowInstaller} from "./horizontalOverflow";
import {resolve} from "../../container";

export class EopContentNavigation extends HTMLElement {

    private horizontalOverflow: HorizontalOverflow | undefined;

    public constructor(
        private scrollService: ScrollService = resolve(ScrollService),
        private horizontalOverflowInstaller: HorizontalOverflowInstaller = resolve(HorizontalOverflowInstaller)
    ) {
        super();
    }

    public connectedCallback(): void {
        const tabsElement = this.querySelector(".content-navigation-tabs");
        if (tabsElement) {
            this.horizontalOverflow = this.horizontalOverflowInstaller.installOn(tabsElement);
        }
        const activeTabElement = this.querySelector(".active");
        if (activeTabElement) {
            this.scrollService.scrollToElementHorizontallyCentered(activeTabElement, 0);
        }
    }

    public disconnectedCallback(): void {
        this.horizontalOverflow?.uninstall();
    }
}

customElements.define("eop-content-navigation", EopContentNavigation);