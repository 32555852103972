import type {Lifetime} from "./lifetime";


export type Observer<T> = (data: T) => void

export class Observers<T> {
    private observers: Observer<T>[] = [];

    public register(observer: Observer<T>, lifetime?: Lifetime): void {
        this.observers.push(observer);
        lifetime?.onDrop( () => this.unregister(observer));
    }

    public unregister(observer: Observer<T>): void {
        this.observers.removeAll(observer);
    }

    public notify(data: T): void {
        this.observers.forEach((observer) => observer(data));
    }
}


