import type {PropertyMap} from "./utils/objects";
import {isString} from "../bootstrap/common/strings";
import {isDefined} from "./utils/basics";
import {autoRegister, resolve} from "../container";
import {Nav} from "./nav";
import type {Observer} from "./observer";
import {Observers} from "./observer";
import type {Lifetime} from "./lifetime";

@autoRegister()
export class QueryParameters {
    private searchStringObservers: Observers<string>;

    public constructor(
        private nav: Nav = resolve(Nav)
    ) {
        this.searchStringObservers = new Observers();
        this.searchStringObservers.register((searchString) => this.nav.setSearch(searchString));
    }

    private setSearch(parameters: URLSearchParams): void {
        this.applyQueryString(QueryParameters.isEmpty(parameters)
            ? "?" + parameters.toString().replace(/\+/g, " ")
            : "");
    }

    private static isEmpty(parameters: URLSearchParams): boolean {
        // Replace with URLSearchParams.size as soon as WebKit supports it: https://caniuse.com/mdn-api_urlsearchparams_size
        return !!(parameters.entries().next().value);
    }

    public extractParameters(): URLSearchParams {
        return new URLSearchParams(this.nav.search());
    }

    public set(key: string, value: string): void {
        const currentParameters = this.extractParameters();
        currentParameters.set(key, value);
        this.setSearch(currentParameters);
    }

    public appendAll(key: string, values: string[]): void {
        const currentParameters = this.extractParameters();
        values.forEach(value => currentParameters.append(key, value));
        this.setSearch(currentParameters);
    }

    public applyQueryString(query: string): void {
        this.searchStringObservers.notify(query);
    }

    public remove(key: string): void {
        const currentParameters = this.extractParameters();
        currentParameters.delete(key);
        this.setSearch(currentParameters);
    }

    public removeParametersStartingWith(prefix: string): void {
        const currentParameters = this.extractParameters();

        Array.from(currentParameters.keys())
            .filter(key => key.startsWith(prefix))
            .forEach(key => currentParameters.delete(key));

        this.setSearch(currentParameters);
    }

    public update(values: PropertyMap<string>): void {
        const currentParameters = this.extractParameters();
        Object.entries(values)
            .filter(entry => isDefined(entry[1]))
            .forEach(entry => currentParameters.set(entry[0], entry[1]!));
        this.setSearch(currentParameters);
    }

    public clear(): void {
        this.setSearch(new URLSearchParams());
    }

    public getString(key: string): string | null {
        return this.extractParameters().get(key);
    }

    public getStrings(key: string): string[] {
        return this.extractParameters().getAll(key);
    }

    public onString(key: string, func: (value: string) => void): this {
        const value = this.getString(key);
        if (isString(value)) {
            func(value);
        }
        return this;
    }

    public onStrings(key: string, func: (values: string[]) => void): this {
        const values = this.getStrings(key);
        func(values);
        return this;
    }

    public onStringsStartingWith(prefix: string, callback: (entries: Map<string, string[]>) => void): this {
        const parameterValues: Map<string, string[]> = new Map();

        for (const entry of this.extractParameters()) {
            if (entry[0].startsWith(prefix)) {
                const values = parameterValues.get(entry[0]) ?? [];
                values.push(entry[1]);
                parameterValues.set(entry[0], values);
            }
        }

        if (parameterValues.size > 0) {
            callback(parameterValues);
        }
        return this;
    }

    public exists(key: string): boolean {
        return this.extractParameters().has(key);
    }

    public onChange(observer: Observer<string>, lifetime?: Lifetime): void {
        this.searchStringObservers.register(observer, lifetime);
    }
}