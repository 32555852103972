import {html, type TemplateResult} from "lit";
import {customElement, property} from "lit/decorators.js";
import {ColorSchemeAwareImage} from "./colorSchemeAwareImage";
import {OUT_OF_VIEW_ATTR} from "./imageUtils";

@customElement("eop-static-svg")
export class EopStaticSvg extends ColorSchemeAwareImage {

    @property({attribute: "alt"})
    private alt: string;
    @property({attribute: OUT_OF_VIEW_ATTR, type: Boolean})
    public outOfView: boolean = false;

    public render(): TemplateResult {
        return html`<img src=${this.outOfView ? "" : this.srcCurrent} alt=${this.alt ?? ""} loading="lazy" class="svg-image" ?baseplate=${this.enableBaseplate}/>`;
    }
}