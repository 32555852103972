import {extendPrototype} from "../../common/utils/extend";

declare global {
    interface Window {
        addEventListeners: (this: Window, types: string[], listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions) => void;
    }
}

extendPrototype(Window, {
    addEventListeners: function (this: Window, types: string[], listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions): void {
        types.forEach(it => {
            this.addEventListener(it, listener, options);
        });
    }
});