import {customElement} from "lit/decorators.js";
import {Resolution} from "../../../common/resolution";
import {resolve} from "../../../container";
import {Page} from "../../../common/page";
import {ScrollService} from "../../../common/scroll";
import {EopExecutiveBoardGallery, executiveBoardGalleryConfig} from "./executiveBoardGallery";
import StylesFiveWithoutGroupShot from "./executiveBoardGalleryFiveWithoutGroupShot.lit.scss";
import StylesCommon from "./executiveBoardGalleryCommon.lit.scss";

@customElement("eop-executive-board-gallery-5-no-intro-image")
export class EopExecutiveBoardGalleryFiveWithoutGroupShot extends EopExecutiveBoardGallery {

    public static readonly styles = [StylesCommon, StylesFiveWithoutGroupShot];

    public constructor(resolution: Resolution = resolve(Resolution), page: Page = resolve(Page), scrollService: ScrollService = resolve(ScrollService)) {
        // has duplicate in scss
        const portraitOnStageTimeline = [0, 0.24, 0.48, 0.72, 0.96];
        const config = executiveBoardGalleryConfig(portraitOnStageTimeline, {portraitCount: 5, hasGroupShot: false});
        super(config, resolution, page, scrollService);
    }
}
