import {ScrollEvents} from "./scroll";
import {Resolution} from "./resolution";
import {GLOBAL} from "./globals";
import {autoRegister, resolve} from "../container";
import {ACTIVATION_CHANGE, prepareEvents} from "./utils/events";

import type {Lifetime} from "./lifetime";

@autoRegister()
export class ViewportListener {
    private callbacks: (() => void)[];

    public constructor(
        private resolution: Resolution = resolve(Resolution),
        private scrollService: ScrollEvents = resolve(ScrollEvents)
    ) {
        this.callbacks = [];

        this.scrollService.onScroll(() => this.applyCallbacks());
        this.resolution.onWindowResize(() => this.applyCallbacks());

        prepareEvents(GLOBAL.window())
            .on(ACTIVATION_CHANGE, ev => this.applyCallbacks()); // viewport is always affected
    }

    public onViewportEvent(callback: () => void, lifetime?: Lifetime): void {
        this.callbacks.push(callback);
        lifetime?.onDrop(() => this.callbacks.removeFirst(callback));
    }

    private applyCallbacks(): void {
        this.callbacks.forEach(callback => callback());
    }
}