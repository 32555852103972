import type {PropertyMap} from "../../common/utils/objects";
import {extendPrototype, type Patched} from "../../common/utils/extend";
import {GLOBAL} from "../../common/globals";

export class Attribute {
    public constructor(public name: string, public value: string) {
    }
}

export interface Hideable {
    visibleStyle: string | undefined;
}

type TraversalOptions = {
    transparent: boolean;
}

extendPrototype(HTMLInputElement, {
    attachedFiles: function (this: HTMLInputElement): File[] {
        if (!this.files) {
            return [];
        }
        return Array.from(this.files);
    }
});

export type EventAttachable = {
    _events: PropertyMap<boolean>;
};

const valueFrom = (cssProperty: string): number => {
    return parseInt(cssProperty) || 0;
};

const CONSIDERED_CLICKABLE_TAGS = ["a", "button", "eop-background-link", "eop-page-overlay-link", "eop-navigation-element"];
const IGNORED_CLICKABLE_TAGS = ["header", "main", "footer"];
export const NO_CLICK_TARGET_CLASS = "no-click-target";

extendPrototype(Element, {
    computedStyle: function (this: Element): CSSStyleDeclaration {
        return GLOBAL.window().getComputedStyle(this);
    },
    isFocused: function (this: Element): boolean {
        return this === GLOBAL.document().activeElement;
    },
    moveAttributes: function (this: Element, to: Element, predicate?: (attr: string) => boolean): void {
        const attributes = this.filteredAttributes(predicate);

        attributes.forEach(attribute => {
            this.removeAttribute(attribute.name);
            to.setAttribute(attribute.name, attribute.value);
        });
    },
    filteredAttributes: function (this: Element, predicate?: (attr: string) => boolean): Attribute[] {
        return Array.from(this.attributes)
            .filter(a => a.name !== "id")
            .filter(a => !predicate || predicate(a.name))
            .map(a => new Attribute(a.name, a.value));
    },
    isClickable: function (this: Element): boolean {
        if (this === GLOBAL.bodyElement()) {
            return false;
        }
        if (this === GLOBAL.htmlElement()) {
            return false;
        }
        if (this.classList.contains(NO_CLICK_TARGET_CLASS)) {
            return false;
        }
        const elementTag = this.tagName.toLowerCase();
        if (IGNORED_CLICKABLE_TAGS.includes(elementTag)) {
            return false;
        }

        return CONSIDERED_CLICKABLE_TAGS.includes(elementTag)
            || !!(this as any).onclick
            || (this as any)._events?.click
            || (this as any)._events?.submit
            || false;
    },
    isVisible: function (this: Element): boolean {
        if (this.getClientRects().length === 0) {
            return false;
        }
        const style = GLOBAL.window().getComputedStyle(this);
        return style.width !== "0"
            && style.height !== "0"
            && style.opacity !== "0"
            && style.display !== "none"
            && style.visibility !== "collapse"
            && style.visibility !== "hidden";
    },
    closestThat: function (this: Element, predicate: (e: Element) => boolean, options: TraversalOptions = {transparent: false}): Element | null {
        let current: Element | null = this;
        while (current) {
            if (predicate(current)) {
                return current;
            }
            if (current.parentElement) {
                current = current.parentElement;
            } else if (options.transparent) {
                current = (current.parentNode as ShadowRoot)?.host;
            } else {
                current = null;
            }
        }
        return null;
    },
    hasAncestor: function (this: Element, ancestor: Element, options: TraversalOptions = {transparent: false}): boolean {
        return this !== ancestor
            && this.closestThat(e => e === ancestor, options) !== null;
    },
    hasDescendant: function (this: Element, descendant: Element, options: TraversalOptions = {transparent: false}): boolean {
        return descendant.hasAncestor(this, options);
    },
    readRawData: function <T>(this: Element, id: string): T {
        const content = this.querySelector<HTMLScriptElement>("script[data-id='" + id + "']")!.text;
        if (content) {
            return JSON.parse(content) as T;
        }
        return {} as T;
    },
    resolveTemplateElement: function (this: Element, id: string): HTMLTemplateElement {
        return this.querySelector<HTMLTemplateElement>("template[data-id='" + id + "']")!;
    },
    hide: function (this: Element): void {
        if (this instanceof HTMLElement || this instanceof SVGElement) {
            let visibleStyle: string | undefined = this.style.display;
            if (visibleStyle === "none") {
                visibleStyle = undefined;
            }
            (this as any as Hideable).visibleStyle = visibleStyle;
            this.style.display = "none";
        }
    },
    show: function (this: Element): void {
        if (this instanceof HTMLElement || this instanceof SVGElement) {
            const visibleStyle = (this as any as Hideable).visibleStyle;
            if (visibleStyle) {
                this.style.display = visibleStyle;
            } else if (this.style.display === "none") {
                this.style.removeProperty("display");
                if (!this.isVisible()) {
                    (this as any as Hideable).visibleStyle = "block";
                    this.style.display = "block";
                }
            } else {
                if (!this.isVisible()) {
                    (this as any as Hideable).visibleStyle = "block";
                    this.style.display = "block";
                }
            }
        }
    },
    leftOffset: function (this: Element): number {
        return this.getBoundingClientRect()?.left + GLOBAL.window().scrollX;
    },
    rightOffset: function (this: Element): number {
        return this.getBoundingClientRect()?.right + GLOBAL.window().scrollX;
    },
    topOffset: function (this: Element): number {
        return this.getBoundingClientRect()?.top + GLOBAL.window().scrollY;
    },
    bottomOffset: function (this: Element): number {
        return this.getBoundingClientRect()?.bottom + GLOBAL.window().scrollY;
    },
    addEventListener: function <K extends keyof ElementEventMap>(type: K, listener: (this: Element, ev: ElementEventMap[K]) => any, options?: boolean | AddEventListenerOptions | undefined) {
        (this as any as EventAttachable)._events ??= {};
        (this as any as EventAttachable)._events[type] = true;
        (Element as any as Patched)._original.addEventListener.bind(this)(type, listener, options);
    },
    parents: function (this: Element): HTMLElement[] {
        const parents: HTMLElement[] = [];
        let nextParent = this.parentElement;
        while (nextParent) {
            parents.push(nextParent);
            nextParent = nextParent.parentElement;
        }
        return parents;
    },
    nextAll: function (this: Element): Element[] {
        const allChildren = Array.from(this.parentNode?.children ?? new HTMLCollection());
        const selfIndex = allChildren.indexOf(this);
        return allChildren.filter((item) => allChildren.indexOf(item) > selfIndex);
    },
    outerHeight: function (this: Element, options: SizeOptions = {}): number {
        const styles = GLOBAL.window().getComputedStyle(this);
        const border = valueFrom(styles.borderTopWidth) + valueFrom(styles.borderBottomWidth);
        let margin = 0;
        if (options.includeMargin === true) {
            margin = valueFrom(styles.marginTop) + valueFrom(styles.marginBottom);
        }
        return this.clientHeight + border + margin;
    },
    outerWidth: function (this: Element, options: SizeOptions = {}): number {
        const styles = GLOBAL.window().getComputedStyle(this);
        const border = valueFrom(styles.borderLeftWidth) + valueFrom(styles.borderRightWidth);
        let margin = 0;
        if (options.includeMargin === true) {
            margin = valueFrom(styles.marginLeft) + valueFrom(styles.marginRight);
        }
        return this.clientWidth + border + margin;
    }
});

declare global {

    interface HTMLInputElement {
        attachedFiles: () => File[];
    }

    interface Element {
        computedStyle: () => CSSStyleDeclaration;
        isFocused: () => boolean;
        moveAttributes: (to: Element, predicate?: (attr: string) => boolean) => void;
        filteredAttributes: (predicate?: (attr: string) => boolean) => Attribute[];
        isClickable: () => boolean;
        isVisible: () => boolean;
        closestThat: (predicate: (e: Element) => boolean, options?: TraversalOptions) => Element | null;
        hasAncestor: (ancestor: Element, options?: TraversalOptions) => boolean;
        hasDescendant: (descendant: Element, options?: TraversalOptions) => boolean;
        readRawData: <T>(this: Element, id: string) => T;
        resolveTemplateElement: (this: Element, id: string) => HTMLTemplateElement;
        hide: () => void;
        show: () => void;
        leftOffset: () => number;
        rightOffset: () => number;
        topOffset: () => number;
        bottomOffset: () => number;
        parents: () => HTMLElement[];
        nextAll: () => Element[];
        outerHeight: (options?: SizeOptions) => number;
        outerWidth: (options?: SizeOptions) => number;
    }
}

type SizeOptions = {
    includeMargin?: boolean;
};