import {customElement, property, state} from "lit/decorators.js";
import {html, LitElement, type TemplateResult} from "lit";
import {PageScrollbar} from "../../../common/pageScrollbar";
import {autoRegister, resolve} from "../../../container";
import {GLOBAL} from "../../../common/globals";
import {Nav} from "../../../common/nav";
import Styles from "./pageOverlay.lit.scss";
import {ClientEvents} from "./pageOverlayMessages";
import {KEYS, prepareEvents} from "../../../common/utils/events";
import {sameParentDomain} from "../../../common/utils/url";
import {PageOverlayHostHistory} from "./pageOverlayHistory";
import {ManagingResources} from "../../../common/lifetime";
import {keyed} from "lit/directives/keyed.js";
import type {DirectiveResult} from "lit/directive.js";

export const QUERY_PAGE_OVERLAY_HREF = "pageOverlayFromHref";

declare global {
    interface HTMLElementTagNameMap {
        "eop-page-overlay": EopPageOverlay;
    }
}

@customElement("eop-page-overlay")
export class EopPageOverlay extends ManagingResources(LitElement) {
    public static readonly styles = Styles;

    @property({reflect: true, type: Boolean})
    public open: boolean = false;
    @property({reflect: true, type: Boolean})
    public pageLoaded: boolean = false;
    @state()
    private pageHref?: string;

    public constructor(
        private history: PageOverlayHostHistory = resolve(PageOverlayHostHistory),
        private pageScrollbar: PageScrollbar = resolve(PageScrollbar),
        private nav: Nav = resolve(Nav)
    ) {
        super();
    }

    public render(): TemplateResult {
        const handleCloseClick = (): void => this.closeOverlay();
        return html`
            <div class="overlay-viewport">
                <div class="overlay-shadow"></div>
                <div class="overlay-area">
                    <div class="overlay">
                        <div class="close-bar" data-eventelement="page-overlay-control">
                            <span
                                    @click=${handleCloseClick}
                                    title="Close"
                                    data-tracking-label="close-page-overlay"
                                    class="close-overlay-link">
                            </span>
                        </div>
                        <div class="overlay-content">
                            <div class="skeleton-boxes">
                                <span class="skeleton-box"></span>
                                <span class="skeleton-box"></span>
                                <span class="skeleton-box"></span>
                            </div>
                            ${this.renderIframeTag()}
                        </div>
                    </div>
                </div>
            </div>
        `;
    }

    public connectedCallback(): void {
        super.connectedCallback();

        prepareEvents(GLOBAL.document())
            .boundTo(this)
            .on("keydown", (ev) => this.escapeKeyHandler(ev));

        ClientEvents.for(this)
            .loadedOrChanged((data) => this.notifyPageLoadedOrChanged(data.href, data.title, data.numClientHistoryEntriesSinceStart))
            .closeOverlayRequest((_data) => this.closeOverlay())
            .openInOverlayRequest((data) => this.openPage(data.href))
            .openWithoutOverlayRequest((data) => this.openLinkOutsideOverlay(data.href));
    }

    public closeOverlay(): void {
        if (this.open) {
            this.doCloseOverlay();
            this.history.returnToRoot();
        }
    }

    public closeOverlayFromHistory(): void {
        if (this.open) {
            this.doCloseOverlay();
        }
    }

    public openPage(pageHref: string): void {
        this.history.pushPreliminaryEntry(this.hrefWithoutOverlayMode(pageHref));
        this.doOpenOverlay(pageHref);
    }

    public openPageFromHistory(pageHref: string): void {
        this.doOpenOverlay(pageHref);
    }

    private escapeKeyHandler(evt: KeyboardEvent): void {
        if (evt.key === KEYS.ESCAPE) {
            evt.stopPropagation();
            this.closeOverlay();
        }
    };

    private notifyPageLoadedOrChanged(pageHref: string, title: string, numClientHistoryEntriesSinceStart: number): void {
        this.history.updateEntry(this.hrefWithoutOverlayMode(pageHref), title, numClientHistoryEntriesSinceStart);
        this.pageLoaded = true;
    }

    private openLinkOutsideOverlay(pageHref: string): void {
        this.nav.redirect(pageHref);
    }

    private doOpenOverlay(pageHref: string): void {
        this.pageLoaded = false;
        this.pageScrollbar.disablePageScrollability();
        this.open = true;
        this.pageHref = pageHref;
    }

    private doCloseOverlay(): void {
        this.pageLoaded = false;
        this.pageScrollbar.enablePageScrollability();
        this.open = false;
        this.pageHref = undefined;
    }

    private renderIframeTag(): TemplateResult | DirectiveResult | null {
        if (this.open && this.pageHref) {
            return keyed(this.pageHref, html`
                <iframe src=${this.hrefWithOverlayMode(this.pageHref)}></iframe>
            `);
        }
        return null;
    }

    private hrefWithoutOverlayMode(href: string): string {
        const modifiedHref = new URL(href);
        modifiedHref.searchParams.delete(QUERY_PAGE_OVERLAY_HREF);
        return modifiedHref.href;
    }

    private hrefWithOverlayMode(href: string): string {
        const modifiedHref = new URL(href);
        modifiedHref.searchParams.set(QUERY_PAGE_OVERLAY_HREF, this.history.getRootBaseHref());
        return modifiedHref.href;
    }
}

@autoRegister()
export class PageOverlayTrustPolicyProvider {
    private trustPolicy: PageOverlayTrustPolicy | undefined;

    public constructor(private nav: Nav = resolve(Nav)) {
    }

    public provideDefault(): void {
        const trustedOriginUrl = new URL(this.nav.origin());
        this.provide(new PageOverlayTrustPolicy(trustedOriginUrl));
    }

    public provide(trustPolicy: PageOverlayTrustPolicy): void {
        this.trustPolicy = trustPolicy;
    }

    public get(): PageOverlayTrustPolicy {
        if (!this.trustPolicy) {
            throw new Error("PageOverlayTrustPolicy not provided");
        }
        return this.trustPolicy;
    }
}

export class PageOverlayTrustPolicy {
    public constructor(private trustedOriginUrl: URL) {
    }

    public isTrustedHref(otherHref: string): boolean {
        return this.hasTrustedOrigin(otherHref);
    }

    private hasTrustedOrigin(otherHref: string): boolean {
        const otherOriginUrl = new URL(otherHref);
        return this.trustedOriginUrl.protocol === otherOriginUrl.protocol
            && sameParentDomain(this.trustedOriginUrl, otherOriginUrl);
    }
}


