export class ImageBreakpoint {
    public min: number;
    public max: number;
}

export class BootstrapBreakpoint {
    public constructor(
        public name: string,
        public range: [number, number]
    ) {
    }

    public isDownTo(other: BootstrapBreakpoint): boolean {
        return this.range[0] >= other.range[0];
    }

    public isUpTo(other: BootstrapBreakpoint): boolean {
        return this.range[1] <= other.range[1];
    }
}

export const IMAGE_BREAKPOINTS: {
    [key: string]: ImageBreakpoint;
} = {                                           // SELENIUM DEVICES
    _0: {min: 0, max: 480},             // XS   // PHONE
    _481: {min: 481, max: 767},         // XS   // PHONE_LARGE
    _768: {min: 768, max: 1023},        // SM   // TABLET
    _1024: {min: 1024, max: 1279},      // MD   // TABLET_LARGE
    _1280: {min: 1280, max: 1535},      // LG   // DESKTOP
    _1536: {min: 1536, max: 1920},      // XL   // DESKTOP_LARGE
    _1921: {min: 1921, max: 9999999}    // XXL  // DESKTOP_ULTRA_WIDE
};

export const BOOTSTRAP: {
    [key: string]: BootstrapBreakpoint;
} = {
    XS: new BootstrapBreakpoint("xs", [0, IMAGE_BREAKPOINTS._481.max]),
    SM: new BootstrapBreakpoint("sm", [IMAGE_BREAKPOINTS._768.min, IMAGE_BREAKPOINTS._768.max]),
    MD: new BootstrapBreakpoint("md", [IMAGE_BREAKPOINTS._1024.min, IMAGE_BREAKPOINTS._1024.max]),
    LG: new BootstrapBreakpoint("lg", [IMAGE_BREAKPOINTS._1280.min, IMAGE_BREAKPOINTS._1280.max]),
    XL: new BootstrapBreakpoint("xl", [IMAGE_BREAKPOINTS._1536.min, IMAGE_BREAKPOINTS._1536.max]),
    XXL: new BootstrapBreakpoint("xxl", [IMAGE_BREAKPOINTS._1921.min, 9999999])
};

export const bootstrapBreakpointByName = (name: string): BootstrapBreakpoint | null => {
    return Object.values(BOOTSTRAP)
        .findFirst(breakpoint => breakpoint.name === name) ?? null;
};