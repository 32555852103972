import {intRangeClosed} from "../../bootstrap/common/arrays";
import {randomInt} from "./random";

export namespace Sugar {
    export function sugar(): string {
        const codeAsString = randomCode();
        const codeAsNumbers = codeAsString.map(c => parseInt(c, 30)) as [number, number, number];
        const checksumAsNumber = calculateChecksum(codeAsNumbers[0], codeAsNumbers[1], codeAsNumbers[2]);
        const checksumAsString = checksumToString(checksumAsNumber);
        return randomMerge(codeAsString.join(""), checksumAsString);
    }

    export function randomCode(): [string, string, string] {
        return [random30(10), random30(9), random30(7)];
    }

    export function calculateChecksum(x: number, y: number, z: number): number {
        const a = Math.floor(z / 2345) + 1;
        const b = 300 * (x % (y + 1));
        const c = Math.floor(b / a) % 123456;
        const d = z % 1234;
        return (c ** 2 + d ** 3) % 7775;
    }

    export function randomMerge(string1: string, string2: string): string {
        const targetLength = string1.length + string2.length;
        const targetIndices = intRangeClosed(0, targetLength - 1);
        const string2Indices: number[] = [];
        string2.split("").forEach(_str => {
            const index = randomInt(targetIndices.length - 1);
            string2Indices.push(targetIndices[index]);
            targetIndices.splice(index, 1);
        });

        let string1Index = 0;
        let string2Index = 0;
        let result = "";
        for (let i = 0; i < targetLength; i++) {
            if (string2Indices.includes(i)) {
                result += string2[string2Index];
                string2Index++;
            } else {
                result += string1[string1Index];
                string1Index++;
            }
        }
        return result;
    }

    export function checksumToString(checkNumber: number): string {
        return ("00000" + checkNumber.toString(6))
            .split("")
            .map(d => d.charCodeAt(0) + 69)
            .map(c => String.fromCharCode(c))
            .join("")
            .slice(-5);
    }

    function random30(amount: number): string {
        return intRangeClosed(1, amount)
            .map(_ => randomInt(29))
            .map(r => r < 10 ? (r + 48) : (r + 87))
            .map(c => String.fromCharCode(c))
            .join("");
    }
}
