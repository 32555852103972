import {PersistentTrigger, type TriggerFactory} from "./triggers";
import {Timer, TimerConfig, TimerFactory} from "../../page/elements/timer";
import {GLOBAL} from "../globals";
import {autoRegister, resolve} from "../../container";
import {isPresent} from "../utils/basics";

export class InactivityTrigger extends PersistentTrigger {
    private timer: Timer;
    private triggerDelay: number;

    public constructor(
        element: Element,
        private timerFactory: TimerFactory,
        callback: () => void
    ) {
        super(callback);
        this.triggerDelay = milliseconds(element.getAttribute("data-trigger-delay")) ?? 3000;

        const timerConfig = new TimerConfig()
            .withDelay(this.triggerDelay)
            .withCallback(() => this.trigger());
        this.timer = this.timerFactory.newTimer(timerConfig);

        GLOBAL.window().addEventListeners(["click", "keydown", "scroll", "mousemove"], () => this.timer.refresh());
    }

    public stop(): void {
        super.stop();
        this.timer.stop();
    }
}

const milliseconds = (seconds: string | null): number | null => {
    const parsed = seconds?.toFloat();
    return isPresent(parsed) ? parsed * 1000 : null;
};

@autoRegister()
export class InactivityTriggerFactory implements TriggerFactory {
    public constructor(private timerFactory: TimerFactory = resolve(TimerFactory)) {
    }

    public createTrigger(element: Element, callback: () => void): InactivityTrigger {
        return new InactivityTrigger(element, this.timerFactory, callback);
    }
}