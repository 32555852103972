import {ACTIVATION_CHANGE, ActivationChangeEventParams} from "./utils/events";

export const ACTIVE_TABS_CLASS_NAME = "enbw-tab-model"; //REMINDER let broadcast listen to event cancellation instead on certain style classes

export type TabModelOptions = {
    class: string;
    initial: number;
};

export class TabModel {
    private readonly root: Element;
    private readonly elements: Element[];
    private options: TabModelOptions;

    public constructor(root: Element, elements: NodeListOf<Element>) {
        this.root = root;
        this.elements = Array.from(elements);
    }

    public periodicSwitch(options?: Partial<TabModelOptions>): this {
        this.root.classList.add(ACTIVE_TABS_CLASS_NAME);

        const classNameActive = options?.class ?? "active";
        const activePosition = this.elements.findIndex(it => it.classList.contains(classNameActive));
        const initialPosition = activePosition !== -1 ? activePosition : options?.initial ?? 0;

        this.options = {
            class: classNameActive,
            initial: initialPosition
        };

        this.elements[initialPosition].classList.add(classNameActive);

        this.root.dispatchEvent(new CustomEvent(ACTIVATION_CHANGE, {
            bubbles: true,
            detail: new ActivationChangeEventParams("init", this.root, ACTIVE_TABS_CLASS_NAME)
        }));

        return this;
    }

    public activeIndex(): number {
        return this.elements.findIndex(it => it.classList.contains(this.options.class));
    }

    public selectNext(): this {
        return this.changeSelection(activePosition => (activePosition + 1 + this.elements.length) % this.elements.length);

    }

    public selectPrev(): this {
        return this.changeSelection(activePosition => (activePosition - 1 + this.elements.length) % this.elements.length);

    }

    public selectActive(selected: number | HTMLElement): this {
        const targetIndex = typeof selected === "number"
            ? selected
            : this.elements.indexOf(selected);

        if (targetIndex >= 0 && targetIndex !== this.activeIndex()) {
            return this.changeSelection(() => targetIndex);
        }

        return this;
    }

    private changeSelection(indexChange: (oldPosition: number) => number): this {
        const activePosition = this.activeIndex();
        const activeElement = this.elements[activePosition];
        activeElement.classList.remove(this.options.class);

        const nextPosition = indexChange(activePosition);
        const nextElement = this.elements[nextPosition];
        nextElement.classList.add(this.options.class);

        activeElement.dispatchEvent(new CustomEvent(ACTIVATION_CHANGE, {
            bubbles: true,
            detail: new ActivationChangeEventParams("hide", activeElement, ACTIVE_TABS_CLASS_NAME)
        }));
        nextElement.dispatchEvent(new CustomEvent(ACTIVATION_CHANGE, {
            bubbles: true,
            detail: new ActivationChangeEventParams("show", nextElement, ACTIVE_TABS_CLASS_NAME)
        }));

        return this;
    }

    public length(): number {
        return this.elements.length;
    }

    public at(index: number): Element | undefined {
        return this.elements[index];
    }

}
