import {html, type PropertyValues, type TemplateResult} from "lit";
import {customElement, property, query} from "lit/decorators.js";
import Styles from "./iButtonPopover.lit.scss";
import {FeatureDetector} from "../../common/featureDetector";
import {resolve} from "../../container";
import {prepareEvents} from "../../common/utils/events";
import {fresh} from "../../common/lifetime";
import {PopoverAnchor} from "./popover/popoverAnchor";

@customElement("eop-i-button")
export class EopIButton extends PopoverAnchor {

    public static readonly styles = Styles;

    @query(".i-button")
    public iButtonElement: HTMLSpanElement;
    @property({attribute: "i-button-style"})
    public iButtonStyle: string;

    public constructor(private features: FeatureDetector = resolve(FeatureDetector)) {
        super();
    }

    public render(): TemplateResult {
        return html`
            <span
                    class="i-button ${this.iButtonStyle}"
                    data-eventelement="i-button"
                    data-tracking-label="i-button-touch">
            </span>
        `;
    }

    protected getAnchorElement(): HTMLElement {
        return this.iButtonElement;
    }

    protected updated(_changedProperties: PropertyValues): void {
        super.updated(_changedProperties);

        const lifetime = fresh(this, "firstUpdated");
        if (this.features.isTouchDevice()) {
            prepareEvents(this)
                .boundTo(lifetime)
                .on("click", () => {
                    this.setPopoverPosition();
                    this.popoverElement.toggle();
                });
        } else {
            prepareEvents(this)
                .boundTo(lifetime)
                .on("click", () => this.popoverElement.isModal() && this.popoverElement.open())
                .on("mouseenter", () => {
                    if (this.popoverElement.isHint()) {
                        this.setPopoverPosition();
                        this.popoverElement.open();
                    }
                })
                .on("mouseleave", () => this.popoverElement.isHint() && this.popoverElement.close());
        }
    }
}