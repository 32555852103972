import {noop} from "../../common/utils/functions";
import {Interval} from "../../common/interval";
import {autoRegister, resolve} from "../../container";

export class Timer {
    private timer: Promise<void> | null = null;

    public constructor(
        private delay: number,
        private callback: () => void,
        private interval: Interval
    ) {
        this.start();
    }

    public refresh(): void {
        if (this.isRunning()) {
            this.stop();
            this.start();
        }
    }

    public stop(): void {
        if (this.timer) {
            this.interval.cancel(this.timer);
        }
        this.timer = null;
    }

    private start(): void {
        this.timer = this.interval.repeat(() => this.executeCallback(), this.delay, 1);
    }

    private executeCallback(): void {
        this.callback();
        this.stop();
    }

    private isRunning(): boolean {
        return this.timer !== null;
    }
}

export class TimerConfig {
    public delay: number;
    public callback: () => void;

    public constructor() {
        this.delay = 0;
        this.callback = noop;
    }

    public withDelay(delay: number): this {
        this.delay = delay;
        return this;
    }

    public withCallback(callback: () => void): this {
        this.callback = callback;
        return this;
    }
}

@autoRegister()
export class TimerFactory {
    public constructor(private interval: Interval = resolve(Interval)) {
    }

    public newTimer(config: TimerConfig): Timer {
        return new Timer(config.delay, config.callback, this.interval);
    }
}