import {Resolution} from "../../common/resolution";
import * as Arrays from "../../bootstrap/common/arrays";
import {noop} from "../../common/utils/functions";
import {resolve} from "../../container";

type AfterScrollToIndex = (index: number) => void;

export class MultiScrollCarousel {
    private maxElementsPerSlide: number;
    private elementsPerSlide: number;
    private elementCount: number;
    private currentIndex: number;
    private reframeAction: AfterScrollToIndex;
    private slideSizeFactory: (elementCount: number) => number;
    private updateActiveIndicesCallback: (activeIndices: number[]) => void;

    public constructor(
        maxElementsPerSlide: number,
        resolution: Resolution = resolve(Resolution)
    ) {
        this.maxElementsPerSlide = maxElementsPerSlide;
        this.elementsPerSlide = 0;
        this.elementCount = 0;
        this.currentIndex = 0;
        this.reframeAction = noop;
        this.slideSizeFactory = (() => 1);
        this.updateActiveIndicesCallback = noop;
        resolution.onWindowResize(() => {
            this.elementsPerSlide = this.slideSizeFactory(this.elementCount);
            this.reframe();
        });
    }

    public onReframe(reframeAction: AfterScrollToIndex): this {
        this.reframeAction = reframeAction;
        return this;
    }

    public withSlideSizeFactory(slideSizeFactory: (elementCount: number) => number): this {
        this.slideSizeFactory = slideSizeFactory;
        return this;
    }

    public withUpdateActiveIndicesCallback(updateActiveIndicesCallback: (indices: number[]) => void): this {
        this.updateActiveIndicesCallback = updateActiveIndicesCallback;
        return this;
    }

    public updateNumberOfElements(elementCount: number): void {
        this.elementCount = elementCount;
        this.elementsPerSlide = this.slideSizeFactory(elementCount);
        this.currentIndex = 0;
        this.reframe();
    }

    public scrollLeft(scrollCallback: AfterScrollToIndex): void {
        if (this.canScrollLeft()) {
            this.scrollToElement(this.currentIndex - this.elementsPerSlide, scrollCallback);
        }
    }

    public scrollRight(scrollCallback: AfterScrollToIndex): void {
        if (this.canScrollRight()) {
            this.scrollToElement(this.currentIndex + this.elementsPerSlide, scrollCallback);
        }
    }

    public canScrollLeft(): boolean {
        return this.currentIndex > 0;
    }

    public canScrollRight(): boolean {
        return this.currentIndex < this.elementCount - this.elementsPerSlide;
    }

    public isScrollable(): boolean {
        return this.canScrollLeft() || this.canScrollRight();
    }

    public scrollToElement(index: number, scrollCallback: AfterScrollToIndex): void {
        const truncatedIndex = this.truncateIndexToBoundaries(index);
        scrollCallback(truncatedIndex);
        this.currentIndex = truncatedIndex;
        this.updateActiveIndices();
    }

    public reframe(): void {
        this.scrollToElement(this.currentIndex, this.reframeAction);
    }

    private truncateIndexToBoundaries(index: number): number {
        let result = index;
        result = Math.min(result, this.elementCount - this.elementsPerSlide);
        result = Math.max(0, result);
        return result;
    }

    public clickableIndicators(): number[] {
        return this.getIndicatorIndices().filter((index: number) => this.isIndicatorClickable(index));
    }

    public isIndicatorClickable(index: number): boolean {
        const isReachable = index > -this.elementsPerSlide;
        const canBeScrolledToSuchThatIsLeft = ((index - this.currentIndex) % this.elementsPerSlide) === 0;
        return isReachable && canBeScrolledToSuchThatIsLeft;
    }

    public getCurrentIndex(): number {
        return this.currentIndex;
    }

    public getIndicatorIndices(): number[] {
        return Arrays.intRangeClosed(-this.maxElementsPerSlide + 1, this.elementCount - 1);
    }

    public updateActiveIndices(): void {
        this.updateActiveIndicesCallback(this.determineActiveIndices());
    }

    public determineActiveIndices(): number[] {
        const currentIndices = this.getIndicatorIndices();
        const currentIndexPosition = currentIndices.indexOf(this.currentIndex);
        if (currentIndexPosition === -1) {
            return [];
        }
        const slideSize = this.elementsPerSlide > 0 ? this.elementsPerSlide : this.maxElementsPerSlide;
        return currentIndices.slice(currentIndexPosition, currentIndexPosition + slideSize);
    }
}