export interface TriggerFactory {
    createTrigger: (element: Element, callback: () => void) => Trigger;
}

export interface Trigger {
    trigger: () => void;

    stop: () => void;
}

export abstract class PersistentTrigger implements Trigger {
    public triggered: boolean = false;
    public triggerCallbacks: (() => void)[];

    public constructor(...triggerCallbacks: (() => void)[]) {
        this.triggerCallbacks = triggerCallbacks;
    }

    public trigger(): void {
        this.triggered = true;
        this.triggerCallbacks.forEach(callback => callback());
    }

    public stop(): void {
        this.triggered = false;
    }
}

export class OnOffTrigger extends PersistentTrigger {

    public constructor(...triggerCallbacks: (() => void)[]) {
        super(...triggerCallbacks);
    }

    public trigger(): void {
        super.trigger();
        this.triggered = false;
    }
}

export abstract class CombinationTrigger implements Trigger {
    public triggered: boolean = false;

    public constructor(protected triggers: PersistentTrigger[], public triggeredCallback: () => void) {
        triggers.forEach(trigger=> trigger.triggerCallbacks.push(() => this.checkState()));
        this.checkState();
    }

    public trigger(): void {
        this.triggered = true;
        this.triggeredCallback();
    }

    public stop(): void {
        this.triggered = false;
        this.triggers.forEach(trigger => trigger.stop());
    }

    private checkState(): void {
        if (this.shouldTrigger()) {
            this.trigger();
        }
    }

    protected abstract shouldTrigger(): boolean;

}

export class AndTrigger extends CombinationTrigger {
    public constructor(triggers: PersistentTrigger[], callback: () => void) {
        super(triggers, callback);
    }

    protected shouldTrigger(): boolean {
        return !this.triggered
            && this.triggers.every(trigger => trigger.triggered);
    }
}

export class OrTrigger extends CombinationTrigger {
    public constructor(triggers: PersistentTrigger[], callback: () => void) {
        super(triggers, callback);
    }

    protected shouldTrigger(): boolean {
        return !this.triggered
            && this.triggers.some(trigger => trigger.triggered);
    }
}

export class SyntheticTriggerFactory implements TriggerFactory {
    public createTrigger(element: Element, callback: () => void): PersistentTrigger {
        return new SyntheticTrigger(callback, element);
    }
}

export class SyntheticTrigger extends PersistentTrigger {

    public constructor(callback: () => void, private element?: Element) {
        super(callback);
        this.element?.addEventListener("trigger", event => {
            event.preventDefault();
            this.trigger();
        });
    }

}