import {autoRegister, resolve} from "../../container";
import {GLOBAL} from "../../common/globals";
import {Load} from "../../common/load";
import {ColorSchemeService} from "../../common/colorSchemeService";
import {LanguagesService} from "../../common/languages";
import {Configuration} from "../../common/config";
import {elementFrom} from "../../common/utils/html";
import {isDefined} from "../../common/utils/basics";

export type GoogleTagManagerWindow = Window & {
    dataLayer: any;
};

const WEBSITE_CORPORATE_ZONE = "uk";

@autoRegister()
export class NativeGoogleTagManager {
    private window = GLOBAL.window() as GoogleTagManagerWindow;
    private head = GLOBAL.headElement();

    private readonly GOOGLE_TAG_MANAGER_ID: string | undefined;
    private readonly GOOGLE_ANALYTICS_STAGE_FLAG: string | undefined;

    public constructor(
        private load: Load = resolve(Load),
        private colorSchemeService: ColorSchemeService = resolve(ColorSchemeService),
        private languagesService: LanguagesService = resolve(LanguagesService),
        configuration: Configuration = resolve(Configuration)
    ) {
        this.GOOGLE_TAG_MANAGER_ID = configuration.get("GOOGLE_TAG_MANAGER_ID");
        this.GOOGLE_ANALYTICS_STAGE_FLAG = configuration.get("GOOGLE_ANALYTICS_STAGE_FLAG");
    }

    public init(): void {
        this.pushInitialPageObject();
        this.initScript();
    }

    public push(data: any): void {
        this.lazyDataLayer().push(data);
    }

    private pushInitialPageObject(): void {
        this.lazyDataLayer().push({
            page: {
                zone: WEBSITE_CORPORATE_ZONE,
                environment: this.GOOGLE_ANALYTICS_STAGE_FLAG,
                color_mode: this.colorSchemeService.getColorScheme().toLowerCase(),
                page_language: this.languagesService.activeLanguageId()
            }
        });
    }

    private initScript(): void {
        this.lazyDataLayer().push({
            event: "gtm.js",
            "gtm.start": new Date().getTime()
        });
        const anchorScript = elementFrom(`<script id="anchor-for-google-tag-manager"></script>`);
        this.head.appendChild(anchorScript);

        if (isDefined(this.GOOGLE_TAG_MANAGER_ID)) {
            void this.load.script(`https://www.googletagmanager.com/gtm.js?id=${this.GOOGLE_TAG_MANAGER_ID}`);
        }
    }

    private lazyDataLayer(): any {
        if (!this.window.dataLayer) {
            this.window.dataLayer = [];
        }
        return this.window.dataLayer;
    }
}