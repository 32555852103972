export class Globals {

    public visualViewport(): VisualViewport | null {
        return window.visualViewport;
    }

    public window(): Window {
        return window;
    }

    public navigator(): Navigator {
        return navigator;
    }

    public document(): Document {
        return document;
    }

    public bodyElement(): HTMLElement {
        return document.querySelector("body")!;
    }

    public headElement(): HTMLElement {
        return document.querySelector("head")!;
    }

    public htmlElement(): HTMLElement {
        return document.documentElement;
    }

    public documentReferrer(): string {
        return document.referrer;
    }

    public documentBaseUri(): string {
        return document.baseURI;
    }
}

export const GLOBAL = new Globals();