import {LocalDate} from "./localDate";
import {DatePeriod} from "./datePeriod";

export const YEAR_REGEX = /^\d+$/;
export const RELATIVE_DATE_REGEX = /(^[-|+]?\d+)([ymwd])/;


export function dateExpressionFrom(input: string): DateExpression {
    return AbsoluteYearExpression.parse(input)
        ?? RelativeDateExpression.parse(input)
        ?? HTML5DateExpression.parse(input)
        ?? InvalidDateExpression.of(input);
}

export interface DateExpression {
    toLocalDate: () => LocalDate;
}

export class AbsoluteYearExpression implements DateExpression {

    public constructor(private year: number) {
    }

    public static parse(input: string): AbsoluteYearExpression | undefined {
        if (YEAR_REGEX.test(input)) {
            return new AbsoluteYearExpression(parseInt(input));
        } else {
            return undefined;
        }
    }

    public toLocalDate(): LocalDate {
        return LocalDate.fromYMD(this.year, 1, 1);
    }

}

export class RelativeDateExpression implements DateExpression {

    public constructor(private number: number, private unit: string) {
    }

    public static parse(input: string): RelativeDateExpression | undefined {
        const parsed = RELATIVE_DATE_REGEX.exec(input);
        if (parsed) {
            return new RelativeDateExpression(parseInt(parsed[1]), parsed[2]);
        } else {
            return undefined;
        }
    }

    public toLocalDate(): LocalDate {
        return LocalDate.today().add(this.toDatePeriod());
    }

    public toDatePeriod(): DatePeriod {
        return new DatePeriod(this.number, this.unit);
    }
}

export class HTML5DateExpression implements DateExpression {

    public constructor(private year: number, private month: number, private day: number) {
    }

    public static parse(input: string): HTML5DateExpression | undefined {
        const trydate = new Date(input);
        const year = trydate.getFullYear();
        const month = trydate.getMonth();
        const day = trydate.getDate();
        if (!isNaN(year + month + day)) {
            return new HTML5DateExpression(year, month + 1, day);
        } else {
            return undefined;
        }
    }

    public toLocalDate(): LocalDate {
        return LocalDate.fromYMD(this.year, this.month, this.day);
    }
}

export class InvalidDateExpression implements DateExpression {

    public constructor(private errorToken: string) {
    }

    public static of(input: string): InvalidDateExpression {
        return new InvalidDateExpression(input);
    }

    public toLocalDate(): LocalDate {
        return LocalDate.invalid();
    }

}
