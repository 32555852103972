import {customElement, query, queryAll} from "lit/decorators.js";
import {html, type PropertyValues, type TemplateResult} from "lit";
import Styles from "./chapterNavigation.lit.scss";
import {Chapters} from "./chapters";
import {resolve} from "../../../container";
import {CHAPTER_NAVIGATION_PRIORITY, StickyElements, StickyPriorityElement} from "../sticky/stickyElements";
import {forAllEntries, IntersectionObserverFactory} from "../../../common/observation";
import {afterContentLoaded} from "../elementFeatures";
import {ScrollableBar} from "../scrollableBar";

@customElement("eop-chapter-navigation")
export class EopChapterNavigation extends ScrollableBar {

    public static readonly styles = Styles;

    @query(".chapter-navigation-container")
    private chapterNavigationContainer: HTMLElement;
    @query(".items")
    protected scrollableArea: HTMLElement;
    @queryAll(".item")
    private navigationItems: NodeListOf<HTMLElement>;

    private stickySentinelIntersectionObserver: IntersectionObserver;

    public constructor(
        private chapters: Chapters = resolve(Chapters),
        private stickyElements: StickyElements = resolve(StickyElements),
        private intersectionObserverFactory: IntersectionObserverFactory = resolve(IntersectionObserverFactory)
    ) {
        super();
    }

    protected items(): HTMLElement[] {
        return Array.from(this.navigationItems);
    }

    public connectedCallback(): void {
        super.connectedCallback();
        this.chapters.onChapterChange(() => this.updateActiveChapter(), this);
        this.stickySentinelIntersectionObserver = this.intersectionObserverFactory.create(forAllEntries(entry => {
            if (entry.isIntersecting) {
                this.classList.remove("sticky-mode");
            } else {
                this.classList.add("sticky-mode");
            }
        }), undefined, "sentinel");
    }

    public render(): TemplateResult {
        return html`
            <div class="sticky-sentinel"></div>
            <div class="background-container"></div>
            <div class="chapter-navigation-container">
                <nav class=chapter-navigation>
                    <ul class="items horizontally-scrollable-area"
                        @scroll=${this.toggleScrollButtonVisibility}
                        @touchend=${{handleEvent: () => this.toggleScrollButtonVisibility(), passive: true}}
                    >
                        ${(this.renderChapters())}
                        <li aria-hidden="true">
                            <eop-swipe-hint></eop-swipe-hint>
                        </li>
                    </ul>
                    ${this.renderScrollLeftButton()}
                    ${this.renderScrollRightButton()}
                </nav>
            </div>`;
    }

    private renderChapters(): TemplateResult[] {
        return this.chapters.getChapterData().map(chapter => html`
            <li class=item>
                <eop-chapter-navigation-item
                        ?active=${chapter.active}
                        .item=${chapter}
                ></eop-chapter-navigation-item>
            </li>`
        );
    }

    protected firstUpdated(_changedProperties: PropertyValues): void {
        super.firstUpdated(_changedProperties);

        this.stickyElements.register(new StickyPriorityElement(this.chapterNavigationContainer)
            .withPriority(CHAPTER_NAVIGATION_PRIORITY)
            .withStickyClass("sticky")
            .withScrollSectionsUnderMidpointOfElement());

        this.stickySentinelIntersectionObserver.observe(this.renderRoot.querySelector(".sticky-sentinel")!);

        afterContentLoaded(() => this.scrollToItem(0));
    }

    public disconnectedCallback(): void {
        this.stickyElements.unregister(this.chapterNavigationContainer);
        this.stickySentinelIntersectionObserver.disconnect();
        super.disconnectedCallback();
    }

    private updateActiveChapter(): void {
        this.scrollToActiveChapter();
        this.requestUpdate();
    }

    private scrollToActiveChapter(): void {
        const activeChapterIndex = this.chapters.activeChapterIndex();
        if (this.scrollableArea && this.navigationItems.length > 0 && activeChapterIndex !== undefined && activeChapterIndex > -1) {
            if (this.scrollableArea.clientWidth < this.scrollableArea.scrollWidth) {
                this.scrollToItem(activeChapterIndex);
            }
        }
    }
}