import {html, type TemplateResult} from "lit";
import {unsafeHTML} from "lit/directives/unsafe-html.js";

export type HeadlineLevel = `h${1 | 2 | 3 | 4 | 5 | 6}`;

export function headline(level: HeadlineLevel, content: string, classes: string[] = []): TemplateResult {

    switch (level) {
        case "h1":
            return html`
                <div class="h h1 ${classes.join(" ")}">${unsafeHTML(content)}</div>
            `;
        case "h2":
            return html`
                <div class="h h2 ${classes.join(" ")}">${unsafeHTML(content)}</div>
            `;
        case "h3":
            return html`
                <div class="h h3 ${classes.join(" ")}">${unsafeHTML(content)}</div>
            `;
        case "h4":
            return html`
                <div class="h h4 ${classes.join(" ")}">${unsafeHTML(content)}</div>
            `;
        case "h5":
            return html`
                <div class="h h5 ${classes.join(" ")}">${unsafeHTML(content)}</div>
            `;
        default:
            return html`
                <div class="h h6 ${classes.join(" ")}">${unsafeHTML(content)}</div>
            `;
    }
}