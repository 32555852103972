export class EopNoFurtherQuestions extends HTMLElement {

    public constructor(private html: string) {
        super();
    }

    public connectedCallback(): void {
        this.innerHTML = `<div class="no-further-questions">${this.html}</div>`;
    }

    public disconnectedCallback(): void {
        this.innerHTML = "";
    }

}

customElements.define("eop-survey-no-further-questions", EopNoFurtherQuestions);