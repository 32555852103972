import {property} from "lit/decorators.js";
import {ManagingResources} from "../../../common/lifetime";
import type {EopPopover} from "./popover";
import {resolve} from "../../../container";
import {PageFeatures} from "../pageFeatures";
import {Resolution} from "../../../common/resolution";
import {elementFrom, templateContaining} from "../../../common/utils/html";
import {UnLitElement} from "../../../common/elements";
import type {PropertyValues} from "lit";

export abstract class PopoverAnchor extends ManagingResources(UnLitElement) {

    @property({attribute: "content"})
    public contentId: string;

    protected popoverElement: EopPopover;

    public constructor(
        private pageFeatures: PageFeatures = resolve(PageFeatures),
        private resolution: Resolution = resolve(Resolution)
    ) {
        super();
    }

    public connectedCallback(): void {
        super.connectedCallback();

        this.resolution.onWindowResize(() => this.setPopoverPosition(), this);

        const templateHTML = this.resolveTemplateElement(this.contentId).innerHTML;
        const template = templateContaining(templateHTML);
        template.setAttribute("data-id", "content");

        const inModalDialog = this.closest(".modal-dialog") !== null;
        const popoverHtml = inModalDialog
            ? `<eop-popover content="content" in-modal-dialog></eop-popover>`
            : `<eop-popover content="content"></eop-popover>`;
        this.popoverElement = this.pageFeatures.insert<EopPopover>(elementFrom(popoverHtml));
        this.popoverElement.appendChild(template);
        this.popoverElement.onParent(this);
    }

    public firstUpdated(_changedProperties: PropertyValues): void {
        super.firstUpdated(_changedProperties);
        // size may depend on hover states etc., so fixate size here
        const currentAnchorElementSize = this.computeCurrentAnchorElementSize();
        this.popoverElement.style.setProperty("--parent-height", currentAnchorElementSize.height + "px");
        this.popoverElement.style.setProperty("--parent-width", currentAnchorElementSize.width + "px");
    }

    protected abstract getAnchorElement(): HTMLElement;

    public setPopoverPosition(): void {
        const currentAnchorElementSize = this.computeCurrentAnchorElementSize();

        // if element was not visible initially, parent height and width need to be set again here
        const parentHeightProperty = this.popoverElement.style.getPropertyValue("--parent-height");
        const parentWidthProperty = this.popoverElement.style.getPropertyValue("--parent-width");
        if (parentHeightProperty === "0px" || parentWidthProperty === "0px" || parentHeightProperty === "" || parentWidthProperty === "") {
            this.popoverElement.style.setProperty("--parent-height", currentAnchorElementSize.height + "px");
            this.popoverElement.style.setProperty("--parent-width", currentAnchorElementSize.width + "px");
        }

        this.popoverElement.style.setProperty("--parent-center-top-offset",
            this.getAnchorElement().topOffset()
            + 0.5 * currentAnchorElementSize.height + "px");
        this.popoverElement.style.setProperty("--parent-center-left-offset",
            this.getAnchorElement().leftOffset()
            + 0.5 * currentAnchorElementSize.width + "px");
    }

    private computeCurrentAnchorElementSize(): { width: number; height: number } {
        return {
            width: this.getAnchorElement().getBoundingClientRect().width, // visible width, not the same as clientWidth
            height: this.getAnchorElement().getBoundingClientRect().height // visible height, not the same as clientHeight
        };
    }

    public disconnectedCallback(): void {
        this.popoverElement.remove();
        super.disconnectedCallback();
    }
}