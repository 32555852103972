import {HorizontalSwipeHandler} from "../../common/utils/events";
import {QueryParameters} from "../../common/queryParameters";
import {clamp} from "../../bootstrap/common/numbers";
import {resolve} from "../../container";
import {customElement} from "lit/decorators.js";
import {TabModel} from "../../common/tabModel";
import {UnLitElement} from "../../common/elements";

export const SWITCH_QUERY_PARAM_NAME = "switch";

@customElement("eop-multiple-content-switch")
export class EopMultipleContentSwitch extends UnLitElement {
    private activeIndex: number;
    private labels: TabModel;
    private contents: TabModel;
    private selectorElement: Element;

    public constructor(private queryParameters: QueryParameters = resolve(QueryParameters)) {
        super();

        this.activeIndex = 0;
        this.labels = new TabModel(this, this.querySelectorAll(".multiple-switch-label")).periodicSwitch({class: "active"});
        this.contents = new TabModel(this, this.querySelectorAll(".multiple-switch-content")).periodicSwitch({class: "active"});
        this.selectorElement = this.querySelector(".multiple-switch-selector")!;
    }

    public connectedCallback(): void {
        super.connectedCallback();
        this.querySelectorAll(".multiple-switch-label")
            .forEach((e, i) => e.addEventListener("click", event => this.switch(i)));

        new HorizontalSwipeHandler(Array.from(this.querySelectorAll(".multiple-switch-label")))
            .onSwipeLeft((e, target, i) => this.onSwipeLeft(target, i))
            .onSwipeRight((e, target, i) => this.onSwipeRight(target, i))
            .activate();

        this.queryParameters.onString(SWITCH_QUERY_PARAM_NAME, index => this.switch(index?.toInt() ?? 0));
    }

    private onSwipeLeft(target: Element, index: number): void {
        if (this.activeIndex !== index) {
            return;
        }
        this.switch(index - 1);
    }

    private onSwipeRight(target: Element, index: number): void {
        if (this.activeIndex !== index) {
            return;
        }
        this.switch(index + 1);
    }

    private switch(index: number): void {
        const targetIndex = clamp(index, 0, this.labels.length() - 1);
        if (targetIndex === this.activeIndex) {
            return;
        }
        this.labels.selectActive(targetIndex);
        this.contents.selectActive(targetIndex);

        this.selectorElement.classList.remove("toggled-" + this.activeIndex);
        this.selectorElement.classList.add("toggled-" + targetIndex);

        this.activeIndex = targetIndex;
        this.queryParameters.set(SWITCH_QUERY_PARAM_NAME, String(targetIndex));
    }
}