import {ImageProxyUrlFactory} from "./imageProxyUrlFactory";
import {autoRegister, resolve} from "../../../container";
import {isDefined} from "../../../common/utils/basics";

import {ImageDimension, ResolutionSource} from "./imageUtils";

const PIXEL_RATIOS = [1, 1.5, 2, 3, 4];

@autoRegister()
export class RetinaSourceCreator {
    public constructor(private imageproxyUrlFactory: ImageProxyUrlFactory = resolve(ImageProxyUrlFactory)) {
    }

    public srcsetFor(source: ResolutionSource, dimension: ImageDimension): string {
        return PIXEL_RATIOS
            .map(ratio => this.resolutionUrlScaled(ratio, source, dimension))
            .filter(isDefined)
            .join(",");
    }

    private resolutionUrlScaled(factor: number, source: ResolutionSource, imageDimension: ImageDimension): string | undefined {
        const targetWidth = Math.ceil(imageDimension.width * factor);
        const targetHeight = Math.ceil(imageDimension.height * factor);
        if (targetWidth > source.width || targetHeight > source.height) {
            return undefined;
        }
        return `${this.imageproxyUrlFactory.resolutionUrlBase(source.url, targetWidth, targetHeight, source.focusX, source.focusY)} ${factor}x`;
    }

    public resolutionUrlBase(src: ResolutionSource, imageDimension: ImageDimension): string {
        return this.imageproxyUrlFactory.resolutionUrlBase(src.url, imageDimension.width, imageDimension.height, src.focusX, src.focusY);
    }
}