import {isFunction} from "./utils/functions";

export interface Lifetime {
    drop: () => void;
    signal: () => AbortSignal;
    onDrop: (callback: () => void) => void;
}

export function lifetime(object: any): Lifetime {
    if (isFunction(object.drop) && isFunction(object.onDrop)) {
        return object as Lifetime;
    } else {
        if (!object._storedController) {
            object._storedController = new AbortController();
        }
        return new DefaultLifetime(object._storedController);
    }
}

export function fresh(object: any, key: string): Lifetime {
    if (object._storedControllers) {
        if (object._storedControllers[key]) {
            object._storedControllers[key].abort();
        }
        object._storedControllers[key] = new AbortController();
    } else {
        object._storedControllers = {[key]: new AbortController()};
    }
    return new DefaultLifetime(object._storedControllers[key]);
}

export function done(object: any, key: string): void {
    if (object._storedControllers?.[key]) {
        object._storedControllers[key].abort();
        object._storedControllers[key] = null;
    }
}

export class DefaultLifetime implements Lifetime {
    public constructor(private abortController: AbortController = new AbortController()) {
    }

    public drop(): void {
        this.abortController.abort();
    }

    public signal(): AbortSignal {
        return this.abortController.signal;
    }

    public onDrop(callback: () => void): void {
        this.abortController.signal.addEventListener("abort", callback);
    }
}

export type Constructor<T> = new (...args: any[]) => T;


// eslint-disable-next-line @typescript-eslint/naming-convention
export function ManagingResources<T extends Constructor<HTMLElement & { disconnectedCallback?: () => void }>>(Base: T): T & Constructor<Lifetime> {
    return class extends Base implements Lifetime {
        public _storedController: AbortController;

        public abortController(): AbortController {
            if (!this._storedController) {
                this._storedController = new AbortController();
            }
            return this._storedController;
        }

        public signal(): AbortSignal {
            return this.abortController().signal;
        }

        public drop(): void {
            this.abortController().abort();
        }

        public onDrop(callback: () => void): void {
            this.signal().addEventListener("abort", callback);
        }

        // @ts-ignore
        public disconnectedCallback(): void {
            if (super.disconnectedCallback) {
                super.disconnectedCallback();
            }
            this.abortController().abort();
        }
    };
}
