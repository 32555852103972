import {autoRegister} from "../../container";
import {promiseFromJsonResponse} from "../../common/utils/promises";

const SHARE_DATA_URL = "https://xms.deutsche-boerse.com/chartmod/refreshData?ISIN=DE0005220008&MARKET=DETR";

export type ShareDataResponse = {
    LAST: number;
    DATETIME_LAST_R: number;
    PREVIOUSDAYDIFFVALUE: number;
    PREVIOUSDAYDIFFPERCENT: number;
};


@autoRegister()
export class ShareService {

    public async fetch(): Promise<ShareDataResponse> {
        const response = await fetch(`${SHARE_DATA_URL}`);
        return promiseFromJsonResponse(response);
    }
}