export class SvgCircleBuilder {
    private svgString: string;

    public constructor(radius: number, cx: number, cy: number) {
        this.svgString = `<circle r="${radius}" cx="${cx}" cy="${cy}"`;
    }

    public withAttribute(name: string, value: string | number): this {
        this.svgString += ` ${name}="${value}"`;
        return this;
    }

    public withFillColor(value: string): this {
        return this.withAttribute("fill", value);
    }

    public withStrokeColor(value: string): this {
        return this.withAttribute("stroke", value);
    }

    public toHtml(): string {
        this.svgString += "></circle>";
        return this.svgString;
    }
}