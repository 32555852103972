export class EopSliderElement extends HTMLElement {

    public image: HTMLElement | null;

    public constructor(element: Element) {
        super();

        this.image = element.querySelector(".eop-image");
    }
}

customElements.define("eop-slider-element", EopSliderElement);

