import {OnOffTrigger, type TriggerFactory} from "./triggers";

export class ClickOrKeydownTrigger extends OnOffTrigger {
    public constructor(private element: Element, callback: () => void) {
        super(callback);

        this.element.addEventListener("click", event => {
            this.triggerEvent(event);
        });

        this.element.addEventListener("keydown", event => {
            if (event instanceof KeyboardEvent && event.key === "Enter") {
                this.triggerEvent(event);
            }
        });
    }

    private triggerEvent(event: Event): void {
        event.preventDefault();
        this.trigger();
    }
}

export class ClickOrKeydownTriggerFactory implements TriggerFactory {

    public createTrigger(element: Element, callback: () => void): ClickOrKeydownTrigger {
        return new ClickOrKeydownTrigger(element, callback);
    }
}