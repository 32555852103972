import {customElement, property} from "lit/decorators.js";
import {Dictionary} from "../../../elements/dictionary";
import {html, type TemplateResult} from "lit";
import Styles from "./navigationSheet.lit.scss";
import type {MetaLink} from "../common/navLinkTypes";
import {type NavigationItem, type NavigationLevel1Item, NavModel} from "../common/navModel";
import {navigationUpEvent} from "./mobileHeaderEvents";
import {EopNavigationItemGroup} from "../common/navigationItemGroup";
import {resolve} from "../../../../container";
import {ClickTracking} from "../../../../tracking/clickTracking";
import {NavigationInteractionLocation, NavigationInteractionType} from "../../../../tracking/listener";

@customElement("eop-navigation-sheet")
export class EopNavigationSheet extends EopNavigationItemGroup {

    public static readonly styles = Styles;

    @property()
    public navItem: NavigationItem | null;
    @property()
    public sectorLinks: NavigationLevel1Item[] = [];
    @property()
    public metaLinks: MetaLink[] = [];
    @property({attribute: "with-language-switch", type: Boolean})
    public withLanguageSwitch: boolean = false;
    @property()
    public level: number;
    @property({attribute: "with-dark-mode-toggle", type: Boolean})
    private withDarkModeToggle: boolean = false;

    public constructor(
        private tracking: ClickTracking = resolve(ClickTracking),
        private navModel: NavModel = resolve(NavModel)
    ) {
        super();
    }

    private dictionary: Dictionary;

    public connectedCallback(): void {
        super.connectedCallback();
        this.dictionary = Dictionary.of(this);
    }

    public render(): TemplateResult {
        if (!this.navItem && this.metaLinks.isEmpty() && this.sectorLinks.isEmpty()) {
            return html`
                <div class="mobile-sub-menu-container single-block" data-eventelement="metanavigation">
                    ${this.renderSearchLink()}
                    ${this.renderDarkModeToggle()}
                    ${this.renderLanguageSwitch()}
                </div>
            `;
        } else {
            return html`
                <div class="mobile-sub-menu-container multi-block">
                    <div class="mobile-menu-top">
                        ${this.renderContextNavigation()}
                        ${this.renderMainNavigation()}
                        ${this.renderMetaAndSectorNavigation()}
                    </div>
                    <div class="mobile-menu-bottom" data-eventelement="metanavigation">
                        ${this.renderLanguageSwitch()}
                    </div>
                </div>
            `;
        }
    }

    private renderMainNavigation(): TemplateResult | null {
        if (!this.navItem?.hasSubItems()) {
            return null;
        }

        return html`
            <div class="main-navigation" data-eventelement="mainnavigation">
                ${(this.renderItems())}
            </div>
        `;
    }

    private renderItems(): TemplateResult[] {
        return this.navItem!.getSubItems().map(item => html`
            <eop-navigation-element .navItem=${item} .level=${this.level}></eop-navigation-element>`
        );
    }

    private shouldRenderSearchLink(): boolean {
        return !!this.navModel.getSearchHref() || !!this.navModel.getActiveSector()?.searchHref;
    }

    private renderMetaAndSectorNavigation(): TemplateResult | null {
        if (!this.shouldRenderSearchLink()
            && this.sectorLinks.length <= 1
            && !this.metaLinks.isNotEmpty()
            && !this.withDarkModeToggle
        ) {
            return null;
        }

        return html`
            <div class="meta-navigation" data-eventelement="metanavigation">
                ${(this.renderSectorLinks())}
                ${(this.renderMetaLinks())}
                ${this.renderDarkModeToggle()}
                ${this.renderSearchLink()}
            </div>
        `;
    }

    private renderSectorLinks(): TemplateResult[] {
        return this.sectorLinks.map(sectorLink => this.renderSectorLink(sectorLink));
    }

    private renderSectorLink(sectorLink: NavigationLevel1Item): TemplateResult {
        const classes = `sector-link link${sectorLink.active ? " highlighted" : ""}`;
        return html`
            <a href=${sectorLink.href} target=${sectorLink.target} title=${sectorLink.label}
               class=${classes}>${sectorLink.label}</a>`;
    }

    private renderMetaLinks(): TemplateResult[] {
        return this.metaLinks.map(metaLink => this.renderMetaLink(metaLink));
    }

    private renderMetaLink(metaLink: MetaLink): TemplateResult {
        return html`
            <a href=${metaLink.href} target=${metaLink.target} title=${metaLink.label}
               class="meta-link link">${metaLink.label}</a>`;
    }

    private renderDarkModeToggle(): TemplateResult | null {
        if (!this.withDarkModeToggle) {
            return null;
        }

        return html`
            <eop-color-scheme-toggle inside-sheet="true"></eop-color-scheme-toggle>`;
    }


    private renderLanguageSwitch(): TemplateResult | null {
        if (!this.withLanguageSwitch) {
            return null;
        }

        return html`
            <eop-mobile-language-switch>`;
    }

    private renderSearchLink(): TemplateResult {
        return html`
            <eop-navigation-search-link></eop-navigation-search-link>`;
    }

    private renderContextNavigation(): TemplateResult | null {
        if (this.level <= 2) {
            return null;
        }

        return html`
            ${this.renderBackNavigation()}
            ${this.renderParentLink()}
        `;
    }

    private renderParentLink(): TemplateResult | null {
        if (!this.navItem) {
            return null;
        }

        if (this.navItem.hasLink()) {
            return html`
                <div class="parent-navigation" data-eventelement="parent-navigation">
                    <a href=${this.navItem.href}
                       target=${this.navItem.target}
                       title=${this.navItem.label}
                       class="parent-item parent-link">
                        ${this.navItem.label}
                    </a>
                </div>`;
        } else {
            return html`
                <div class="parent-navigation">
                    <div title=${this.navItem.label} class="parent-item">${this.navItem.label}</div>
                </div>`;
        }
    }

    private renderBackNavigation(): TemplateResult {
        const backNavigationLabel = this.dictionary.translate("MSG_BACK_NAVIGATION_LABEL");
        return html`
            <div class="back-navigation" data-eventelement="back-navigation" @click=${this.navigateBack}>${backNavigationLabel}</div>`;
    }

    private navigateBack(): void {
        this.dispatchEvent(navigationUpEvent());
        this.trackNavigationBack();
    }

    private trackNavigationBack(): void {
        this.tracking.handleNavigationInteract({
            label: this.navItem?.label ?? this.dictionary.translate("MSG_BACK_NAVIGATION_LABEL"),
            location: NavigationInteractionLocation.HEADER,
            contentId: "header.back-navigation",
            type: NavigationInteractionType.CLOSE
        });
    }
}