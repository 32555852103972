import type {Page} from "./page";

import {noop} from "./utils/functions";

export type ViewportPredicate = (viewportState: ViewportState) => boolean;
export type ViewportState = { yScrollPosition: number };

export class ViewportCallback {
    public constructor(
        private predicate: ViewportPredicate,
        private callback: (viewportCondition: boolean) => void,
        private page: Page
    ) {
    }

    public evaluatePredicate(): boolean {
        return this.predicate({
            yScrollPosition: this.page.getYScrollPosition()
        });
    }

    public callCallback(conditionAfterChange: boolean): void {
        this.callback(conditionAfterChange);
    }

    public apply(): void {
        this.callback(this.evaluatePredicate());
    }
}

export class ViewportCallbackBuilder {
    public predicate: ViewportPredicate;
    public callback: (viewportCondition: boolean) => void;
    private page: Page;

    public constructor() {
        this.predicate = () => true;
        this.callback = noop;
    }

    public withPredicate(predicate: ViewportPredicate): this {
        this.predicate = predicate;
        return this;
    }

    public withPage(page: Page): this {
        this.page = page;
        return this;
    }

    public withCallback(callback: (viewportCondition: boolean) => void): this {
        this.callback = callback;
        return this;
    }

    public build(): ViewportCallback {
        return new ViewportCallback(this.predicate, this.callback, this.page);
    }

}