import {GLOBAL} from "../../common/globals";
import {Resolution} from "../../common/resolution";
import {autoRegister, resolve} from "../../container";
import {forAllEntries, IntersectionObserverFactory, ResizeObserverFactory} from "../../common/observation";
import {FeatureDetector} from "../../common/featureDetector";
import {customElement, property} from "lit/decorators.js";
import {html, type TemplateResult} from "lit";
import {Deferred, schedule, SingletonPromise} from "../../common/utils/promises";
import {EventBus} from "../../common/eventBus";
import {ManagingResources} from "../../common/lifetime";
import {UnLitElement} from "../../common/elements";
import {Page} from "../../common/page";

// Heights are derived from common resolutions
const QXGA_VIEWPORT_HEIGHT = 1536;
const XGA_VIEWPORT_HEIGHT = 768;
// This function is used to calculate the factor for the parallax effect. The larger the viewport, the larger the factor.
const FACTOR_FUNCTION = (x: number): number => (XGA_VIEWPORT_HEIGHT + x) / (2 * QXGA_VIEWPORT_HEIGHT);

export class ParallaxElement {
    private isInViewport: boolean;
    private parallax: Parallax;
    private intersectionObserver: IntersectionObserver | undefined;
    private element: HTMLElement;
    private readonly container: HTMLElement;
    private singletonPromise: SingletonPromise<void>;

    public constructor(
        element: HTMLElement,
        container: HTMLElement,
        private intersectionObserverFactory: IntersectionObserverFactory = resolve(IntersectionObserverFactory),
        private page: Page = resolve(Page)
    ) {
        this.singletonPromise = new SingletonPromise<void>();
        this.element = element;
        this.container = container;
        this.parallax = new Parallax();

        this.init();
    }

    public disconnect(): void {
        this.intersectionObserver?.disconnect();
    }

    public update(): void {
        this.updateBoundaries();
        if (!this.isInViewport) {
            return;
        }
        this.updateCss();
    }

    private init(): void {
        this.intersectionObserver = this.intersectionObserverFactory.create(forAllEntries(entry => {
            this.isInViewport = entry.isIntersecting;
            this.toggle();
        }));
        this.intersectionObserver.observe(this.container);

        this.updateBoundaries();
    }

    private updateBoundaries(): void {
        this.parallax.updateBoundaries({
            containerHeight: this.container.outerHeight(),
            elementHeight: this.element.outerHeight(),
            windowHeight: this.page.viewportHeight()
        });
    }

    private toggle(): void {
        if (this.isInViewport) {
            this.listenToScroll();
        } else {
            this.pause();
        }
    }

    private pause(): void {
        GLOBAL.window().removeEventListener("scroll", this.updateCss);
    }

    private listenToScroll(): void {
        this.updateCss();
        GLOBAL.window().addEventListener("scroll", this.updateCss);
    }

    private updateCss = (): void => {
        this.singletonPromise.of(() => schedule(new Promise<void>((res, _) =>
            GLOBAL.window().requestAnimationFrame(() => {
                const translation = this.parallax.computeCurrentTranslation(this.container.topOffset(), this.page.getYScrollPosition());
                this.element.style.transform = `translate3d(0,${translation}px,0)`;
                res();
            }))).as("parallax"));
    };
}

export type ParallaxBoundaries = {
    elementHeight: number;
    containerHeight: number;
    windowHeight: number;
};

export class Parallax {
    private translationRange: number;
    private scrollRange: number;

    public constructor() {
        this.translationRange = 1;
        this.scrollRange = 1;
    }

    public computeCurrentTranslation(containerOffsetTop: number, scrollY: number): string {
        const relativeViewportPosition = containerOffsetTop - scrollY;
        const scrollPercentage = 1 - relativeViewportPosition / this.scrollRange;

        return (this.translationRange * scrollPercentage).toFixed(2);
    }

    public updateBoundaries(boundaries: ParallaxBoundaries): void {
        const elementHeight = boundaries.elementHeight;
        const containerHeight = boundaries.containerHeight;
        const windowHeight = boundaries.windowHeight;

        if (elementHeight <= windowHeight) {
            this.translationRange = (elementHeight - containerHeight) * FACTOR_FUNCTION(windowHeight - elementHeight);
        } else {
            this.translationRange = (windowHeight - containerHeight) / 4;
        }
        this.scrollRange = (windowHeight - containerHeight) / 2;
    }
}

export class EopImpulse extends HTMLElement {

    private resizeObserver: ResizeObserver | undefined;

    public constructor(private resizeObserverFactory: ResizeObserverFactory = resolve(ResizeObserverFactory)) {
        super();
    }

    public connectedCallback(): void {
        this.resizeObserver = this.resizeObserverFactory.create(() => this.setDimensions());
        this.resizeObserver.observe(this.parentElement!);

        this.setDimensions();
    }

    public disconnectedCallback(): void {
        this.resizeObserver!.disconnect();
    }

    private setDimensions(): void {
        this.style.setProperty("--impulse-width", this.offsetWidth + "px");
        this.style.setProperty("--impulse-height", this.offsetHeight + "px");
    }
}

export class EopParallaxTeaser extends ManagingResources(HTMLElement) {

    private instance: ParallaxElement | undefined;

    public constructor(
        private resolution: Resolution = resolve(Resolution),
        private features: FeatureDetector = resolve(FeatureDetector)
    ) {
        super();
    }

    public connectedCallback(): void {
        if (this.features.isTouchDevice()) {
            return;
        }
        const parallaxElement = this.querySelector<HTMLElement>(".parallax-layer")!;

        this.instance = new ParallaxElement(parallaxElement, this);

        this.resolution.onWindowResize(() => this.instance?.update(), this);
    }

    public disconnectedCallback(): void {
        this.instance?.disconnect();
    }

}

export const HERO_TEASER_READY_EVENT = "hero-teaser-ready";

export class EopHeroTeaser extends HTMLElement {

    public constructor(private eventBus: EventBus = resolve(EventBus)) {
        super();
    }

    public connectedCallback(): void {
        const videoElements = this.querySelectorAll<EopHeroTeaserVideoLoop>("eop-heroteaser-video-loop");
        for (const videoElement of videoElements) {
            videoElement.whenVisuallyReady().then(() => this.triggerAnimation());
        }

        const imageElements = this.querySelectorAll(".hero-teaser-image");
        for (const imageElement of imageElements) {
            const imageEl = imageElement.querySelector<HTMLImageElement>("img");
            if (!imageEl?.complete) {
                this.addEventListener("imageLoaded", () => this.triggerAnimation());
            } else {
                this.triggerAnimation();
            }
        }

        if (imageElements.length === 0 && videoElements.length === 0) {
            this.triggerAnimation();
        }
    }

    private triggerAnimation(): void {
        this.eventBus.dispatchEvent(HERO_TEASER_READY_EVENT, {});
        this.classList.remove("image-loading");
    }
}

@autoRegister()
export class SimpleHtmlImageFactory {

    public create(): HTMLImageElement {
        return new Image();
    }
}

@customElement("eop-heroteaser-video-loop")
export class EopHeroTeaserVideoLoop extends UnLitElement {

    @property({attribute: "video-src"})
    private src: string;
    @property({attribute: "thumbnail-src"})
    private thumbnailSrc: string;

    private loading: Deferred<void>;

    public constructor(private simpleImageFactory: SimpleHtmlImageFactory = resolve(SimpleHtmlImageFactory)) {
        super();
        this.loading = new Deferred();
    }

    public connectedCallback(): void {
        super.connectedCallback();
        if (this.thumbnailSrc) {
            this.registerOnloadListener();
        }
    }

    public render(): TemplateResult {
        return html`
            <video @playing=${this.visuallyReady} poster=${this.thumbnailSrc} autoplay loop muted playsinline>
                <source src=${this.src}>
            </video>
        `;
    }

    private registerOnloadListener(): void {
        // hack to detect when the poster image is loaded
        const image = this.simpleImageFactory.create();
        image.onload = () => this.visuallyReady();
        image.src = this.thumbnailSrc;
    }

    public async whenVisuallyReady(): Promise<void> {
        return this.loading.promise;
    }

    private visuallyReady(): void {
        this.loading.resolve();
    }
}

customElements.define("eop-heroteaser", EopHeroTeaser);
customElements.define("eop-impulse", EopImpulse);
customElements.define("eop-parallax-teaser", EopParallaxTeaser);
