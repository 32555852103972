import {eopCustomEvent} from "../../../../common/utils/events";

export const NAVIGATION_TOGGLE_FLYOUT_EVENT = "eop-navigation-toggle-flyout";

export type NavigationToggleFlyoutEventPayload = {
    id: string;
    label: string;
}

export type NavigationToggleFlyoutEvent = CustomEvent<NavigationToggleFlyoutEventPayload>;

export function navigationToggleFlyoutEvent(id: string, label: string): NavigationToggleFlyoutEvent {
    return eopCustomEvent(NAVIGATION_TOGGLE_FLYOUT_EVENT, {id, label});
}