import type {Animation, IndexedElement, TransitionAdvice} from "./animation";
import {noop} from "./utils/functions";
import {resolve} from "../container";
import {Timeout} from "./timeout";
import {ACTIVATION_CHANGE, ActivationChangeEventParams} from "./utils/events";

export const ACTIVE_SLIDE_CLASS_NAME = "enbw-slide-model"; //REMINDER let broadcast listen to event cancellation instead on certain style classes


export class SlideModel {

    private elements: HTMLElement[];
    private prePrev: TransitionAdvice;
    private postPrev: TransitionAdvice;
    private preNext: TransitionAdvice;
    private postNext: TransitionAdvice;

    public constructor(
        elements: HTMLElement[],
        private activeClass: string = "active",
        initial: number = 0,
        private timeout: Timeout = resolve(Timeout)
    ) {
        this.elements = elements;
        this.prePrev = noop;
        this.postPrev = noop;
        this.preNext = noop;
        this.postNext = noop;
        const activePosition = this.elements.findIndex(e => e.classList.contains(activeClass));
        const initialPosition = activePosition !== -1 ? activePosition : initial;

        this.elements.forEach(e => e.classList.add(ACTIVE_SLIDE_CLASS_NAME));
        this.elements[initialPosition].classList.add(this.activeClass);

        this.elements.forEach(e => {
            e.dispatchEvent(new CustomEvent(ACTIVATION_CHANGE, {
                bubbles: true,
                detail: new ActivationChangeEventParams("init", e, ACTIVE_SLIDE_CLASS_NAME)
            }));
        });
    }

    public withPrePrev(pre: TransitionAdvice): this {
        this.prePrev = pre;
        return this;
    }

    public withPostPrev(post: TransitionAdvice): this {
        this.postPrev = post;
        return this;
    }

    public withPreNext(next: TransitionAdvice): this {
        this.preNext = next;
        return this;
    }

    public withPostNext(next: TransitionAdvice): this {
        this.postNext = next;
        return this;
    }

    public activeIndex(): number {
        return this.elements.findIndex(e => e.classList.contains(this.activeClass));
    }

    public selectActive(selected: number | HTMLElement, animation: Animation): void {
        const targetIndex = typeof selected === "number"
            ? selected
            : this.elements.indexOf(selected);

        if (targetIndex >= 0 && targetIndex !== this.activeIndex()) {
            this.changeSelection(animation, () => targetIndex);
        }
    }

    public selectPrev(animation: Animation): void {
        const preparedAnimation = animation.onStart(this.prePrev)
            .onDone(this.postPrev);

        this.changeSelection(preparedAnimation, i => this.getPreviousIndex(i));
    }

    public selectNext(animation: Animation): void {
        const preparedAnimation = animation.onStart(this.preNext)
            .onDone(this.postNext);

        this.changeSelection(preparedAnimation, i => this.getNextIndex(i));
    }

    private getPreviousIndex(activePosition: number): number {
        return (activePosition - 1 + this.elements.length) % this.elements.length;
    }

    private getNextIndex(activePosition: number): number {
        return (activePosition + 1) % this.elements.length;
    }

    private changeSelection(animation: Animation, indexChange: (oldPosition: number) => number): void {
        const index = this.activeIndex();
        const newIndex = indexChange(index);
        const hide = {
            i: index,
            element: this.elements.at(index)!
        };
        const show = {
            i: newIndex,
            element: this.elements.at(newIndex)!
        };
        animation.pre(hide, show);

        if (animation.duration > 0) {
            this.elements.forEach(e => {
                e.dispatchEvent(new CustomEvent(ACTIVATION_CHANGE, {
                    bubbles: true,
                    detail: new ActivationChangeEventParams("animate", e, ACTIVE_SLIDE_CLASS_NAME)
                }));
            });
            this.timeout.delay(() => {
                this.animate(hide, show, animation);
            }, animation.duration);
        } else {
            this.animate(hide, show, animation);
        }
    }

    private animate(hide: IndexedElement, show: IndexedElement, animation: Animation): void {
        hide.element.classList.remove(this.activeClass);
        hide.element.dispatchEvent(new CustomEvent(ACTIVATION_CHANGE, {
            bubbles: true,
            detail: new ActivationChangeEventParams("hide", hide.element, ACTIVE_SLIDE_CLASS_NAME)
        }));

        show.element.classList.add(this.activeClass);
        animation?.post(hide, show);
        show.element.dispatchEvent(new CustomEvent(ACTIVATION_CHANGE, {
            bubbles: true,
            detail: new ActivationChangeEventParams("show", show.element, ACTIVE_SLIDE_CLASS_NAME)
        }));
    }
}
