import {LoadAfterConfirmationEvents} from "./loadAfterConfirmation";
import {Load} from "../../common/load";
import {resolve} from "../../container";


export class EopFlippingBook extends HTMLElement {
    public constructor(
        private loadAfterConfirmationEvents: LoadAfterConfirmationEvents = resolve(LoadAfterConfirmationEvents),
        private load: Load = resolve(Load)
    ) {
        super();
    }

    public connectedCallback(): void {
        const id = this.getAttribute("fbo-id");

        this.loadAfterConfirmationEvents.onConfirmation("flippingbook", () => {
            return this.load.script("https://online.flippingbook.com/EmbedScriptUrl.aspx?m=redir&hid=" + id);
        });
    }
}

customElements.define("eop-flipping-book", EopFlippingBook);