import {LanguageProfile, LanguagesService} from "../../languages";
import {autoRegister, resolve} from "../../../container";

@autoRegister()
export class LocatedDates {

    public constructor(private languageService: LanguagesService = resolve(LanguagesService)) {
    }

    public toLocalPartialDateString(date: Date): string {
        return date.toLocaleDateString(this.language().id, this.language().partialDateFormat);
    }

    public toLocalDateString(date: Date): string {
        return date.toLocaleDateString(this.language().id, this.language().dateFormat);
    }

    public toLocalDateTimeString(date: Date, timezoneOffset?: string): string {
        const dateWithOffset = utcToZonedTime(date, timezoneOffset);
        return dateWithOffset.toLocaleDateString(this.language().id, this.language().dateTimeFormat);
    }

    private language(): LanguageProfile {
        return this.languageService.activeLanguage();
    }
}

const utcToZonedTime = (utcDate: Date, zoneOffset: string = "+00:00"): Date => {
    const offsetMilliseconds = toMillis(zoneOffset);
    const date = new Date(utcDate.getTime() - offsetMilliseconds);
    const result = new Date(0);
    result.setFullYear(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    result.setHours(date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds(), date.getUTCMilliseconds());
    return result;
};

const toMillis = (timezoneString: string): number => {
    const timezoneHHMM = /^([+-])?(\d{2}):?(\d{2})$/;
    const token = timezoneHHMM.exec(timezoneString);
    if (!token) {
        return NaN;
    }
    const hours = parseInt(token[2], 10);
    const minutes = parseInt(token[3], 10);
    if (hours < -23 || hours > 23) {
        return NaN;
    }
    if (minutes < 0 || minutes > 59) {
        return NaN;
    }
    const absoluteOffset = Math.abs(hours) * 60 * 60 * 1000 + minutes * 60 * 1000;
    return token[1] === "-" ? absoluteOffset : -absoluteOffset;
};