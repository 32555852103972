import {eopCustomEvent} from "../../common/utils/events";

export const MACHERBUS_PROJECT_VOTE_EVENT = "eop-macherbus-project-vote";
export const MACHERBUS_CATEGORY_VOTE_EVENT = "eop-macherbus-category-vote";

export type MacherbusProjectVoteEvent = CustomEvent<MacherbusProjectVoteEventData>;

export type MacherbusProjectVoteEventData = { projectId: string };

export type MacherbusCategoryVoteEvent = CustomEvent<MacherbusCategoryVoteEventData>;

export type MacherbusCategoryVoteEventData = {
    categoryId: string;
    projectId: string;
};

export function macherbusProjectVoteEvent(projectId: string): MacherbusProjectVoteEvent {
    return eopCustomEvent(MACHERBUS_PROJECT_VOTE_EVENT, {projectId: projectId});
}

export function macherbusCategoryVoteEvent(categoryId: string, projectId: string): MacherbusCategoryVoteEvent {
    return eopCustomEvent(MACHERBUS_CATEGORY_VOTE_EVENT, {categoryId: categoryId, projectId: projectId});
}