import {
    type ElementParams,
    formatPercentage,
    Question,
    type QuestionCoordinates,
    type StatisticsPerOption,
    SurveyAbstractQuestionElement,
    type SurveyNavigation
} from "./abstractQuestion";
import {elementFrom} from "../../common/utils/html";
import {EopSurveyStatistics} from "./surveyStatistics";
import ICON_THUMBS_UP from "../../../../svgpictograms/thumbs_up.svg";
import ICON_THUMBS_DOWN from "../../../../svgpictograms/thumbs_down.svg";

export type BinaryQuestionOptions = {
    yes: string;
    no: string;
};

export class BinaryQuestion extends Question<BinaryQuestionOptions> {

    public constructor(coordinates: QuestionCoordinates<BinaryQuestionOptions>) {
        super(coordinates);
    }

    public createInteractableElement(navigation: SurveyNavigation, params: ElementParams): EopSurveyBinaryQuestion {
        return new EopSurveyBinaryQuestion(
            () => this.createPromptContent(),
            (data: StatisticsPerOption) => this.createResultContent(data),
            navigation,
            this.coordinates,
            params);
    }

    public createStatisticsElement(data: StatisticsPerOption): EopSurveyStatistics {
        return new EopSurveyStatistics(this.createResultContent(data), this.coordinates);
    }

    private createPromptContent(): Element {
        const element = elementFrom(`
            <div class="binary-question-prompt">
                <input type="text" hidden/>
                <div class="action-element survey-input-yes">
                    <button type="submit" class="survey-binary-button" data-tracking-label="yes">${ICON_THUMBS_UP}</button>
                    <p class="survey-binary-option-text">${this.coordinates.options.yes}</p>
                </div>
                <div class="action-element survey-input-no">
                    <button type="submit" class="survey-binary-button" data-tracking-label="no">${ICON_THUMBS_DOWN}</button>
                    <p class="survey-binary-option-text">${this.coordinates.options.no}</p>
                </div>
            </div>`);
        element.querySelector(".survey-input-yes button")!.addEventListener("click", () => {
            element.querySelector("input")!.value = "yes";
        });
        element.querySelector(".survey-input-no button")!.addEventListener("click", () => {
            element.querySelector("input")!.value = "no";
        });
        return element;
    }

    private createResultContent(data: StatisticsPerOption): Element {
        return elementFrom(`
            <div class="binary-question-result">
                <div class="action-element survey-input-yes">
                    <div class="survey-binary-button">${ICON_THUMBS_UP}</div>
                    <div class="survey-binary-result">${formatPercentage(data.yes?.percentage)}</div>
                    <p class="survey-binary-option-text">${this.coordinates.options.yes}</p>
                </div>
                <div class="action-element survey-input-no">
                    <div class="survey-binary-button">${ICON_THUMBS_DOWN}</div>
                    <div class="survey-binary-result">${formatPercentage(data.no?.percentage)}</div>
                    <p class="survey-binary-option-text">${this.coordinates.options.no}</p>
                </div>
            </div>`);
    }
}

export class EopSurveyBinaryQuestion extends SurveyAbstractQuestionElement<BinaryQuestionOptions> {
    private submitYesButton: HTMLElement;
    private submitNoButton: HTMLElement;

    public constructor(
        createPromptContent: () => Element,
        createResultContent: (data: StatisticsPerOption) => Element,
        navigation: SurveyNavigation,
        questionData: QuestionCoordinates<BinaryQuestionOptions>,
        params: ElementParams
    ) {
        super(createPromptContent, createResultContent, navigation, questionData, params);
    }

    protected specificConnectedCallback(): void {
        this.submitYesButton = this.promptElement.querySelector(".survey-input-yes button")!;
        this.submitNoButton = this.promptElement.querySelector(".survey-input-no button")!;
    }

    protected async submitAndGetStatistics(event: SubmitEvent): Promise<StatisticsPerOption> {
        const value = this.extractAnswer(event);
        if (!value) {
            return {};
        }

        return this.navigation.answerAndGetStatistics(this.questionData.id, value);
    }

    private extractAnswer(event: SubmitEvent): string | null {
        if (event.submitter === this.submitYesButton) {
            return "yes";
        }
        if (event.submitter === this.submitNoButton) {
            return "no";
        }
        if (event.target instanceof HTMLElement) {
            // 2022-03-22 necessary since event.submitter is not defined in Safari
            return event.target.querySelector("input")?.value ?? null;
        }
        return null;
    }

    protected hasSubmitButton(): boolean {
        return false;
    }
}


customElements.define("eop-survey-binary-question", EopSurveyBinaryQuestion);