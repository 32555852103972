import {type PropertyMap} from "./utils/objects";
import {isArray} from "../bootstrap/common/arrays";
import {isString} from "../bootstrap/common/strings";
import {fragmentContaining} from "./utils/html";


function nodeOf(html: Node | string): Node {
    if (isString(html)) {
        return fragmentContaining(html);
    } else {
        return html;
    }
}

export class TemplateModel {
    private element: Node;

    public constructor(html: Node | string) {
        this.element = nodeOf(html);
    }

    public isConnected(): boolean {
        return this.element.parentNode !== null;
    }

    public connect(parent: Element): this {
        parent.replaceChildren(this.element);
        this.element = parent;
        return this;
    }

    public unwrapFragment(): this {
        if (this.element instanceof DocumentFragment && this.element.childElementCount === 1 && this.element.firstElementChild) {
            this.element = this.element.firstElementChild;
        }
        return this;
    }

    public apply(args: PropertyMap): this {
        for (const [key, value] of Object.entries(args)) {
            if (!value) {
                continue;
            } else if (isArray(value)) {
                this.applyArray(key, value);
            } else {
                this.applyScalar(key, value);
            }
        }
        return this;
    }

    private applyArray(key: string, value: any[]): void {
        const slot = this.dataContainerSlot(key);
        if (slot) {
            slot.replaceChildren(...value);
        }
    }

    private applyScalar(key: string, value: any): void {
        const dataSlot = this.dataSlot(key);
        if (dataSlot && value instanceof Element) {
            value.setAttribute("data-slot", key);
            dataSlot.replaceWith(value);
        } else if ((dataSlot instanceof HTMLElement || dataSlot instanceof SVGElement) && (!value || (value instanceof Text && value.textContent?.trim() === ""))) {
            dataSlot.replaceChildren();
            dataSlot.style.display = "none";
        } else if (dataSlot) {
            throw new Error("slot replacement must preserve the data-slot attribute, only Element | falsy is allowed, but was:" + value);
        }
        const containerSlot = this.dataContainerSlot(key);
        if (containerSlot) {
            containerSlot.replaceChildren(value);
        }
    }

    public dataContainerSlot(key: string): Element | undefined {
        if (this.element instanceof Element && this.element.matches(`[data-container-slot=${key}]`)) {
            return this.element;
        } else if (this.element instanceof Element || this.element instanceof DocumentFragment) {
            return this.element.querySelector(`[data-container-slot=${key}]`) ?? undefined;
        } else {
            return undefined;
        }
    }

    public dataSlot(key: string): Element | undefined {
        if (this.element instanceof Element || this.element instanceof DocumentFragment) {
            return this.element.querySelector(`[data-slot=${key}]`) ?? undefined;
        } else {
            return undefined;
        }
    }

    public dataTarget(target: string): Element | undefined {
        if (this.element instanceof Element && this.element.matches(`[data-target=${target}]`)) {
            return this.element;
        } else if (this.element instanceof Element || this.element instanceof DocumentFragment) {
            return this.element.querySelector(`[data-target=${target}]`) ?? undefined;
        } else {
            return undefined;
        }
    }

    public addClasses(...classes: string[]): this {
        classes.filter(clazz => clazz.length > 0)
            .forEach(clazz => this.asElement()?.classList.add(clazz));
        return this;
    }

    public withAttribute(key: string, value: string): this {
        this.asElement()?.setAttribute(key, value);
        return this;
    }

    public onClick(target: string, listener: EventListener): this {
        return this.on("click", target, listener);
    }

    public on(event: string, target: string, listener: EventListener): this {
        this.dataTarget(target)?.addEventListener(event, listener);
        return this;
    }

    public asElement(): Element | undefined {
        if (this.element instanceof Element) {
            return this.element;
        } else if (this.element instanceof DocumentFragment && this.element.childElementCount === 1 && this.element.firstElementChild instanceof Element) {
            return this.element.firstElementChild;
        } else {
            return undefined;
        }
    }


    public asHTML(): string {
        if (this.element instanceof Element) {
            return this.element.outerHTML;
        } else {
            const element = this.element.cloneNode(true);
            const parent = document.createElement("div");
            parent.replaceChildren(element);
            return parent.innerHTML;
        }
    }

    public asFragments(): DocumentFragment | Element | string {
        if (this.element instanceof DocumentFragment || this.element instanceof Element) {
            return this.element;
        } else {
            return this.asHTML();
        }
    }
}