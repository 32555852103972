import {SessionStorage} from "../../common/clientStorage";
import {autoRegister, resolve} from "../../container";
import type {PropertyMap} from "../../common/utils/objects";
import type {Vote} from "./vote";

@autoRegister()
export class MacherbusStorage {

    private readonly sessionStorageKey = "macherbus";

    public constructor(private sessionStorage: SessionStorage = resolve(SessionStorage)) {
    }

    public saveVote(votingId: string, categoryId: string, projectId: string): void {
        this.sessionStorage.saveInPath(this.sessionStorageKey, [votingId, categoryId], projectId);
    }

    public votedProjectInCategory(votingId: string, categoryId: string): string | undefined {
        return this.sessionStorage.fetchFromPath<string>([this.sessionStorageKey, votingId, categoryId]);
    }

    public votes(votingId: string): Vote[] {
        const storedVotes = this.sessionStorage.fetchFromPath<PropertyMap<string>>([this.sessionStorageKey, votingId]);
        const votes = [];
        for (const categoryId in storedVotes) {
            const projectId = storedVotes[categoryId];
            if (projectId) {
                votes.push({categoryId, projectId});
            }
        }
        return votes;
    }
}