import {ModalDialogFactory} from "./modalDialog";
import {Dictionary} from "../dictionary";
import {resolve} from "../../../container";


export class EopDownloadDialog extends HTMLElement {

    public constructor(private modalDialog: ModalDialogFactory = resolve(ModalDialogFactory)) {
        super();
    }

    public connectedCallback(): void {
        const dialogElement = this.querySelector(".download-dialog-content");

        if (!dialogElement) {
            return;
        }

        const dialog = this.modalDialog.create()
            .withDictionary(Dictionary.of(this))
            .withContent(dialogElement);

        const closeButtons = dialogElement.querySelectorAll(".close");
        closeButtons.forEach(element => element.addEventListener("click", () => dialog.fadeOut()));

        this.addEventListener("click", event => {
            event.preventDefault();
            dialog.open();
        });
    }

}

customElements.define("eop-download-dialog", EopDownloadDialog);