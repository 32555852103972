import type {RenewableNumberRequest, RenewableNumberResponse} from "./renewableNumberRequest";
import {autoRegister} from "../../container";
import {promiseFromJsonResponse} from "../../common/utils/promises";

@autoRegister()
export class RenewableEnergyService {
    private currentNumberUrl: string;

    public constructor() {
        this.currentNumberUrl = "/enbw-eop/eop-app-renewable-energy/rest/stable/performance/current";
    }

    public async getCurrent(request: RenewableNumberRequest): Promise<RenewableNumberResponse> {
        const response = await fetch(`${this.currentNumberUrl}/${request.technology}/${request.unit}/${request.precision}`);
        return promiseFromJsonResponse(response);
    }
}