import {GLOBAL} from "./globals";
import {autoRegister, resolve} from "../container";
import {elementFrom} from "./utils/html";

export const SCROLLBAR_HELPER_ID = "scrollBarHelperElement";

@autoRegister()
export class PageScrollbar {

    private originalBodyPadding: number;
    private scrollbarHidden: boolean;

    public constructor(private scrollBarWidthService: ScrollBarWidthService = resolve(ScrollBarWidthService)) {
        this.originalBodyPadding = this.determineOriginalBodyPadding();
        this.scrollbarHidden = false;
    }

    private determineOriginalBodyPadding(): number {
        const paddingRightStyle = getComputedStyle(GLOBAL.bodyElement()).paddingRight;
        return parseInt(paddingRightStyle); // ignores possible "px" suffix
    }

    public disablePageScrollability(): void {
        if (this.hasScrollbar()) {
            this.addScrollbarPlaceholder();
            this.scrollbarHidden = true;
        }
        GLOBAL.htmlElement().classList.add("page-scroll-disabled");
    }

    public enablePageScrollability(): void {
        if (!GLOBAL.htmlElement().classList.contains("page-scroll-disabled")) {
            return;
        }

        GLOBAL.htmlElement().classList.remove("page-scroll-disabled");
        this.removeScrollbarPlaceholder();
        this.scrollbarHidden = false;
    }

    private removeScrollbarPlaceholder(): void {
        GLOBAL.bodyElement().style.setProperty("--scrollbar-placeholder-padding", `${this.originalBodyPadding}px`);
    }

    private addScrollbarPlaceholder(): void {
        this.originalBodyPadding = this.determineOriginalBodyPadding();

        const scrollBarWidth = this.scrollBarWidthService.getScrollbarWidth();
        if (scrollBarWidth > 0) {
            const newPadding = this.originalBodyPadding + scrollBarWidth;
            GLOBAL.bodyElement().style.setProperty("--scrollbar-placeholder-padding", `${newPadding}px`);
        }
    }

    private hasScrollbar(): boolean {
        const scrollBarWidth = this.scrollBarWidthService.getScrollbarWidth();

        return scrollBarWidth > 0
            && !this.scrollbarHidden;
    }
}

@autoRegister()
export class ScrollBarWidthService {
    private readonly scrollbarWidth: number;

    public constructor() {
        this.scrollbarWidth = this.determineScrollbarWidth();
    }

    public getScrollbarWidth(): number {
        return this.scrollbarWidth;
    }

    private determineScrollbarWidth(): number {
        const helperElement = elementFrom<HTMLElement>(`<div id='${SCROLLBAR_HELPER_ID}' style='position: absolute; top: -9999px; width: 50px; height: 50px; overflow: scroll;'><div class='helper-child'></div></div>`);
        const helperChild = helperElement.querySelector(".helper-child")!;
        GLOBAL.bodyElement().append(helperElement);

        const scrollbarWidth = helperElement.getBoundingClientRect().width - helperChild.getBoundingClientRect().width;
        helperElement.remove();

        return scrollbarWidth;
    }
}