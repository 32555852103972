import {PersistentTrigger, type TriggerFactory} from "./triggers";
import {Page} from "../page";
import {GLOBAL} from "../globals";
import {autoRegister, resolve} from "../../container";

import {done, fresh} from "../lifetime";
import {prepareEvents} from "../utils/events";

export class ScrollTrigger extends PersistentTrigger {
    private readonly percentageToScroll: number;

    public constructor(
        private page: Page,
        private element: Element,
        callback: () => void
    ) {
        super(callback);
        this.percentageToScroll = element.getAttribute("data-trigger-percent-scrolled")?.toInt() ?? 50;
        prepareEvents(GLOBAL.window())
            .boundTo(fresh(element, "scrolltrigger"))
            .on("scroll", () => this.onPercentageScrolled());
    }

    private onPercentageScrolled(): void {
        if (!this.triggered && this.page.percentageScrolled() >= this.percentageToScroll) {
            this.trigger();
            this.triggered = true;
        }
    }

    public stop(): void {
        super.stop();
        done(this.element, "scrolltrigger");
    }
}

@autoRegister()
export class ScrollTriggerFactory implements TriggerFactory {

    public constructor(private page: Page = resolve(Page)) {

    }

    public createTrigger(element: Element, callback: () => void): ScrollTrigger {
        return new ScrollTrigger(this.page, element, callback);
    }
}