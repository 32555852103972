export function random(): number {
    return Math.random();
}

export function randomInt(maxInclusive: number): number {
    return Math.floor(Math.random() * (maxInclusive + 1));
}

export function d100(chance: number): boolean {
    return random() * 100 < chance;
}