export const COLLAPSED_ROW_CLASS = "collapsed-row";
export const COLLAPSED_ROW_HEADER_CLASS = "collapsed-row-header";

class CollapsibleRows {

    private readonly toggle: () => void;

    public constructor(private headerRow: Element, private rows: Element[]) {
        headerRow.classList.add(COLLAPSED_ROW_HEADER_CLASS);
        headerRow.setAttribute("data-tracking-label", "collapsible-row-toggle");

        this.toggle = () => {
            this.headerRow.classList.toggle("open");
            this.rows.forEach(row => row.classList.toggle(COLLAPSED_ROW_CLASS));
        };

        this.headerRow.addEventListener("click", this.toggle);
    }

    public cleanup(): void {
        this.headerRow.removeEventListener("click", this.toggle);
    }
}

export class EopCollapsibleTable extends HTMLElement {
    private collapsibleRows: CollapsibleRows[];

    public constructor() {
        super();
    }

    public connectedCallback(): void {
        let headerRow: Element | undefined = undefined;
        let rows: Element[] = [];
        this.collapsibleRows = [];

        for (const row of this.querySelectorAll("tbody ." + COLLAPSED_ROW_CLASS)) {
            if (row.previousElementSibling === null) {
                const theadRow = this.querySelector("thead tr");
                if (theadRow !== null) {
                    headerRow = theadRow;
                } else {
                    headerRow = row;
                    continue;
                }
            } else if (!row.previousElementSibling.classList.contains(COLLAPSED_ROW_CLASS)) {
                headerRow = row.previousElementSibling;
            }

            rows.push(row);

            if (headerRow && (!row.nextElementSibling?.classList.contains(COLLAPSED_ROW_CLASS))) {
                this.collapsibleRows.push(new CollapsibleRows(headerRow, rows));
                rows = [];
            }
        }
    }

    public disconnectedCallback(): void {
        this.collapsibleRows.forEach(row => row.cleanup());
    }
}

customElements.define("eop-collapsible-table", EopCollapsibleTable);