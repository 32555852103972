import {LocalDate} from "./utils/dates/localDate";
import {DatePeriod} from "./utils/dates/datePeriod";
import {GLOBAL} from "./globals";
import {autoRegister} from "../container";
import {isPresent, isUndefined} from "./utils/basics";

const SHARED_DOMAIN = ".enbw.com";

export class Cookie {

    public constructor(public name: string, public value: string) {
    }

    public static fromCookieString(cookieString: string): Cookie | undefined {
        const indexOfSeparator = cookieString.indexOf("=");
        if (indexOfSeparator === -1) {
            return undefined;
        }
        const cookieName = cookieString.substring(0, indexOfSeparator).trim();
        const cookieValue = cookieString.substring(indexOfSeparator + 1).trim();
        return new Cookie(cookieName, cookieValue);
    }
}

@autoRegister()
export class Cookies {

    public constructor(
        private document: Document = GLOBAL.document(),
        private window: Window = GLOBAL.window()
    ) {
    }

    public get(cookieName: string): string | undefined {
        for (const cookieString of this.getAllCookies()) {
            const cookie = Cookie.fromCookieString(cookieString);
            if (cookie?.name === cookieName) {
                return cookie.value;
            }
        }

        return undefined;
    }

    public exists(cookieName: string): boolean {
        return this.getAllCookies().some(cookieString => Cookie.fromCookieString(cookieString)?.name === cookieName);
    }

    private getAllCookies(): string[] {
        return this.document.cookie.split(";")
            .map(c => this.decodeCookie(c))
            .filter(isPresent);
    }

    private decodeCookie(cookie: string): string | undefined {
        try {
            return decodeURIComponent(cookie);
        } catch (e) {
            console.error("cannot decode cookie: ", cookie, e);
            return undefined;
        }
    }

    public setForOneYear(cookieName: string, cookieValue: string): void {
        this.set(cookieName, cookieValue);
    }

    public remove(cookieName: string): void {
        this.set(cookieName);
    }

    private set(cookieName: string, cookieValue?: string): void {
        const options = this.defaultOptions();
        let value = cookieValue;
        if (isUndefined(cookieValue)) {
            options.expires = new Date(0).toUTCString();
            value = "";
        }
        let cookie = `${cookieName}=${value};${options.expires};${options.path};`;
        if (options.domain) {
            cookie += `${options.domain};`;
        }
        this.document.cookie = cookie;
    }

    private defaultOptions(): CookiesOptions {
        const oneYearInTheFuture = LocalDate.today().add(new DatePeriod(1, "year")).toDate()!.toUTCString();
        const options: CookiesOptions = {
            path: "path=/",
            expires: oneYearInTheFuture
        };

        const subDomain = this.window.location.host;
        if (subDomain.endsWith(SHARED_DOMAIN)) {
            options.domain = "domain=" + SHARED_DOMAIN;
        } else {
            options.domain = "domain=" + subDomain.slice(subDomain.indexOf(".")); // this is how GTM sets domains for websites like www.geohardt.de
        }

        return options;
    }
}

type CookiesOptions = {
    path: string;
    domain?: string;
    expires?: string;
};
