import {forAllEntries, IntersectionObserverFactory} from "../../../common/observation";
import {autoRegister, resolve} from "../../../container";
import type {Lifetime} from "../../../common/lifetime";


export type IntersectingViewportCallback = (triggered: boolean) => void;

@autoRegister()
export class IntersectionViewportObserver {

    public observe(): ViewportObservation {
        return new IntersectionViewportObservation();
    }

}

export interface ViewportObservation {
    boundTo: (lifetime: Lifetime) => this;

    onChange: (viewportCallback: IntersectingViewportCallback) => this;

    main: (main: Element, outOfViewport?: boolean, threshold?: number) => this;

    footer: (footer: Element) => this;
}

export class IntersectionViewportObservation implements ViewportObservation {

    private mainObserver: IntersectionObserver;
    private footerObserver: IntersectionObserver;
    private viewportCallback: IntersectingViewportCallback;

    private mainIntersectCompletely: boolean;
    private mainTriggered: boolean;
    private footerIntersects: boolean;

    public constructor(
        private intersectionObserverFactory: IntersectionObserverFactory = resolve(IntersectionObserverFactory)
    ) {
        this.mainIntersectCompletely = false;
        this.mainTriggered = false;
        this.footerIntersects = false;
    }

    public boundTo(lifetime: Lifetime): this {
        lifetime.onDrop(() => {
            this.mainObserver?.disconnect();
            this.footerObserver?.disconnect();
        });
        return this;
    }

    public onChange(viewportCallback: IntersectingViewportCallback): this {
        this.viewportCallback = viewportCallback;
        return this;
    }

    public main(main: Element, outOfViewport: boolean = false, threshold: number = 0): this {
        this.mainObserver = this.intersectionObserverFactory.create(forAllEntries((entry) => {
            if (outOfViewport) {
                this.mainTriggered = !entry.isIntersecting && entry.boundingClientRect.bottom < threshold;
            } else {
                this.mainIntersectCompletely = entry.intersectionRatio === 1;
                this.mainTriggered = entry.isIntersecting;
            }
            this.handleIntersection();
        }), {threshold: [0], rootMargin: `-${threshold}px 0px 0px 0px`}, "main");
        this.mainObserver.observe(main);
        return this;
    }

    public footer(footer: Element): this {
        this.footerObserver = this.intersectionObserverFactory.create(forAllEntries((entry) => {
            this.footerIntersects = entry.isIntersecting || entry.boundingClientRect.top < 0;
            this.handleIntersection();
        }), {threshold: [0]}, "footer");
        this.footerObserver.observe(footer);
        return this;
    }

    private handleIntersection(): void {
        const triggered = this.mainTriggered && !this.footerIntersects || this.mainIntersectCompletely;
        this.viewportCallback(triggered);
    }
}
