import {autoRegister} from "../container";
import type {PropertyMap} from "./utils/objects";

@autoRegister()
export class Load {
    public script(url: string, attributes?: PropertyMap<string>): Promise<void> {
        return new Promise((resolve, reject) => {
            const script = document.createElement("script");
            script.defer = true;
            script.async = true;
            script.type = "text/javascript";
            script.src = url;
            script.addEventListener("load", () => resolve());
            script.addEventListener("error", reject);
            for (const attributesKey in attributes) {
                script.setAttribute(attributesKey, attributes[attributesKey] ?? "");
            }
            document.head.appendChild(script);
        });
    }
}