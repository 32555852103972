import {autoRegister} from "../container";
import {GLOBAL} from "./globals";
import {Deferred} from "./utils/promises";

@autoRegister()
export class Interval {

    private runningTasks: IntervalTask[];

    public constructor(private window: Window = GLOBAL.window()) {
        this.runningTasks = [];
    }

    public repeat(handler: () => void, interval: number, maxCount: number = 0): Promise<void> {
        const deferred = new Deferred<void>();
        const promise = deferred.promise;
        if (maxCount > 0) {
            let counter = 0;
            const intervalId = this.window.setInterval(() => {
                handler();
                counter++;
                if (counter === maxCount) {
                    this.resolve(promise);
                }
            }, interval);
            const intervalTask = new IntervalTask(intervalId, handler, deferred);
            this.runningTasks.push(intervalTask);
        } else {
            const intervalId = this.window.setInterval(handler, interval);
            const intervalTask = new IntervalTask(intervalId, handler, deferred);
            this.runningTasks.push(intervalTask);
        }

        return promise;
    }

    public cancel(promise: Promise<any>): void {
        const runningTask = this.getRunningTask(promise);
        if (!runningTask) {
            return;
        }
        this.window.clearInterval(runningTask.getId());
        runningTask.cancel();
        this.runningTasks.removeAll(runningTask);
    }

    private resolve(promise: Promise<any>): void {
        const runningTask = this.getRunningTask(promise);
        if (!runningTask) {
            return;
        }
        this.window.clearInterval(runningTask.getId());
        runningTask.resolve();
        this.runningTasks.removeAll(runningTask);
    }

    private getRunningTask(promise: Promise<any>): IntervalTask | null {
        return this.runningTasks.findFirst(task => task.promise === promise) ?? null;
    }
}

class IntervalTask {

    public promise: Promise<void>;

    public constructor(
        public id: number,
        public callback: () => any,
        private deferred: Deferred<void>
    ) {
        this.promise = deferred.promise;
    }

    public getId(): number {
        return this.id;
    }

    public cancel(): void {
        this.deferred.rejectExpected();
    }

    public resolve(): void {
        this.deferred.resolve();
    }
}