import {LoadAfterConfirmationEvents} from "../../../page/elements/loadAfterConfirmation";
import {resolve} from "../../../container";
import {EOP_ERRORS} from "../../../common/utils/promises";
import {elementFrom} from "../../../common/utils/html";
import {YoutubeService} from "./youtube";

export type VideoInformation = {
    duration?: string;
    title?: string;
    description?: string;
    uploadDate?: string;
};

export class EopSocialYoutubeSeo extends HTMLElement {

    private youtubeService: YoutubeService;
    private loadAfterConfirmationEvents: LoadAfterConfirmationEvents;

    public constructor() {
        super();
        this.youtubeService = resolve(YoutubeService);
        this.loadAfterConfirmationEvents = resolve(LoadAfterConfirmationEvents);
    }

    public connectedCallback(): void {
        const videoId = this.getAttribute("video-id");
        if (!videoId) {
            return;
        }

        this.loadAfterConfirmationEvents.onConfirmation("youtube", async () => {
            try {
                const info = await this.youtubeService.getVideoInfo(videoId);
                return this.addVideoMarkup(info);
            } catch (error) {
                return EOP_ERRORS(error);
            }
        });
    }

    private addVideoMarkup(videoInfo: VideoInformation): void {
        this.setAttribute("itemprop", "video");
        this.setAttribute("itemtype", "http://schema.org/VideoObject");
        this.setAttribute("itemscope", "");

        const videoContainer = this.querySelector(".youtube-player");
        if (videoContainer === null) {
            return;
        }

        if (videoInfo.description) {
            videoContainer.prepend(elementFrom(`<span itemprop="description">${videoInfo.description}</span>`));
        }
        if (videoInfo.uploadDate) {
            videoContainer.prepend(this.metaItemProp("uploadDate", videoInfo.uploadDate));
        }
        if (videoInfo.duration) {
            videoContainer.prepend(this.metaItemProp("duration", videoInfo.duration));
        }
        if (videoInfo.title) {
            videoContainer.prepend(this.metaItemProp("name", videoInfo.title));
        }
    }

    private metaItemProp(itemProp: string, value: string): HTMLElement {
        const htmlElement = elementFrom<HTMLElement>(`<meta itemprop="${itemProp}">`);
        htmlElement.setAttribute("content", value);
        return htmlElement;
    }
}