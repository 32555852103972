import {resolve} from "../../../container";
import {GLOBAL} from "../../../common/globals";
import {Nav} from "../../../common/nav";
import {noopAsync} from "../../../common/utils/functions";
import {QueryParameters} from "../../../common/queryParameters";
import {ClientPostings} from "./pageOverlayMessages";

export enum PageOverlayHistoryType {
    ROOT = "root",
    PAGE = "page",
    CLIENT = "client"
}

export type PageOverlayHistoryState = {
    entryType: PageOverlayHistoryType;
}

export type PageOverlayClientHistoryState = PageOverlayHistoryState & {
    hostHref: string;
    numClientHistoryEntriesSinceStart: number;
}

export type PageOverlayHostHistoryState = PageOverlayHistoryState & {
    href: string;
    title: string | null;
    rootBaseHref: string;
    numHistoryEntriesFromRoot: number;
}

export type PageOverlayRootHistoryState = PageOverlayHostHistoryState & {}
export type PageOverlayPageHistoryState = PageOverlayRootHistoryState & {
    numHostHistoryEntriesAtStart: number;
}

export class PageOverlayHostHistory {
    private readonly history: History;
    private readonly rootBaseHref: string;
    private returnToRootCallback: () => Promise<void>;
    private returnToPageCallback: (pageHref: string) => Promise<void>;

    public constructor(private nav: Nav = resolve(Nav)) {
        this.history = nav.history();
        this.rootBaseHref = cleanedUrl(nav.href());
        this.returnToRootCallback = noopAsync;
        this.returnToPageCallback = noopAsync;
    }

    public setReturnToRootCallback(callback: () => Promise<void>): void {
        this.returnToRootCallback = callback;
    }

    public setReturnToPageCallback(callback: (pageHref: string) => Promise<void>): void {
        this.returnToPageCallback = callback;
    }

    public getRootBaseHref(): string {
        return this.rootBaseHref;
    }

    public init(): void {
        GLOBAL.window().addEventListener("popstate", event => {
            this.processState(event.state);
        });
    }

    private processState(pageState: unknown): void {
        if (pageState !== null) {
            if (this.isValidHostState(pageState)
                && this.hasCurrentRoot(pageState)
                && this.hasConsistentRootHref(pageState)) {
                this.performPageStateActions(pageState);
            } else {
                this.nav.reload();
            }
        }
    }

    private getCurrentState(): PageOverlayHostHistoryState {
        return this.history.state;
    }

    private initFreshRootState(): PageOverlayRootHistoryState {
        const initialPageState: PageOverlayRootHistoryState = {
            entryType: PageOverlayHistoryType.ROOT,
            href: this.nav.href(),
            title: GLOBAL.document().title,
            rootBaseHref: this.rootBaseHref,
            numHistoryEntriesFromRoot: 0
        };
        this.replaceState(initialPageState, initialPageState.href);
        return initialPageState;
    }

    private hasConsistentRootHref(pageState: PageOverlayHostHistoryState): boolean {
        return pageState.entryType !== PageOverlayHistoryType.ROOT || this.hasHrefMatchingRootPage(pageState.href);
    }

    private hasHrefMatchingRootPage(href: string): boolean {
        return this.rootBaseHref === cleanedUrl(href);
    }

    private isValidHostState(historyState: any): historyState is PageOverlayHostHistoryState {
        const entryType = (historyState as PageOverlayHistoryState).entryType;
        return entryType === PageOverlayHistoryType.ROOT
            || entryType === PageOverlayHistoryType.PAGE;
    }

    private hasCurrentRoot(pageState: PageOverlayHostHistoryState): boolean {
        return pageState.rootBaseHref === this.rootBaseHref;
    }

    private performPageStateActions(pageState: PageOverlayHostHistoryState): void {
        this.applyTitle(pageState);
        if (pageState.entryType === PageOverlayHistoryType.ROOT) {
            this.returnToRootCallback();
        } else if (pageState.entryType === PageOverlayHistoryType.PAGE) {
            this.returnToPageCallback(pageState.href);
        }
    }

    private applyTitle(pageState: PageOverlayHostHistoryState): void {
        if (pageState.title !== null) {
            GLOBAL.document().title = pageState.title;
        }
    }

    public pushPreliminaryEntry(pageHref: string): void {
        const currentState = this.currentOrInitialState();

        const numHistoryEntriesFromRoot = currentState.numHistoryEntriesFromRoot + 1;
        const newState: PageOverlayPageHistoryState = {
            entryType: PageOverlayHistoryType.PAGE,
            href: pageHref,
            title: null,
            rootBaseHref: currentState.rootBaseHref,
            numHistoryEntriesFromRoot: numHistoryEntriesFromRoot,
            numHostHistoryEntriesAtStart: numHistoryEntriesFromRoot
        };

        this.pushState(newState, this.crossOriginSafeHref(newState.href));
    }

    private crossOriginSafeHref(href: string): string {
        return this.nav.hasSameOrigin(href) ? href : this.createFakeCrossOriginHref(href);
    }

    public updateEntry(pageHref: string, title: string, numClientHistoryEntriesSinceStart: number): void {
        const currentState = this.getCurrentState() as PageOverlayPageHistoryState;

        const updatedState: PageOverlayPageHistoryState = {
            ...currentState,
            href: pageHref,
            title: title,
            numHistoryEntriesFromRoot: currentState.numHostHistoryEntriesAtStart + numClientHistoryEntriesSinceStart - 1
        };
        this.replaceState(updatedState, this.crossOriginSafeHref(updatedState.href));
        this.applyTitle(updatedState);
    }

    public returnToRoot(): void {
        const currentState = this.currentOrInitialState();
        if (currentState.numHistoryEntriesFromRoot > 0) {
            this.history.go(-currentState.numHistoryEntriesFromRoot);
        }
    }

    private currentOrInitialState(): PageOverlayHostHistoryState {
        return this.getCurrentState() ?? this.initFreshRootState();
    }

    private createFakeCrossOriginHref(crossOriginHref: string): string {
        return urlWithHash(this.rootBaseHref, "cross-origin-page-overlay:" + crossOriginHref);
    }

    private replaceState(pageState: PageOverlayHostHistoryState, pageHref: string): void {
        this.history.replaceState(pageState, "", pageHref);
    }

    private pushState(pageState: PageOverlayHostHistoryState, pageHref: string): void {
        this.history.pushState(pageState, "", pageHref);
    }
}

function urlWithHash(url: string, hash: string): string {
    const newUrl = new URL(url);
    newUrl.hash = hash;
    return newUrl.href;
}

function cleanedUrl(url: string): string {
    const newUrl = new URL(url);
    newUrl.search = "";
    newUrl.hash = "";
    return newUrl.href;
}

export function getClientHistoryState(): PageOverlayClientHistoryState | null {
    const clientHistoryState = GLOBAL.window().history.state as PageOverlayClientHistoryState | null;
    return clientHistoryState?.entryType === PageOverlayHistoryType.CLIENT ? clientHistoryState : null;
}

export class PageOverlayClientHistory {
    private history: History;
    private sendClient: ClientPostings;
    private numClientEntriesFromStart: number;

    public constructor(
        private hostHref: string,
        private nav: Nav = resolve(Nav),
        private queryParameters: QueryParameters = resolve(QueryParameters)
    ) {
        this.history = nav.history();
        this.sendClient = ClientPostings.forHref(this.hostHref);
        this.numClientEntriesFromStart = 0;
    }

    public init(): void {
        const initState = this.updateState();
        this.sendClient.loadedOrChangedMessage({
            href: this.nav.href(),
            title: GLOBAL.document().title,
            numClientHistoryEntriesSinceStart: initState.numClientHistoryEntriesSinceStart
        });

        GLOBAL.window().addEventListener("popstate", event => this.notifyLocalHrefChanged());
        this.queryParameters.onChange(() => this.notifyLocalHrefChanged());
    }

    private notifyLocalHrefChanged(): void {
        const myState = this.updateState();
        this.sendClient.loadedOrChangedMessage({
            href: this.nav.href(),
            title: GLOBAL.document().title,
            numClientHistoryEntriesSinceStart: myState.numClientHistoryEntriesSinceStart
        });
    }

    private updateState(): PageOverlayClientHistoryState {
        const currentState = this.history.state as PageOverlayClientHistoryState | null;
        if (currentState !== null && currentState.entryType === PageOverlayHistoryType.CLIENT) {
            this.numClientEntriesFromStart = currentState.numClientHistoryEntriesSinceStart;
            return this.history.state;
        }

        const newState: PageOverlayClientHistoryState = {
            entryType: PageOverlayHistoryType.CLIENT,
            hostHref: this.hostHref,
            numClientHistoryEntriesSinceStart: this.numClientEntriesFromStart + 1
        };
        this.replaceState(newState);
        this.numClientEntriesFromStart = newState.numClientHistoryEntriesSinceStart;
        return newState;
    }

    private replaceState(data: PageOverlayClientHistoryState): void {
        this.history.replaceState(data, "", null);
    }
}
