import {autoRegister} from "../../container";
import type {Lifetime} from "../../common/lifetime";
import {GLOBAL} from "../../common/globals";
import {elementFrom} from "../../common/utils/html";

@autoRegister()
export class PageFeatures {

    public insert<T extends Element>(element: T, lifetime?: Lifetime, subFeature?: string): T {
        let pageFeatures = GLOBAL.bodyElement().querySelector("#pagefeatures");
        if (!pageFeatures) {
            pageFeatures = GLOBAL.bodyElement().appendChild(elementFrom("<div id='pagefeatures'></div>"));
        }

        let insertionPoint: Element | null = pageFeatures;
        if (subFeature) {
            insertionPoint = pageFeatures.querySelector(`#${subFeature}`);
            if (!insertionPoint) {
                insertionPoint = pageFeatures.appendChild(elementFrom(`<div id='${subFeature}'></div>`));
            }
        }

        const childElement = insertionPoint.appendChild(element);
        lifetime?.onDrop(() => childElement.remove());
        return childElement;
    }

    public insertFloatingSideElement<T extends Element>(element: T, lifetime?: Lifetime): T {
        return this.insert(element, lifetime, "floating-side-elements");
    }
}