import {extendPrototype} from "../../common/utils/extend";

extendPrototype(Number, {
    roundToDecimalPosition: function (this: number, decimalPosition: number): number {
        if (decimalPosition >= 0) {
            return Math.round(this / (10 ** decimalPosition)) * (10 ** decimalPosition);
        } else {
            return Math.round(this * (10 ** -decimalPosition)) / (10 ** -decimalPosition);
        }
    },

    lastDecimalPosition: function (this: number): number {
        const numberAsString = String(this);

        if (numberAsString.includes("e")) {
            const [significand, exponent] = numberAsString.split("e");
            return parseFloat(significand).lastDecimalPosition() + parseInt(exponent);
        }

        if (numberAsString.includes(".")) {
            return -numberAsString.split(".")[1].length;
        } else {
            return numberAsString.length - 1 - (lastNonZeroDecimalPositionOfIntegerString(numberAsString) ?? 0);
        }
    }
});

function lastNonZeroDecimalPositionOfIntegerString(integerAsString: string): number | undefined {
    for (let i = integerAsString.length - 1; i >= 0; i--) {
        if (integerAsString.charAt(i) !== "0") {
            return i;
        }
    }

    return undefined;
}

export function isNumber(value: any): value is number {
    return typeof value === "number";
}

export function clamp(value: number, min: number, max: number): number {
    return value > max
        ? max
        : value < min
            ? min
            : value;
}

export function intFrom(string: string): number {
    const parsedNumber = parseInt(string);
    if (isNaN(parsedNumber)) {
        throw new Error(`Cannot parse integer from "${string}"`);
    }
    return parsedNumber;
}

export function floatFrom(string: string): number {
    const parsedNumber = parseFloat(string);
    if (isNaN(parsedNumber)) {
        throw new Error(`Cannot parse float from "${string}"`);
    }
    return parsedNumber;
}

declare global {
    interface Number {
        roundToDecimalPosition: (decimalPosition: number) => number;

        lastDecimalPosition: () => number;
    }
}