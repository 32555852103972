import {Nav} from "../../../common/nav";
import {resolve} from "../../../container";
import {customElement, property} from "lit/decorators.js";
import {UnLitElement} from "../../../common/elements";
import {ClickTracking} from "../../../tracking/clickTracking";

@customElement("eop-background-link")
export class EopBackgroundLink extends UnLitElement {
    @property({attribute: "href"})
    public href: string;
    @property({attribute: "target"})
    public target: string;

    public constructor(
        private nav: Nav = resolve(Nav),
        private clickTracking: ClickTracking = resolve(ClickTracking)
    ) {
        super();
    }

    public connectedCallback(): void {
        super.connectedCallback();

        if (!this.href || this.href.length === 0) {
            return;
        }

        this.style.cursor = "pointer";
        this.setAttribute("tabindex", "0");

        this.addEventListener("click", (event) => {
            let target: HTMLElement | null = event.target as HTMLElement;
            while (target !== this) {
                if (!target || target.isClickable()) {
                    return;
                }
                target = target.parentElement;
            }

            this.clickTracking.handleTextlinkInteract(event, this, this.href);
            if (this.target === "_blank") {
                this.nav.redirectNewWindow(this.href);
            } else {
                this.nav.redirect(this.href);
            }
        });
    }
}