/*
https://easings.net/#
*/

export const LINEAR = (x: number): number => x;
export const EASE_IN_QUAD = (x: number): number => x * x;
export const EASE_OUT_QUAD = (x: number): number => x * (2 - x);
export const EASE_IN_OUT_QUAD = (x: number): number => x < 0.5 ? 2 * x * x : -1 + (4 - 2 * x) * x;
export const EASE_IN_CUBIC = (x: number): number => x * x * x;
export const EASE_IN_OUT_CUBIC = (x: number): number => x < 0.5 ? 4 * x * x * x : (x - 1) * (2 * x - 2) * (2 * x - 2) + 1;
export const EASE_IN_QUART = (x: number): number => x * x * x * x;
export const EASE_IN_QUINT = (x: number): number => x * x * x * x * x;