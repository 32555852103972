import {Nav} from "../../../common/nav";
import {resolve} from "../../../container";

export class EopNestedLink extends HTMLElement {

    public constructor(private nav: Nav = resolve(Nav)) {
        super();
    }

    public connectedCallback(): void {
        const href = this.getAttribute("href");
        if (!href) {
            return;
        }
        this.style.cursor = "pointer";

        this.addEventListener("click", event => {
            event.stopPropagation();
            event.preventDefault();
            if (this.getAttribute("target") === "_blank") {
                this.nav.redirectNewWindow(href);
            } else {
                this.nav.redirect(href);
            }
        });
    }
}

customElements.define("eop-nested-link", EopNestedLink);