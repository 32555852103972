import {UNI_CAROUSEL_ACTIVE_ELEMENT_CHANGE} from "./uniCarousel";

export class EopImageGallery extends HTMLElement {

    public connectedCallback(): void {
        const captionPlaceholderElement = this.querySelector<HTMLElement>(".image-gallery-caption-placeholder")!;
        if (this.captions().length === 0) {
            captionPlaceholderElement.remove();
            return;
        }

        this.captions().forEach((caption, index) => {
            if (index === 0) {
                caption.classList.add("active");
            }
            captionPlaceholderElement.appendChild(caption);
        });

        this.addEventListener(UNI_CAROUSEL_ACTIVE_ELEMENT_CHANGE, (event: Event) => {
            const activeIndex = (event as any).detail;
            this.updateCaption(activeIndex);
        });
    }

    public updateCaption(index: number): void {
        const allCaptions = this.captions();
        const nextCaption = allCaptions[index];

        allCaptions.forEach(caption => caption.classList.remove("active"));
        nextCaption.classList.add("active");
    }

    private captions(): NodeListOf<HTMLElement> {
        return this.querySelectorAll(".image-gallery-caption");
    }
}

customElements.define("eop-image-gallery", EopImageGallery);