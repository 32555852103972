import {mergeShallow} from "./objects";

export type NamedFunction<F extends Function> = F & { id: string };

export function createNamedFunction<F extends Function>(id: string, unnamedFunction: F): NamedFunction<F> {
    return mergeShallow(copyFunction(unnamedFunction), {id: id});
}

export function copyFunction<F extends Function>(functionToCopy: F): F {
    return functionToCopy.bind({});
}

export function isFunction(value: any): value is Function {
    return typeof value === "function";
}

export const noop = (...args: any): void => {
};

export const noopAsync = async (...args: any): Promise<void> => {
};