import {LitElement, type PropertyValues, type TemplateResult} from "lit";
import {GLOBAL} from "./globals";
import type {Constructor} from "./lifetime";

export class UnLitElement extends LitElement {
    protected createRenderRoot(): HTMLElement | DocumentFragment {
        const styles = (this.constructor as any).styles;
        if (styles) {
            const style = GLOBAL.document().createElement("style");
            style.innerText = styles.cssText;
            this.appendChild(style);
        }
        return this;
    }
}

export abstract class ConditionalLitElement extends LitElement {
    protected abstract shouldRender(): boolean;

    protected abstract renderTemplate(): TemplateResult;

    public render(): TemplateResult | null {
        if (!this.shouldRender()) {
            this.remove();
            return null;
        }
        return this.renderTemplate();
    }
}

export interface Initializable {
    initialized: () => void;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function Initializing<T extends Constructor<LitElement>>(Base: T): T & Constructor<Initializable> {
    return class extends Base implements Initializable {

        public initialized(): void {
            this.classList.remove("initializing");
        }
    };
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function AutoInitializing<T extends Constructor<LitElement>>(Base: T): T & Constructor<Initializable> {
    return class extends Base implements Initializable {

        protected firstUpdated(_changedProperties: PropertyValues): void {
            super.firstUpdated(_changedProperties);
            this.initialized();
        }

        public initialized(): void {
            this.classList.remove("initializing");
        }
    };
}