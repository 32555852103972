import type {DatePeriod} from "./datePeriod";
import {dateExpressionFrom} from "./parseDate";

export class LocalDate {

    private date: {
        year: number;
        month: number;
        day: number;
    };

    private constructor(year: number, month: number, day: number) {
        if (isValidYMD(year, month, day)) {
            this.date = {year: year, month: month, day: day};
        } else {
            this.date = {year: NaN, month: NaN, day: NaN};
        }
    }

    public static invalid(): LocalDate {
        return new LocalDate(NaN, NaN, NaN);
    }

    public static today(): LocalDate {
        return LocalDate.fromDate(new Date());
    }

    public static fromDate(date: Date): LocalDate {
        return new LocalDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
    }

    public static fromYMD(year: number, month: number, day: number): LocalDate {
        return new LocalDate(year, month, day);
    }

    public static fromDateExpression(input: string): LocalDate {
        return dateExpressionFrom(input).toLocalDate();
    }

    public clone(): LocalDate {
        return new LocalDate(this.date.year, this.date.month, this.date.day);
    }

    public toDate(): Date | undefined {
        if (!this.isValid()) {
            return undefined;
        }
        return this.makeDate();
    }

    private makeDate(): Date {
        return new Date(Date.UTC(this.date.year, this.date.month - 1, this.date.day));
    }

    public getD(): number {
        return this.date.day;
    }

    public getM(): number {
        return this.date.month;
    }

    public getY(): number {
        return this.date.year;
    }

    public isValid(): boolean {
        return !isNaN(this.date.year + this.date.month + this.date.day);
    }

    public calculateAge(): number {
        if (!this.isValid()) {
            return NaN;
        }

        const today = LocalDate.today();
        const yearDifference = today.getY() - this.getY();
        const monthDifference = today.getM() - this.getM();
        const dayDifference = today.getD() - this.getD();

        if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
            return yearDifference - 1;
        }
        return yearDifference;
    }

    public isAfterOrEqual(otherDate: LocalDate): boolean {
        return this.makeDate() >= otherDate.makeDate();
    }

    public isAfter(otherDate: LocalDate): boolean {
        return this.makeDate() > otherDate.makeDate();
    }

    public isBefore(otherDate: LocalDate): boolean {
        return this.makeDate() < otherDate.makeDate();
    }

    public isBeforeOrEqual(otherDate: LocalDate): boolean {
        return this.makeDate() <= otherDate.makeDate();
    }

    public isInWeekdays(weekdays: number[]): boolean {
        const dayOfDateAsNumber = this.makeDate().getDay();
        return weekdays.includes(dayOfDateAsNumber);
    }

    // eslint-disable-next-line complexity
    public add(range: DatePeriod): LocalDate {
        const value = range.getValue();
        const unit = range.getUnit();
        const newDate = this.makeDate();
        switch (unit) {
            case "day":
            case "days":
            case "d":
                newDate.setDate(newDate.getDate() + value);
                break;
            case "week":
            case "weeks":
            case "w":
                newDate.setDate(newDate.getDate() + value * 7);
                break;
            case "month":
            case "months":
            case "m":
                newDate.setMonth(newDate.getMonth() + value);
                break;
            case "year":
            case "years":
            case "y":
                newDate.setFullYear(newDate.getFullYear() + value);
                break;
            default:
        }

        return LocalDate.fromDate(newDate);
    }

    public toHtml5DateString(): string {
        return [
            this.getY().toString().padStart(4, "0"),
            this.getM().toString().padStart(2, "0"),
            this.getD().toString().padStart(2, "0")
        ].join("-");
    }
}

function isValidYMD(year: number, month: number, day: number): boolean {
    const jsDate = new Date(year, month - 1, day);
    return jsDate.getFullYear() === year
        && jsDate.getMonth() === month - 1
        && jsDate.getDate() === day;
}