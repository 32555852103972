import {html, LitElement, type TemplateResult} from "lit";
import {customElement, property} from "lit/decorators.js";
import Styles from "./imageTeaser.lit.scss";
import FeedTileStyles from "../../../common/feedTile.lit.scss";
import {unsafeHTML} from "lit/directives/unsafe-html.js";
import {headline} from "../../../common/snippets/headline";

const imageTeaserShade: Record<string, string> = {
    NONE: "",
    LIGHT: "image-shade-light",
    MEDIUM: "image-shade-medium",
    STRONG: "image-shade-strong"
} as const;

const imageTeaserBackground: Record<string, string> = {
    DARK: "dark-image",
    BRIGHT: "bright-image"
} as const;

const imageTeaserSize: Record<string, string> = {
    SMALL: "image-teaser-small",
    MEDIUM: "image-teaser-medium",
    LARGE: "image-teaser-large"
} as const;

@customElement("eop-image-teaser")
export class ImageTeaser extends LitElement {

    public static readonly styles = [Styles, FeedTileStyles];

    @property({attribute: "link"})
    private link: string;
    @property({attribute: "text"})
    private text: string;
    @property({attribute: "headline"})
    private headline: string;
    @property({attribute: "hover-text"})
    private hoverText: string;
    @property({attribute: "headline-level", type: Number})
    private headlineLevel: 1 | 2 | 3 | 4 | 5 | 6 = 4;
    @property({attribute: "target"})
    private target: string = "_self";
    @property({attribute: "open-in-page-overlay", type: Boolean})
    private openInPageOverlay: boolean;
    @property({attribute: "image-background"})
    private imageBackground: string;
    @property({attribute: "image-src"})
    private imageSrc: string;
    @property({attribute: "video-src"})
    private videoSrc: string;
    @property({attribute: "image-shade"})
    private imageShade: string;
    @property({attribute: "image-size"})
    private imageSize: string;
    @property({attribute: "tags"})
    private tags: HTMLElement;

    public constructor() {
        super();
    }

    public connectedCallback(): void {
        super.connectedCallback();
        this.classList.add(this.getImageTeaserSize(this.imageSize), this.getImageTeaserBackground(this.imageBackground));
    }

    public render(): TemplateResult {
        if (this.openInPageOverlay) {
            return html`
                <eop-page-overlay-link href=${this.link} title=${this.hoverText} data-tracking-label=${this.headline} tabindex="0"
                                       class="image-teaser ${this.getImageTeaserBackground(this.imageBackground)} ${this.getImageTeaserSize(this.imageSize)}">
                    ${(this.renderImage())}
                    ${(this.renderContent())}
                </eop-page-overlay-link>
            `;
        }
        return html`
            <a href=${this.link} target=${this.target} title=${this.hoverText} data-tracking-label=${this.headline}
               class="image-teaser ${this.getImageTeaserBackground(this.imageBackground)} ${this.getImageTeaserSize(this.imageSize)}">
                ${(this.renderImage())}
                ${(this.renderContent())}
            </a>
        `;
    }

    private renderContent(): TemplateResult {
        return html`
            <div class="image-teaser-content">
                ${headline(`h${this.headlineLevel}`, this.headline, ["image-teaser-headline"])}
                <div class="image-teaser-text" data-role="content-sub-headline">${unsafeHTML(this.text)}</div>
                ${this.tags}
            </div>
        `;
    }

    private renderImage(): TemplateResult {
        return html`
            <div class="image-teaser-image ${this.getImageTeaserShade(this.imageShade)}">
                <eop-responsive-image .imageSrc=${this.imageSrc} .imageAlt=${this.hoverText}></eop-responsive-image>
                ${this.renderVideo()}
            </div>
        `;
    }

    private renderVideo(): TemplateResult | null {
        if (!this.videoSrc) {
            return null;
        }
        return html`
            <div class="content-video">
                <video class="video-loop" autoplay playsinline muted loop src=${this.videoSrc} preload="metadata"></video>
            </div>
        `;
    }

    private getImageTeaserSize(key: string): string {
        return imageTeaserSize[key] ?? key;
    }

    private getImageTeaserShade(key: string): string {
        return imageTeaserShade[key] ?? key;
    }

    private getImageTeaserBackground(key: string): string {
        return imageTeaserBackground[key] ?? key;
    }
}