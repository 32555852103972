import {Nav} from "./nav";
import {GLOBAL} from "./globals";
import {ScrollService} from "./scroll";
import {autoRegister, resolve} from "../container";
import {QueryParameters} from "./queryParameters";
import {UrlParts} from "./utils/url";
import {ManagingResources} from "./lifetime";
import {prepareEvents} from "./utils/events";

@autoRegister()
export class SmoothScrolling {
    public constructor(
        private nav: Nav = resolve(Nav),
        private queryParameters: QueryParameters = resolve(QueryParameters),
        private scrollService: ScrollService = resolve(ScrollService)
    ) {
    }

    public handleLinkClick(event: Event): void {
        const targetLink = Array.from(event.composedPath())
            .filter(elm => elm instanceof Element)
            .findFirst(elm => elm.matches("a[href*='#']"));
        if (targetLink) {
            this.scroll(event, targetLink);
        }
    }

    public async scrollToAnchor(anchor: string): Promise<void> {
        const urlParts = UrlParts.from(anchor);
        this.queryParameters.applyQueryString(urlParts.query);
        await this.scrollService.scrollToSection(urlParts.anchor)
            .then(() => this.nav.setAnchor(urlParts.anchor));
    }

    private scroll(event: Event, link: Element): void {
        const href = link.getAttribute("href")!;

        if (!this.nav.isJump(href)) {
            return;
        }

        event.stopPropagation();
        event.preventDefault();

        void this.scrollToAnchor(href);
    }
}

export class EopSmoothScrolling extends ManagingResources(HTMLElement) {
    public constructor(private smoothScrolling: SmoothScrolling = resolve(SmoothScrolling)) {
        super();
    }

    public connectedCallback(): void {
        prepareEvents(GLOBAL.bodyElement())
            .boundTo(this)
            .on("click", ev => this.smoothScrolling.handleLinkClick(ev));
    }
}

customElements.define("eop-smooth-scrolling", EopSmoothScrolling);