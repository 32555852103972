import {autoRegister} from "../container";
import {Deferred} from "./utils/promises";
import {GLOBAL} from "./globals";

@autoRegister()
export class Timeout {

    private queue: Task[];
    private window: Window;

    public constructor() {
        this.queue = [];
        this.window = GLOBAL.window();
    }

    public delay<T>(callback: () => T, delay: number = 0): Promise<T> {
        const deferred = new Deferred<T>();

        const id = this.window.setTimeout(() => {
            deferred.resolve(callback());
            this.queue.removeAll(task);
        }, delay);
        const task = new Task(id, callback, deferred);
        this.queue.push(task);

        return deferred.promise;
    }

    public cancel(promise: Promise<any>): void {
        const runningTask = this.queue.findFirst(task => task.promise === promise);
        if (!runningTask) {
            return;
        }
        this.window.clearTimeout(runningTask.id);
        runningTask.cancel();
        this.queue.removeAll(runningTask);
    }
}

export class Task {

    public promise: Promise<any>;

    public constructor(
        public id: number,
        public callback: () => any,
        private deferred: Deferred<any>
    ) {
        this.promise = deferred.promise;
    }

    public cancel(): void {
        this.deferred.rejectExpected();
    }
}