import {LocatedDates} from "../../common/utils/dates/locatedDates";
import {resolve} from "../../container";
import {UnLitElement} from "../../common/elements";
import {customElement, property} from "lit/decorators.js";
import {html, type TemplateResult} from "lit";

@customElement("eop-date")
export class EopDate extends UnLitElement {

    @property({type: Number})
    public year: number;
    @property({type: Number})
    public month: number;
    @property({type: Number})
    public day: number;
    @property({attribute: "date-time", type: Number})
    public dateTime: number;

    public constructor(private locatedDates: LocatedDates = resolve(LocatedDates)) {
        super();
    }

    private formattedDate(): string {
        if (this.dateTime) {
            return this.locatedDates.toLocalDateString(new Date(this.dateTime));
        }

        if (this.year && this.month && this.day) {
            return this.locatedDates.toLocalDateString(new Date(this.year, this.month - 1, this.day));
        }

        if (this.year && this.month) {
            const date = new Date(this.year, this.month - 1);
            return this.locatedDates.toLocalPartialDateString(date);
        }

        return this.year.toString();
    }

    protected render(): TemplateResult {
        return html`${this.formattedDate()}`;
    }
}