import {html, LitElement, type TemplateResult} from "lit";
import {customElement, property} from "lit/decorators.js";
import Styles from "./colorSchemeToggle.lit.scss";
import {ManagingResources} from "../../../../common/lifetime";
import {resolve} from "../../../../container";
import {EventBus, EventBusEvent} from "../../../../common/eventBus";
import {Dictionary} from "../../../elements/dictionary";
import {COLOR_SCHEME_CHANGED_EVENT, ColorScheme, type ColorSchemeData, ColorSchemeService} from "../../../../common/colorSchemeService";

@customElement("eop-color-scheme-toggle")
export class EopColorSchemeToggle extends ManagingResources(LitElement) {

    public static readonly styles = Styles;

    @property({attribute: "color-scheme", reflect: true})
    public colorScheme = ColorScheme.LIGHT;
    @property({attribute: "inside-sheet", type: Boolean})
    public insideSheet: boolean;

    private dictionary: Dictionary;

    public constructor(
        private eventBus: EventBus = resolve(EventBus),
        private colorSchemeService: ColorSchemeService = resolve(ColorSchemeService)
    ) {
        super();
    }

    public connectedCallback(): void {
        super.connectedCallback();

        this.addEventListener("keydown", this.handleKeydown.bind(this));
        this.dictionary = Dictionary.of(this);

        this.eventBus.on(COLOR_SCHEME_CHANGED_EVENT, (event: EventBusEvent<ColorSchemeData>) => {
            this.colorScheme = event.data.colorScheme;
        });
        this.colorScheme = this.colorSchemeService.getColorScheme();
    }

    public render(): TemplateResult {
        const trackingLabel = this.colorScheme === ColorScheme.LIGHT ? "light->dark" : "dark->light";
        return html`
            <div class="color-scheme-toggle" @click=${this.toggleColorScheme} tabindex="0" data-eventelement="dark-mode-toggle"
                 data-tracking-label=${trackingLabel}>
                <div class="color-scheme-label">${this.renderLabel()}</div>
            </div>
        `;
    }

    private renderLabel(): TemplateResult {
        if (this.colorScheme === ColorScheme.LIGHT) {
            return html`${this.dictionary.translate("MSG_LIGHT_SCHEME_TOGGLE_LABEL")}`;
        } else {
            return html`${this.dictionary.translate("MSG_DARK_SCHEME_TOGGLE_LABEL")}`;
        }
    }

    private handleKeydown(e: KeyboardEvent): void {
        if (e.code === "Space" || e.code === "Enter") {
            this.toggleColorScheme();
        }
    }

    private toggleColorScheme(): void {
        this.colorSchemeService.toggleColorScheme();
    }
}