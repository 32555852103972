import {HorizontalOverflow, HorizontalOverflowInstaller} from "./horizontalOverflow";
import {resolve} from "../../container";

export class EopSwipeable extends HTMLElement {
    private horizontalOverflow: HorizontalOverflow;

    public constructor(private horizontalOverflowInstaller: HorizontalOverflowInstaller = resolve(HorizontalOverflowInstaller)) {
        super();
    }

    public connectedCallback(): void {
        this.horizontalOverflow = this.horizontalOverflowInstaller.installOn(this);
    }

    public disconnectedCallback(): void {
        this.horizontalOverflow.uninstall();
    }
}

customElements.define("eop-swipeable", EopSwipeable);