import {autoRegister} from "../container";
import {GLOBAL} from "./globals";

const CONFIG_PREFIX = "eop-config:";

@autoRegister()
export class Configuration {
    private properties: Map<string, string>;

    public constructor() {
        this.properties = this.parseEntries();
    }

    public get(key: string): string | undefined {
        return this.properties.get(key);
    }

    private parseEntries(): Map<string, string> {
        const properties = new Map();
        GLOBAL.headElement().querySelectorAll("meta").forEach(meta => {
            const property = meta.getAttribute("property");
            if (property?.startsWith(CONFIG_PREFIX)) {
                properties.set(property.slice(CONFIG_PREFIX.length), meta.getAttribute("content"));
            }
        });

        return properties;
    }
}