import {Cookies} from "../../common/cookies";
import {autoRegister, resolve} from "../../container";
import {GLOBAL} from "../../common/globals";
import {elementFrom} from "../../common/utils/html";
import {OneTrust, ONETRUST_CONSENT_STATUS_COOKIE_NAME, ONETRUST_LAYER_CLOSED_COOKIE_NAME} from "./oneTrust";

@autoRegister()
export class OneTrustCookieReset {
    public constructor(
        private cookies: Cookies = resolve(Cookies)
    ) {
    }

    public resetCookies(): void {
        this.cookies.remove(ONETRUST_LAYER_CLOSED_COOKIE_NAME);
        this.cookies.remove(ONETRUST_CONSENT_STATUS_COOKIE_NAME);
    }
}

@autoRegister()
export class OneTrustResetButton {

    private resetButton: HTMLElement;

    public constructor() {
        this.resetButton = elementFrom(`<button id="ot-sdk-btn" class="ot-sdk-show-settings"></button>`);
        GLOBAL.bodyElement().appendChild(this.resetButton);
    }

    public click(): void {
        if (!this.resetButton.isConnected) {
            GLOBAL.bodyElement().appendChild(this.resetButton);

        }
        this.resetButton.click();
        GLOBAL.bodyElement().removeChild(this.resetButton);
    }
}

export class EopTrackingReset extends HTMLElement {

    public constructor(
        private oneTrustCookieReset: OneTrustCookieReset = resolve(OneTrustCookieReset),
        private oneTrustResetButton: OneTrustResetButton = resolve(OneTrustResetButton),
        private oneTrust: OneTrust = resolve(OneTrust)
    ) {
        super();
    }

    public connectedCallback(): void {
        this.addEventListener("click", () => {
            const alreadyLoaded = this.oneTrust.loadOneTrustScript();
            if (alreadyLoaded) {
                this.oneTrustCookieReset.resetCookies();
                this.oneTrustResetButton.click();
            }
        });
    }
}

customElements.define("eop-tracking-reset", EopTrackingReset);