import {AndTrigger, OrTrigger, type TriggerFactory} from "./triggers";
import {TimerTriggerFactory} from "./timerTrigger";
import {ScrollTriggerFactory} from "./scrollTrigger";
import {autoRegister, resolve} from "../../container";
import {noop} from "../utils/functions";

@autoRegister()
export class ScrollAndTimerTriggerFactory implements TriggerFactory {
    public constructor(
        private scrollTriggerFactory: ScrollTriggerFactory = resolve(ScrollTriggerFactory),
        private timerTriggerFactory: TimerTriggerFactory = resolve(TimerTriggerFactory)) {
    }

    public createTrigger(element: Element, callback: () => void): AndTrigger {
        return new AndTrigger([
            this.timerTriggerFactory.createTrigger(element, noop),
            this.scrollTriggerFactory.createTrigger(element, noop)
        ], callback);
    }
}

@autoRegister()
export class ScrollOrTimerTriggerFactory implements TriggerFactory {
    public constructor(
        private scrollTriggerFactory: ScrollTriggerFactory = resolve(ScrollTriggerFactory),
        private timerTriggerFactory: TimerTriggerFactory = resolve(TimerTriggerFactory)) {
    }

    public createTrigger(element: Element, callback: () => void): OrTrigger {
        return new OrTrigger([
            this.timerTriggerFactory.createTrigger(element, noop),
            this.scrollTriggerFactory.createTrigger(element, noop)
        ], callback);
    }
}