import {autoRegister} from "../../container";


@autoRegister()
export class ConfirmedModules {
    private confirmedModules = new Set<string>();

    public hasBeenConfirmed(module: string): boolean {
        return this.confirmedModules.has(module);
    }

    public addConfirmedModule(moduleName: string): void {
        this.confirmedModules.add(moduleName);
    }
}
