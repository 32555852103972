import {customElement} from "lit/decorators.js";
import {Resolution} from "../../../common/resolution";
import {resolve} from "../../../container";
import {Page} from "../../../common/page";
import {ScrollService} from "../../../common/scroll";
import {EopExecutiveBoardGallery, executiveBoardGalleryConfig} from "./executiveBoardGallery";
import StylesFive from "./executiveBoardGalleryFive.lit.scss";
import StylesCommon from "./executiveBoardGalleryCommon.lit.scss";

@customElement("eop-executive-board-gallery-5")
export class EopExecutiveBoardGalleryFive extends EopExecutiveBoardGallery {

    public static readonly styles = [StylesCommon, StylesFive];

    public constructor(resolution: Resolution = resolve(Resolution), page: Page = resolve(Page), scrollService: ScrollService = resolve(ScrollService)) {
        // has duplicate in scss
        const portraitOnStageTimeline = [0.19, 0.38, 0.57, 0.76, 0.95];
        const config = executiveBoardGalleryConfig(portraitOnStageTimeline, {portraitCount: 5, hasGroupShot: true});
        super(config, resolution, page, scrollService);
    }
}
