import {isDefined} from "../../common/utils/basics";
import {
    type ElementParams,
    formatPercentage,
    Question,
    type QuestionCoordinates,
    type StatisticsPerOption,
    SurveyAbstractQuestionElement,
    type SurveyNavigation
} from "./abstractQuestion";
import {elementFrom} from "../../common/utils/html";
import {noop} from "../../common/utils/functions";
import {EopSurveyStatistics} from "./surveyStatistics";
import {html, LitElement, type TemplateResult} from "lit";
import {customElement} from "lit/decorators.js";
import ResultStyles from "./singleChoiceResults.lit.scss";
import RADIO_ICON from "../../../../resources/assets/images/radio_old.svg";

export class SingleChoiceQuestion extends Question {

    public constructor(coordinates: QuestionCoordinates) {
        super(coordinates);
    }

    public createInteractableElement(navigation: SurveyNavigation, params: ElementParams): EopSurveySingleChoiceQuestion {
        return new EopSurveySingleChoiceQuestion(
            () => this.createPromptContent(),
            (data: StatisticsPerOption) => this.createResultContent(data),
            navigation,
            this.coordinates,
            params);
    }

    public createStatisticsElement(data: StatisticsPerOption): EopSurveyStatistics {
        return new EopSurveyStatistics(this.createResultContent(data), this.coordinates);
    }

    private createPromptContent(): Element {
        const promptContent = elementFrom(`<div class="single-choice-question-prompt"></div>`);

        const radioGroup = new EopRadioGroup();
        Object.entries(this.coordinates.options)
            .forEach(entry => radioGroup.addRadioButton(entry[1] ?? "", entry[0]));
        promptContent.appendChild(radioGroup);

        return promptContent;
    }

    private createResultContent(data: StatisticsPerOption): Element {
        const resultElement = elementFrom(`<div class="single-choice-question-result"></div>`);
        Object.entries(data)
            .map(entries => entries[1])
            .filter(questionResult => isDefined(questionResult))
            .map(questionResult => new SurveySingleChoiceResultItemElement(questionResult.optionText, questionResult.percentage))
            .forEach(resultItemElement => resultElement.appendChild(resultItemElement));
        return resultElement;
    }
}

export class EopSurveySingleChoiceQuestion extends SurveyAbstractQuestionElement {

    private radioGroup: EopRadioGroup;

    public constructor(
        createPromptContent: () => Element,
        createResultContent: (data: StatisticsPerOption) => Element,
        navigation: SurveyNavigation,
        questionData: QuestionCoordinates,
        params: ElementParams
    ) {
        super(createPromptContent, createResultContent, navigation, questionData, params);
    }

    protected specificConnectedCallback(): void {
        this.radioGroup = this.promptElement.querySelector("eop-radio-group") as EopRadioGroup;
        this.radioGroup.onCheck(() => this.enableSubmitButton(true));
    }

    protected async submitAndGetStatistics(): Promise<StatisticsPerOption> {
        if (!this.radioGroup.value) {
            return {};
        }

        return this.navigation.answerAndGetStatistics(this.questionData.id, this.radioGroup.value);
    }

    protected hasSubmitButton(): boolean {
        return true;
    }
}

export class EopRadioButton extends HTMLElement {

    private inputElement: HTMLInputElement;

    public constructor(private label: string, public value: string) {
        super();
        this.setupHtml();
        this.inputElement = this.querySelector("input")!;
    }

    private setupHtml(): void {
        const icon = elementFrom(RADIO_ICON);
        icon.classList.add("checkable-icon");
        this.setAttribute("data-tracking-label", "single-choice");
        this.innerHTML = `
            <div class="checkable-form-element">
                <input type="radio" value="${this.value}"/>
                ${icon.outerHTML}
                <label>${this.label}</label>
            </div>
        `;
    }

    public check(): void {
        this.inputElement.checked = true;
    }

    public uncheck(): void {
        this.inputElement.checked = false;
    }
}

export class EopRadioGroup extends HTMLElement {

    public value: string | null;
    private buttons: EopRadioButton[];
    private checkListener: () => void;

    public constructor() {
        super();
        this.buttons = [];
        this.value = null;
        this.checkListener = noop;
    }

    public addRadioButton(label: string, value: string): void {
        const radioButton = new EopRadioButton(label, value);
        radioButton.addEventListener("click", () => this.select(radioButton));
        this.appendChild(radioButton);
        this.buttons.push(radioButton);
    }

    private select(button: EopRadioButton): void {
        this.buttons.forEach(b => b.uncheck());
        button.check();
        this.value = button.value;
        this.checkListener();
    }

    public onCheck(checkListener: () => void): void {
        this.checkListener = checkListener;
    }
}

@customElement("eop-survey-single-choice-result-item")
export class SurveySingleChoiceResultItemElement extends LitElement {

    public static readonly styles = ResultStyles;

    public constructor(private text: string, private percentage: number) {
        super();
    }

    public render(): TemplateResult {
        this.style.setProperty("--result-percentage", this.formatPercentageStyle(this.percentage));
        return html`
            <div class="single-choice-result-text">${this.text}</div>
            <div class="result-bar">
                <div class="result-bar-oblong"></div>
                <div class="single-choice-result-value">${formatPercentage(this.percentage)}</div>
            </div>
        `;
    }

    private formatPercentageStyle(value: number): string {
        return (value * 100) + "%";
    }
}

customElements.define("eop-survey-single-choice-question", EopSurveySingleChoiceQuestion);
customElements.define("eop-radio-button", EopRadioButton);
customElements.define("eop-radio-group", EopRadioGroup);
