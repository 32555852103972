import {forAllEntries, IntersectionObserverFactory, onceIntersected} from "../../common/observation";
import {resolve} from "../../container";

export class EopAlreadyInViewport extends HTMLElement {

    private intersectionObserver: IntersectionObserver;

    public constructor(private intersectionObserverFactory: IntersectionObserverFactory = resolve(IntersectionObserverFactory)) {
        super();
    }

    public connectedCallback(): void {
        const percentage = this.getAttribute("percentage")?.toFloat() ?? 0;

        this.intersectionObserver = this.intersectionObserverFactory.create(onceIntersected(() => this.toggleClass(true)), {threshold: percentage});
        this.intersectionObserver.observe(this);
    }

    public disconnectedCallback(): void {
        this.intersectionObserver.disconnect();
    }

    public toggleClass(intersecting: boolean): void {
        this.classList.toggle("already-in-viewport", intersecting);
    }
}

export class EopInViewport extends HTMLElement {

    private intersectionObserver: IntersectionObserver;

    public constructor(private intersectionObserverFactory: IntersectionObserverFactory = resolve(IntersectionObserverFactory)) {
        super();
    }

    public connectedCallback(): void {
        const percentage = this.getAttribute("percentage")?.toFloat() ?? 0;
        this.intersectionObserver = this.intersectionObserverFactory.create(forAllEntries(entry => this.toggleClass(entry.isIntersecting)), {threshold: percentage});
        this.intersectionObserver.observe(this);
    }

    public disconnectedCallback(): void {
        this.intersectionObserver.disconnect();
    }

    public toggleClass(intersecting: boolean): void {
        this.classList.toggle("in-viewport", intersecting);
    }
}

customElements.define("eop-already-in-viewport", EopAlreadyInViewport);
customElements.define("eop-in-viewport", EopInViewport);