import {GLOBAL} from "../../common/globals";
import {AlignedSlotsFactory} from "./alignedSlots";
import {HorizontalOverflowFactory} from "./horizontalOverflow";

export interface ReadyElementFeature {
    install: () => this;

    uninstall: () => void;
}

export interface ElementFeatureFactory<T extends ReadyElementFeature = ReadyElementFeature> {
    installOn: (element: Element) => T;
}

export function installElementFeature(id: string, featureFactory: ElementFeatureFactory): void {
    GLOBAL.bodyElement().querySelectorAll<Element>("[" + id + "]")
        .forEach(elem => {
            featureFactory.installOn(elem);
        });
}

export function afterContentLoaded(init: () => void): void {
    if (document.readyState === "loading") {
        GLOBAL.window().addEventListener("DOMContentLoaded", () => {
            init();
        });
    } else {
        init();
    }
}

afterContentLoaded(() => {
    installElementFeature("eop-aligned-slots", new AlignedSlotsFactory());
    installElementFeature("eop-horizontal-overflow", new HorizontalOverflowFactory());
});