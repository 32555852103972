import {html, LitElement, type TemplateResult} from "lit";
import {customElement, property} from "lit/decorators.js";
import Styles from "./macherbusProject.lit.scss";
import type {DirectiveResult} from "lit/directive.js";
import {templateContent} from "lit/directives/template-content.js";
import {unsafeSVG} from "lit/directives/unsafe-svg.js";
import {macherbusProjectVoteEvent} from "./macherbusEvents";
import THUMBS_UP_ICON from "../../../../svgpictograms/thumbs_up.svg";
import CHECK_ICON from "../../../../resources/assets/images/check.svg";


// GoodExampleFor: LitComponent with ShadowDOM and <template>
@customElement("eop-macherbus-project")
export class EopMacherbusProject extends LitElement {

    public static readonly styles = Styles;

    @property({attribute: "content"})
    public contentId: string;
    @property({attribute: "project-id"})
    private projectId: string;
    @property({attribute: "image-src"})
    private imageSrc: string;
    @property({attribute: "image-alt"})
    private imageAlt: string;
    @property({type: Boolean, reflect: true})
    private voted: boolean;

    private textualContent: DirectiveResult;

    public constructor() {
        super();
        this.voted = false;
    }

    public connectedCallback(): void {
        super.connectedCallback();

        const textualContentTemplate = this.resolveTemplateElement(this.contentId);
        if (textualContentTemplate !== null) {
            this.textualContent = templateContent(textualContentTemplate);
        }
    }

    public render(): TemplateResult {
        return html`
            <div class="project-tile">
                <div class="tile-image">
                    <eop-responsive-image image-src=${this.imageSrc} image-alt=${this.imageAlt}></eop-responsive-image>
                </div>
                <div class="tile-text">
                    ${this.textualContent}
                    <div class="button-container">
                        <button @click=${this.vote} ?disabled=${this.voted} class="select-button secondary" data-eventelement="button" type="button">
                            <span class="button-label"><eop-msg key="SELECT_PROJECT_LABEL"></eop-msg> ${this.renderThumbsUpIcon()}</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="check-icon">${this.renderCheckIcon()}</div>
        `;
    }

    public acceptsVote(votedProjectId: string): void {
        if (this.hasProjectId(votedProjectId)) {
            this.vote();
        }
    }

    public acceptsVoteOut(votedProjectId: string): void {
        if (this.hasNotProjectId(votedProjectId)) {
            this.unVote();
        }
    }

    public vote(): void {
        this.voted = true;
        this.dispatchEvent(macherbusProjectVoteEvent(this.projectId));
    }

    public unVote(): void {
        this.voted = false;
    }

    public hasProjectId(projectId: string): boolean {
        return this.projectId === projectId;
    }

    public hasNotProjectId(projectId: string): boolean {
        return !this.hasProjectId(projectId);
    }

    private renderCheckIcon(): TemplateResult {
        return html`${unsafeSVG(CHECK_ICON)}`;
    }

    private renderThumbsUpIcon(): TemplateResult {
        return html`${unsafeSVG(THUMBS_UP_ICON)}`;
    }
}