import {html, LitElement, type TemplateResult} from "lit";
import {customElement, property, state} from "lit/decorators.js";
import Styles from "./chapterNavigationItem.lit.scss";
import {ChapterData, chapterLinkEvent} from "./chapters";

@customElement("eop-chapter-navigation-item")
export class EopChapterNavigationItem extends LitElement {

    public static readonly styles = Styles;

    public readonly id: string;

    @property({type: Boolean, reflect: true})
    public active: boolean;
    @state()
    private item: ChapterData;

    public render(): TemplateResult {
        return html`
            <a class="link"
               href=${this.item.href}
               data-tracking-label=${this.item.name}
               @click=${this.handleClick}
            >
                <div class="chapter-name">${this.item.name}</div>
            </a>
        `;
    }

    private handleClick(event: Event): void {
        event.preventDefault();
        this.dispatchEvent(chapterLinkEvent(this.item));
    }
}