import {isString} from "../bootstrap/common/strings";

export class TrackingContext {

    public constructor(public element: Element) {
    }

    protected static elementIsApplicable(element: Element): boolean {
        return element.isVisible();
    }

    public static fromEvent(event: Event): TrackingContext | null {
        const eventPath: HTMLElement[] = Array.from(event.composedPath())
            .filter(element => element instanceof HTMLElement);

        const clickedElement = eventPath
            .find(elm => this.elementIsApplicable(elm));

        if (clickedElement) {
            return new TrackingContext(clickedElement);
        } else {
            return null;
        }
    }

    public static fromElement(element: Element): TrackingContext | null {
        if (this.elementIsApplicable(element)) {
            return new TrackingContext(element);
        }
        if (element.children.length === 1) {
            return this.fromElement(element.children.item(0)!);
        }
        const applicableChildren = Array.from(element.children)
            .filter(elm => this.elementIsApplicable(elm));
        if (applicableChildren.length === 1) {
            return new TrackingContext(applicableChildren[0]);
        }
        return null;
    }

    public static makeContentId(event: Event): string {
        const eventPath: Element[] = Array.from(event.composedPath()
            .filter(element => element instanceof Element));
        return eventPath
            .map(elm => elm.id ? elm.id : elm.getAttribute("data-eventelement"))
            .filter(id => !!id)
            .reverse()
            .join(".");
    }

    public getEventLabel(): string {
        let eventLabel: string | null = null;
        let current: TrackingContext = this;
        while (!eventLabel) {
            eventLabel = current.getEventNameByAttribute("title")
                ?? current.getEventNameByTextContent()
                ?? current.getEventNameByAttribute("data-tracking-label")
                ?? current.getEventNameByAttribute("alt")
                ?? current.getEventNameByImgSrc();

            const nextChild = this.getNextChildForEventLabel(current);
            if (nextChild) {
                current = new TrackingContext(nextChild);
                continue;
            }
            break;
        }

        return eventLabel ?? "";
    }

    private getNextChildForEventLabel(context: TrackingContext): Element | null {
        if (context.element.children.length === 1) {
            return context.element.children.item(0)!;
        }
        return null;
    }

    private getEventNameByTextContent(): string | null {
        const text = Array.from(this.element.childNodes)
            .filter(e => e instanceof Text)
            .map(e => e.textContent)
            .join(" ")
            .trim();

        return this.validNonEmptyString(text);
    }

    private getEventNameByImgSrc(): string | null {
        let imageFileName = this.getEventNameByAttribute("src");
        if (imageFileName) {
            imageFileName = imageFileName.split("/").pop() ?? null;
        }

        return this.validNonEmptyString(imageFileName);
    }

    private getEventNameByAttribute(name: string): string | null {
        const eventName = this.element.getAttribute(name);
        return this.validNonEmptyString(eventName);
    }

    private validNonEmptyString(text: any): string | null {
        if (!isString(text)) {
            return null;
        }
        const trimmed = text.trim();
        if (trimmed.length === 0) {
            return null;
        }
        return trimmed;
    }
}

export class ClickTrackingContext extends TrackingContext {

    protected static elementIsApplicable(element: Element): boolean {
        return element.isVisible() && element.isClickable();
    }
}