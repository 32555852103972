import type {PropertyMap} from "../../common/utils/objects";
import {autoRegister} from "../../container";
import {promiseFromJsonResponse, promiseFromVoidResponse} from "../../common/utils/promises";


export class SurveyGetQuestionRequest {
    public constructor(public lang: string, public userId: string) {
    }
}

export class SurveyPostAnswerRequest {
    public constructor(public userId: string, public questionId: number, public answer: string) {
    }
}

export class SurveyPostSkipRequest {
    public constructor(public userId: string, public questionId: number) {
    }
}

export class SurveyGetStatisticsForQuestionRequest {
    public constructor(public questionId: number, public lang: string) {
    }
}

export class SurveyGetStatisticsRequest {
    public constructor(public lang: string) {
    }
}

export type SurveyQuestionResponse = {
    id: number;
    text: string;
    topic?: string;
    type: string;
    options: PropertyMap<string>;
};

export type SurveyStatisticsResponse = {
    items: SurveyStatisticsForQuestionResponse[];
};

export type SurveyStatisticsForQuestionResponse = {
    question: SurveyQuestionResponse;
    totalCount: number;
    options: PropertyMap<SurveyOptionStatisticsResponse>;
};

export type SurveyOptionStatisticsResponse = {
    optionText: string;
    absolute: number;
    percentage: number;
};

@autoRegister()
export class SurveyService {

    public async getQuestion(request: SurveyGetQuestionRequest): Promise<SurveyQuestionResponse | null> {
        const response = await fetch(`/enbw-eop/eop-app-survey/rest/stable/question/next/${request.lang}?`
            + new URLSearchParams([["userId", request.userId]]).toString());

        switch (response.status) {
            case 200:
                return response.json();
            case 204:
                return null;
            default:
                throw new Error(`Response failed with status ${response.status}: ${response.statusText}`);
        }
    }

    public async postAnswer(request: SurveyPostAnswerRequest): Promise<void> {
        const response = await fetch(
            `/enbw-eop/eop-app-survey/rest/stable/question/submit/${request.questionId}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json;charset=UTF-8"
                },
                body: JSON.stringify({choice: request.answer, userId: request.userId})
            });
        return promiseFromVoidResponse(response);
    }

    public async postSkip(request: SurveyPostSkipRequest): Promise<void> {
        const response = await fetch(
            `/enbw-eop/eop-app-survey/rest/stable/question/skip/${request.questionId}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json;charset=UTF-8"
                },
                body: JSON.stringify({userId: request.userId})
            });
        return promiseFromVoidResponse(response);
    }

    public async getStatisticsForQuestion(request: SurveyGetStatisticsForQuestionRequest): Promise<SurveyStatisticsForQuestionResponse> {
        const response = await fetch(`/enbw-eop/eop-app-survey/rest/stable/question/statistics/${request.questionId}/${request.lang}`);
        return promiseFromJsonResponse(response);
    }

    public async getStatistics(request: SurveyGetStatisticsRequest): Promise<SurveyStatisticsResponse> {
        const response = await fetch(`/enbw-eop/eop-app-survey/rest/stable/question/statistics/${request.lang}`);
        return promiseFromJsonResponse(response);
    }
}