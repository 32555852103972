import {resolve} from "../../container";
import {ClickTracking} from "../../tracking/clickTracking";

export class EopAdditionalTextToggle extends HTMLElement {

    public constructor(private clickTracking: ClickTracking = resolve(ClickTracking)) {
        super();
    }

    public connectedCallback(): void {
        this.addEventListener("click", event => {
            this.clickTracking.handleTextlinkInteract(event, this);
            this.nextAll().forEach(it => it.classList.add("additional-text-separator-animation"));
            this.remove();
        });
    }
}

customElements.define("eop-additional-text-toggle", EopAdditionalTextToggle);