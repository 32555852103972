import {GLOBAL} from "../globals";

export class UrlBuilder {

    private url: string;

    public constructor(baseUrl: string) {
        this.url = baseUrl;
    }

    public withQueryParameter(key: string, value: string): this {
        let url: string;
        if (this.url.includes("?")) {
            url = this.url.concat("&");
        } else {
            url = this.url.concat("?");
        }
        this.url = url.concat(key, "=", encodeURIComponent(value));
        return this;
    }

    public build(): string {
        return this.url;
    }
}

export class UrlParts {

    public constructor(public base: string, public query: string, public anchor: string) {
    }

    public static from(href: string): UrlParts {
        const [baseAndQuery, anchor] = splitLinkSegment(href, "#");
        const [base, query] = splitLinkSegment(baseAndQuery, "?");
        return new UrlParts(base, query, anchor);
    }
}

function splitLinkSegment(href: string, separator: string): [string, string] {
    const separatorPosition = href.indexOf(separator);
    if (separatorPosition === -1) {
        return [href, ""];
    } else {
        return [href.substring(0, separatorPosition), href.substring(separatorPosition)];
    }
}

export function absoluteHrefFrom(relativeHref: string): string {
    return new URL(relativeHref, GLOBAL.documentBaseUri()).href;
}

export function relativize(absoluteHref: string): string {
    const currentOrigin = new URL(GLOBAL.documentBaseUri()).origin;
    return absoluteHref.replace(new RegExp("^" + currentOrigin), "");
}

export function sameParentDomain(href: string | URL, otherHref: string | URL): boolean {
    const url = href instanceof URL ? href : new URL(href);
    const otherUrl = otherHref instanceof URL ? otherHref : new URL(otherHref);
    return extractParentDomain(url.hostname) === extractParentDomain(otherUrl.hostname);
}

function extractParentDomain(hostname: string): string {
    const hostnameSplit = hostname.split(".");
    if (hasPermissibleSinglePartTopLevelDomain(hostname)) {
        return hostnameSplit.slice(hostnameSplit.length - 2).join(".");
    } else if (hasPermissibleTwoPartTopLevelDomain(hostname)) {
        return hostnameSplit.slice(hostnameSplit.length - 3).join(".");
    } else {
        throw new Error("Unsupported top level domain");
    }
}

function hasPermissibleSinglePartTopLevelDomain(hostname: string): boolean {
    const hostnameSplit = hostname.split(".");
    const singlePartTopLevelDomain = hostnameSplit.slice(hostnameSplit.length - 1).join(".");
    return PERMISSIBLE_SINGLE_PART_PARENT_DOMAINS.includes(singlePartTopLevelDomain);
}

function hasPermissibleTwoPartTopLevelDomain(hostname: string): boolean {
    const hostnameSplit = hostname.split(".");
    const twoPartTopLevelDomain = hostnameSplit.slice(hostnameSplit.length - 2).join(".");
    return PERMISSIBLE_TWO_PART_PARENT_DOMAINS.includes(twoPartTopLevelDomain);
}

const PERMISSIBLE_SINGLE_PART_PARENT_DOMAINS: string[] = ["com", "se", "fr", "vc", "de"];
const PERMISSIBLE_TWO_PART_PARENT_DOMAINS: string[] = ["co.uk"];