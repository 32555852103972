import {Interval} from "../../common/interval";
import {resolve} from "../../container";
import {customElement, property, query} from "lit/decorators.js";
import {UnLitElement} from "../../common/elements";

export const RELOAD_INTERVAL = 60 * 1000;

@customElement("eop-webcam-image")
export class EopWebcamImage extends UnLitElement {
    @query("img")
    private imgElement: HTMLImageElement;
    @property()
    private src: string;

    private reloadPromise: Promise<void>;

    public constructor(private interval: Interval = resolve(Interval)) {
        super();
    }

    public connectedCallback(): void {
        super.connectedCallback();

        this.reload();
        this.reloadPromise = this.interval.repeat(() => this.reload(), RELOAD_INTERVAL);
    }

    public disconnectedCallback(): void {
        this.interval.cancel(this.reloadPromise);
        super.disconnectedCallback();
    }

    private reload(): void {
        this.imgElement.setAttribute("src", this.src + "?__random=" + new Date().getTime());
    }
}