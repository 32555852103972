import {OUT_OF_VIEW_ATTR} from "./imageUtils";
import {customElement, property, query} from "lit/decorators.js";
import {html, type TemplateResult} from "lit";
import {ColorSchemeAwareImage} from "./colorSchemeAwareImage";
import {PlainImageLoadListener} from "./plainImageCommons";

@customElement("eop-plain-image")
export class EopPlainImage extends ColorSchemeAwareImage {

    @property({attribute: "alt"})
    public alt: string;
    @property({attribute: OUT_OF_VIEW_ATTR, type: Boolean})
    public outOfView: boolean = false;

    @query(".plain-image")
    private imageElement: HTMLImageElement;

    private imageLoadListener: PlainImageLoadListener;

    public constructor() {
        super();
        this.imageLoadListener = new PlainImageLoadListener(() => this.imageElement, this);
    }

    public render(): TemplateResult {
        return html`<img src=${this.outOfView ? "" : this.srcCurrent}
                         alt=${this.alt ?? ""}
                         loading="lazy"
                         @load=${{handleEvent: () => this.imageLoadListener.imageLoaded(this.srcCurrent), once: true}}
                         class="plain-image"
                         ?baseplate=${this.enableBaseplate}/>`;
    }
}