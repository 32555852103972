import {BOOTSTRAP, BootstrapBreakpoint, IMAGE_BREAKPOINTS} from "./resolutionConstants";
import {Page} from "./page";
import {GLOBAL} from "./globals";
import {autoRegister, resolve} from "../container";
import {Observers} from "./observer";
import type {Lifetime} from "./lifetime";

@autoRegister()
export class Resolution {
    private resizeObservers: Observers<number>;
    private bootstrapBreakpointChangeObservers: Observers<BootstrapBreakpoint>;
    private initialBootstrapBreakpoint: BootstrapBreakpoint;

    public constructor(private page: Page = resolve(Page)) {
        this.resizeObservers = new Observers<number>();
        this.bootstrapBreakpointChangeObservers = new Observers<BootstrapBreakpoint>();
        this.initialBootstrapBreakpoint = this.getBootstrapBreakpoint();

        GLOBAL.window().addEventListener("resize", () => this.resizeCallback());
    }

    private resizeCallback(): void {
        this.resizeObservers.notify(this.getBreakpoint());
        this.bootstrapBreakpointChangeObservers.notify(this.getBootstrapBreakpoint());
    }

    public getBootstrapBreakpoint(): BootstrapBreakpoint {
        const width = this.viewportWidth();

        for (const label in BOOTSTRAP) {
            const bootstrapBreakpoint: BootstrapBreakpoint = BOOTSTRAP[label];
            const lowerIntervalBoundary = bootstrapBreakpoint.range[0];
            const upperIntervalBoundary = bootstrapBreakpoint.range[1];
            if (width >= lowerIntervalBoundary && width <= upperIntervalBoundary) {
                return bootstrapBreakpoint;
            }
        }
        return BOOTSTRAP.XXL;
    }

    public upTo(breakpoint: BootstrapBreakpoint): boolean {
        return this.getBootstrapBreakpoint().isUpTo(breakpoint);
    }

    public downTo(breakpoint: BootstrapBreakpoint): boolean {
        return this.getBootstrapBreakpoint().isDownTo(breakpoint);
    }

    public getBreakpoint(): number {
        const windowWidth = this.viewportWidth();
        for (const label in IMAGE_BREAKPOINTS) {
            const breakpoint = IMAGE_BREAKPOINTS[label];
            if (windowWidth >= breakpoint.min && windowWidth <= breakpoint.max) {
                return breakpoint.min;
            }
        }
        return IMAGE_BREAKPOINTS._1921.min;
    }

    private viewportWidth(): number {
        return this.page.viewportWidth();
    }

    public onWindowResize(callback: (breakpoint: number) => void, lifetime?: Lifetime): void {
        this.resizeObservers.register(callback, lifetime);
    }

    public onBootstrapBreakpointChange(callback: (breakpoint: BootstrapBreakpoint) => void, lifetime?: Lifetime): void {
        let currentBootstrapBreakpoint = this.initialBootstrapBreakpoint;
        this.bootstrapBreakpointChangeObservers.register((bp) => {
            if (bp !== currentBootstrapBreakpoint) {
                currentBootstrapBreakpoint = bp;
                callback(currentBootstrapBreakpoint);
            }
        }, lifetime);
    }

    // Only use this in tests
    public triggerResize(): void {
        this.resizeCallback();
    }
}