import {customElement} from "lit/decorators.js";
import {TabModel} from "../../common/tabModel";
import {UnLitElement} from "../../common/elements";


@customElement("eop-binary-content-switch")
export class EopBinaryContentSwitch extends UnLitElement {
    private labels: TabModel;
    private contents: TabModel;
    private switchButton: Element;

    public constructor() {
        super();

        this.labels = new TabModel(this, this.querySelectorAll(".binary-switch-label")).periodicSwitch({class: "active"});
        this.contents = new TabModel(this, this.querySelectorAll(".binary-switch-content")).periodicSwitch({class: "active"});
        this.switchButton = this.querySelector(".binary-switch-button")!;
    }

    public connectedCallback(): void {
        super.connectedCallback();
        this.switchButton.addEventListener("click", () => {
            if (this.switchButton.classList.contains("toggled")) {
                this.switchButton.classList.remove("toggled");
            } else {
                this.switchButton.classList.add("toggled");
            }
            this.labels.selectNext();
            this.contents.selectNext();
        });
    }
}