import {noop} from "./utils/functions";

export type IndexedElement<TElement = HTMLElement> = { i: number; element: TElement };
export type TransitionAdvice = (hide: IndexedElement, show: IndexedElement) => void;

export class Animation {
    public duration: number;
    public pre: TransitionAdvice;
    public post: TransitionAdvice;

    public constructor() {
        this.duration = 0;
        this.pre = noop;
        this.post = noop;
    }

    public millisceconds(duration: number): this {
        this.duration = duration;
        return this;
    }

    public onStart(pre: TransitionAdvice): this {
        this.pre = pre;
        return this;
    }

    public onDone(post: TransitionAdvice): this {
        this.post = post;
        return this;
    }
}