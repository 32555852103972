import {RelativeDateExpression} from "./parseDate";

export class DatePeriod {

    public constructor(private value: number, private unit: string) {
    }

    public negate(): DatePeriod {
        return new DatePeriod(-this.value, this.unit);
    }

    public getValue(): number {
        return this.value;
    }

    public getUnit(): string {
        return this.unit;
    }

    public static fromDateExpression(exp: string): DatePeriod {
        const expression = RelativeDateExpression.parse(exp);
        return expression
            ? expression.toDatePeriod()
            : new DatePeriod(0, "d");
    }
}