import {html, LitElement, type TemplateResult} from "lit";
import {customElement, property} from "lit/decorators.js";
import Styles from "./_swipe-hint.lit.scss";
import {elementFrom} from "../../../common/utils/html";
import {FeatureDetector} from "../../../common/featureDetector";
import {resolve} from "../../../container";
import {HorizontalOverflowService} from "../horizontalOverflow";
import SWIPE_ICON from "../../../../../resources/assets/images/swipehint.svg";

@customElement("eop-swipe-hint")
export class EopSwipeHint extends LitElement {

    public static readonly styles = Styles;

    @property({attribute: "size"})
    private size: string;
    @property({type: Boolean, reflect: true})
    public active: boolean;

    public constructor(
        private features: FeatureDetector = resolve(FeatureDetector),
        private horizontalOverflowService: HorizontalOverflowService = resolve(HorizontalOverflowService)
    ) {
        super();
        this.size = "small";
        this.active = false;
    }

    public connectedCallback(): void {
        super.connectedCallback();
        this.horizontalOverflowService.registerHandler(this, overflown => this.active = overflown);
    }

    public disconnectedCallback(): void {
        this.horizontalOverflowService.unregisterHandler(this);
        super.disconnectedCallback();
    }

    public render(): TemplateResult | null {
        if (!this.features.isTouchDevice()) {
            return null;
        }

        const hintType = "swipe-hint-" + this.size;
        return html`
            <div class=swipe-hint-box>
                <eop-already-in-viewport percentage=0.8 class="swipe-hint ${hintType}">
                    ${elementFrom(SWIPE_ICON)}
                </eop-already-in-viewport>
            </div>`;
    }
}