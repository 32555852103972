import {customElement} from "lit/decorators.js";
import {Resolution} from "../../../common/resolution";
import {resolve} from "../../../container";
import {Page} from "../../../common/page";
import {ScrollService} from "../../../common/scroll";
import {EopExecutiveBoardGallery, executiveBoardGalleryConfig} from "./executiveBoardGallery";
import StylesFourWithoutGroupShot from "./executiveBoardGalleryFourWithoutGroupShot.lit.scss";
import StylesCommon from "./executiveBoardGalleryCommon.lit.scss";

@customElement("eop-executive-board-gallery-4-no-intro-image")
export class EopExecutiveBoardGalleryFourWithoutGroupShot extends EopExecutiveBoardGallery {

    public static readonly styles = [StylesCommon, StylesFourWithoutGroupShot];

    public constructor(resolution: Resolution = resolve(Resolution), page: Page = resolve(Page), scrollService: ScrollService = resolve(ScrollService)) {
        // has duplicate in scss
        const portraitOnStageTimeline = [0, 0.32, 0.63, 0.95];
        const config = executiveBoardGalleryConfig(portraitOnStageTimeline, {portraitCount: 4, hasGroupShot: false});
        super(config, resolution, page, scrollService);
    }
}
