import {LoadAfterConfirmationEvents} from "../../page/elements/loadAfterConfirmation";
import {Load} from "../../common/load";
import {autoRegister, resolve} from "../../container";
import {Nav} from "../../common/nav";

@autoRegister()
export class TwitterNative {
    private twitterLoaded: Promise<any>;

    public constructor(private nav: Nav = resolve(Nav), private load: Load = resolve(Load)) {
    }

    public init(): Promise<any> {
        const twitterSrc: string = this.nav.protocol() + "//platform.twitter.com/widgets.js";
        this.twitterLoaded = this.twitterLoaded ?? this.load.script(twitterSrc);

        return this.twitterLoaded;
    }
}

export class EopSocialTwitter extends HTMLElement {
    public constructor(
        private twitterNative: TwitterNative = resolve(TwitterNative),
        private loadAfterConfirmationEvents: LoadAfterConfirmationEvents = resolve(LoadAfterConfirmationEvents)
    ) {
        super();
    }

    public connectedCallback(): void {
        this.loadAfterConfirmationEvents.onConfirmation("twitter", () => this.twitterNative.init());
    }
}

customElements.define("eop-social-twitter", EopSocialTwitter);