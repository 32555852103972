import {AutoInitializing} from "../../common/elements";
import {html, LitElement, type PropertyValues, type TemplateResult} from "lit";
import {ManagingResources} from "../../common/lifetime";
import {unsafeSVG} from "lit/directives/unsafe-svg.js";
import LEFT_ARROW from "../../../../resources/assets/images/left_arrow.svg";
import RIGHT_ARROW from "../../../../resources/assets/images/right_arrow.svg";
import {ScrollService} from "../../common/scroll";
import {resolve} from "../../container";
import {ResizeObserverFactory} from "../../common/observation";
import {HorizontalOverflow, HorizontalOverflowInstaller} from "./horizontalOverflow";
import {query, state} from "lit/decorators.js";

export const HORIZONTAL_SCROLL_ERROR_MARGIN = 8;

export const SCROLL_DURATION = 250;

export abstract class ScrollableBar extends ManagingResources(AutoInitializing(LitElement)) {

    protected abstract scrollableArea: HTMLElement;
    @state()
    private canScrollLeft: boolean;
    @state()
    private canScrollRight: boolean;
    @query(".scroll-left")
    private scrollLeftButton: HTMLElement;
    @query(".scroll-right")
    private scrollRightButton: HTMLElement;
    private resizeObserver: ResizeObserver;
    private horizontalOverflow: HorizontalOverflow;

    protected constructor(
        protected scrollService: ScrollService = resolve(ScrollService),
        private resizeObserverFactory: ResizeObserverFactory = resolve(ResizeObserverFactory),
        private horizontalOverflowInstaller: HorizontalOverflowInstaller = resolve(HorizontalOverflowInstaller)
    ) {
        super();
    }

    protected abstract items(): HTMLElement[]

    public connectedCallback(): void {
        super.connectedCallback();
        this.resizeObserver = this.resizeObserverFactory.create(() => this.toggleScrollButtonVisibility());
    }

    protected firstUpdated(_changedProperties: PropertyValues): void {
        super.firstUpdated(_changedProperties);
        this.horizontalOverflow = this.horizontalOverflowInstaller.installOn(this.scrollableArea);
        this.resizeObserver.observe(this.scrollableArea);
    }

    public disconnectedCallback(): void {
        this.horizontalOverflow?.uninstall();
        this.resizeObserver.disconnect();
        super.disconnectedCallback();
    }

    protected renderScrollLeftButton(): TemplateResult | null {
        if (this.canScrollLeft) {
            return html`
                <button
                        class="scroll-button scroll-left"
                        @click=${this.scrollHorizontallyLeft}
                        data-tracking-label="scroll-left"
                >
                    ${unsafeSVG(LEFT_ARROW)}
                </button>`;
        } else {
            return null;
        }
    }

    protected renderScrollRightButton(): TemplateResult | null {
        if (this.canScrollRight) {
            return html`
                <button
                        class="scroll-button scroll-right"
                        @click=${this.scrollHorizontallyRight}
                        data-tracking-label="scroll-right"
                >
                    ${unsafeSVG(RIGHT_ARROW)}
                </button>`;
        } else {
            return null;
        }
    }

    private scrollHorizontallyRight(): void {
        const viewPortOffset = this.scrollableArea.leftOffset();
        const innerRightEdge = this.innerRightEdge();

        const distanceToScroll = this.items()
            .filter(item => item.rightOffset() > innerRightEdge)
            .map(item => item.leftOffset() - viewPortOffset - this.scrollButtonWidth())
            .findFirst(scrollX => scrollX >= 1);

        this.scrollHorizontallyBy(distanceToScroll ?? this.scrollableArea.scrollWidth);
    }

    private scrollHorizontallyLeft(): void {
        const viewPortOffset = this.scrollableArea.leftOffset();
        const firstPartiallyVisibleItem = this.firstPartiallyVisibleItemToLeft();
        const partiallyVisibleWidth = firstPartiallyVisibleItem.leftOffset() + firstPartiallyVisibleItem.outerWidth({includeMargin: true}) - this.innerLeftEdge();
        const remainingWidthToScrollInto = this.scrollableArea.clientWidth - partiallyVisibleWidth - (this.scrollButtonWidth() * 2);

        const reversedItems = this.items().inReverseOrder();
        const indexOfFirstPartiallyVisibleItem = reversedItems.indexOf(firstPartiallyVisibleItem);
        const firstNotFittingItem = reversedItems
            .filter((_item, index) => index > indexOfFirstPartiallyVisibleItem)
            .findFirst(item => item.leftOffset() < -(remainingWidthToScrollInto + viewPortOffset));
        if (firstNotFittingItem) {
            const indexOfFirstNotFittingItem = reversedItems.indexOf(firstNotFittingItem);
            if (indexOfFirstNotFittingItem === 0) {
                this.scrollHorizontallyBy(firstNotFittingItem.leftOffset() - viewPortOffset - this.scrollButtonWidth());
            } else {
                const prevItem = reversedItems[indexOfFirstNotFittingItem - 1];
                this.scrollHorizontallyBy(prevItem.leftOffset() - viewPortOffset - this.scrollButtonWidth());
            }
        } else {
            this.scrollHorizontallyTo(0);
        }
    }

    private firstPartiallyVisibleItemToLeft(): HTMLElement {
        const firstObscuredItem = this.items().inReverseOrder()
            .filter(item => item.leftOffset() < this.innerLeftEdge())
            .first();

        return firstObscuredItem || this.items()[0];
    }

    private innerLeftEdge(): number {
        return this.scrollLeftButton ? this.scrollLeftButton.rightOffset() : this.scrollableArea.leftOffset();
    }

    private innerRightEdge(): number {
        return this.scrollRightButton?.leftOffset() ?? this.scrollableArea.rightOffset();
    }

    private scrollHorizontallyBy(scrollX: number): void {
        this.scrollHorizontallyTo(scrollX + this.scrollableArea.scrollLeft);
    }

    private scrollHorizontallyTo(scrollX: number): void {
        void this.scrollService.enclosingHorizontalArea(this.scrollableArea).scrollTo(scrollX, SCROLL_DURATION);
    }

    private scrollButtonWidth(): number {
        return this.scrollLeftButton?.clientWidth ?? this.scrollRightButton?.clientWidth ?? 0;
    }

    protected scrollToItem(index: number): void {
        void this.scrollService.enclosingHorizontalArea(this.scrollableArea)
            .scrollToElementCentered(this.items()[index], SCROLL_DURATION);
    }

    protected toggleScrollButtonVisibility(): void {
        const scrollLeft = this.scrollableArea.scrollLeft;
        const scrollWidth = this.scrollableArea.scrollWidth;
        const elementWidth = this.scrollableArea.clientWidth;

        if (elementWidth + HORIZONTAL_SCROLL_ERROR_MARGIN >= scrollWidth) {
            this.canScrollLeft = false;
            this.canScrollRight = false;
            return;
        }

        this.canScrollRight = scrollLeft + elementWidth + HORIZONTAL_SCROLL_ERROR_MARGIN < scrollWidth;
        this.canScrollLeft = scrollLeft > HORIZONTAL_SCROLL_ERROR_MARGIN;
    }
}