import {IntersectionObserverFactory, onceIntersected} from "../../../common/observation";
import {resolve} from "../../../container";
import {html, type TemplateResult} from "lit";
import {customElement, property, state} from "lit/decorators.js";
import {ColorSchemeAwareImage} from "./colorSchemeAwareImage";
import {OUT_OF_VIEW_ATTR} from "./imageUtils";

@customElement("eop-animated-svg")
export class EopAnimatedSvg extends ColorSchemeAwareImage {

    @property({attribute: "title"})
    public dataTitle: string;
    @property({attribute: OUT_OF_VIEW_ATTR, type: Boolean})
    public outOfView: boolean = false;

    @state()
    private startAnimation: boolean = false;
    private intersectionObserver: IntersectionObserver;

    public constructor(
        private intersectionObserverFactory: IntersectionObserverFactory = resolve(IntersectionObserverFactory)
    ) {
        super();
    }

    public connectedCallback(): void {
        super.connectedCallback();

        this.intersectionObserver = this.intersectionObserverFactory.create(onceIntersected(() => this.activateSvgElement()), {threshold: 0.2});
        this.intersectionObserver.observe(this);
    }

    public render(): TemplateResult {
        return html`
            <object type="image/svg+xml" class="svg-image" title=${this.dataTitle} data=${!this.outOfView && this.startAnimation ? this.srcCurrent : ""}
                    ?baseplate=${this.enableBaseplate}></object>`;
    }

    private activateSvgElement(): void {
        this.startAnimation = true;
    }

    public disconnectedCallback(): void {
        this.intersectionObserver.disconnect();
        super.disconnectedCallback();
    }
}