import type {ViewportCallback} from "./viewportCallback";
import {ViewportListener} from "./viewportListener";
import {autoRegister, resolve} from "../container";

import type {Lifetime} from "./lifetime";

type ListeningContext = {
    evaluatePredicate: () => boolean;
    callback: (viewportCondition: boolean) => void;
    currentConditionValue: boolean;
};


@autoRegister()
export class ViewportChangeListener {

    public constructor(private viewportListener: ViewportListener = resolve(ViewportListener)) {
    }

    public listenToConditionChange(viewportCallback: ViewportCallback, lifetime?: Lifetime): void {
        const context: ListeningContext = {
            evaluatePredicate: () => viewportCallback.evaluatePredicate(),
            callback: b => viewportCallback.callCallback(b),
            currentConditionValue: viewportCallback.evaluatePredicate()
        };

        this.viewportListener.onViewportEvent(() => this.callCallbackIfConditionChanged(context), lifetime);
        viewportCallback.apply();
    }

    private callCallbackIfConditionChanged(context: ListeningContext): void {
        const newConditionValue = context.evaluatePredicate();
        if (newConditionValue !== context.currentConditionValue) {
            context.currentConditionValue = newConditionValue;
            context.callback(newConditionValue);
        }
    }
}