export type Maybe<T> = T | null | undefined;

export function isDefined<T>(value: (T | undefined)): value is T {
    return value !== undefined;
}

export function isNotNull<T>(value: (T | null)): value is T {
    return value !== null;
}

export function isPresent<T>(value: Maybe<T>): value is T {
    return value !== undefined && value !== null;
}

export function isUndefined<T>(value: any): value is undefined {
    return value === undefined;
}

export function isNull(value: any): value is null {
    return value === null;
}

export function falsyToUndefined<T>(value: Maybe<T>): T | undefined {
    if (!value) {
        return undefined;
    }
    return value;
}