import {ModalDialog, ModalDialogFactory} from "./modalDialog";
import {isNull} from "../../../common/utils/basics";
import {resolve} from "../../../container";
import {elementFrom} from "../../../common/utils/html";


export class EopInlineModalDialog extends HTMLElement {
    private dialog: ModalDialog;

    public constructor(private modalDialog: ModalDialogFactory = resolve(ModalDialogFactory)) {
        super();
    }

    public connectedCallback(): void {
        if (this.dialog) {
            return;
        }

        const closeOnOutsideClick = isNull(this.getAttribute("disable-close-on-outside-click"));

        this.dialog = this.modalDialog.create()
            .closingOnOutsideClick(closeOnOutsideClick);

        const contentId = this.getAttribute("content")!;
        const template = this.resolveTemplateElement(contentId);
        if (template) {
            this.dialog.withContent(elementFrom(template.innerHTML));
        }

        this.addEventListener("click", () => this.dialog.open());
    }
}

customElements.define("eop-inline-modal-dialog", EopInlineModalDialog);