import {EqualHeightManager} from "./equalHeight";
import {autoRegister, resolve} from "../../container";
import type {ElementFeatureFactory, ReadyElementFeature} from "./elementFeatures";
import {Page} from "../../common/page";

export class AlignedSlots implements ReadyElementFeature {

    public constructor(
        private element: Element,
        private equalHeightManager: EqualHeightManager = resolve(EqualHeightManager),
        private page: Page = resolve(Page)
    ) {
    }

    public installForSingleRow(): this {
        this.equalHeightManager.registerRegion(this.element);
        const regionId = this.element.getBoundingClientRect().top;

        Array.from(this.element.querySelectorAll<HTMLElement>("[data-role]"))
            .filter(element => !element.closest("[data-disable-aligned-slots]"))
            .forEach(element => {
                const groupBase = element.getAttribute("data-role") ?? "";
                this.equalHeightManager.register(element, regionId + "-" + groupBase);
            });
        return this;
    }

    public install(): this {
        this.equalHeightManager.registerRegion(this.element);
        const regionId = this.element.getBoundingClientRect().top;

        Array.from(this.element.querySelectorAll<HTMLElement>("[data-role]"))
            .filter(element => !element.closest("[data-disable-aligned-slots]"))
            .forEach(element => {
                const module = element.closest(".content-module");
                if (module) {
                    const moduleContainer = module.closest(".content-modules");
                    this.equalHeightManager.registerResponsive(element, breakpoint => {
                        const groupBase = element.getAttribute("data-role") ?? "";
                        const offset = Array.from((moduleContainer ?? module).querySelectorAll(".content-module"))
                            .indexOf(module);

                        let alignment = moduleContainer && (moduleContainer as AlignmentElement).alignment;
                        if (!alignment || breakpoint) {
                            alignment = (moduleContainer?.getBoundingClientRect().top ?? 0) + this.page.getYScrollPosition();
                            (moduleContainer as AlignmentElement).alignment = alignment;
                        }
                        return regionId + "-" + alignment + "-" + groupBase + "-" + offset;
                    });
                }
            });

        return this;
    }

    public uninstall(): void {
        this.equalHeightManager.unregisterRegion(this.element);
    }
}

export type AlignmentElement = HTMLElement & {
    alignment: number;
};

@autoRegister()
export class AlignedSlotsFactory implements ElementFeatureFactory<AlignedSlots> {
    public installOn(element: Element): AlignedSlots {
        const alignedSlots = new AlignedSlots(element);
        return alignedSlots.install();
    }

    public installForSingleRow(element: Element): AlignedSlots {
        const alignedSlots = new AlignedSlots(element);
        return alignedSlots.installForSingleRow();
    }
}