import {OnOffTrigger, type TriggerFactory} from "./triggers";

export class ClickTrigger extends OnOffTrigger {
    public constructor(private element: Element, callback: () => void) {
        super(callback);

        this.element.addEventListener("click", event => {
            event.preventDefault();
            this.trigger();
        });
    }
}

export class ClickTriggerFactory implements TriggerFactory {

    public createTrigger(element: Element, callback: () => void): ClickTrigger {
        return new ClickTrigger(element, callback);
    }
}