import {LoadAfterConfirmationEvents} from "../../page/elements/loadAfterConfirmation";
import {Resolution} from "../../common/resolution";
import {isObject} from "../../common/utils/objects";
import {GLOBAL} from "../../common/globals";
import {autoRegister, resolve} from "../../container";
import {Load} from "../../common/load";


export type FacebookWindow = Window & {
    FB: any;
};

@autoRegister()
export class LoadFacebookService {
    private facebookLoaded: Promise<void>;
    private window: FacebookWindow;

    public constructor(
        private resolution: Resolution = resolve(Resolution),
        private load: Load = resolve(Load),
        window: Window = GLOBAL.window()
    ) {
        this.window = window as FacebookWindow;
    }

    public init(facebookId: string): Promise<void> {
        this.facebookLoaded = this.facebookLoaded ?? this.load.script("https://connect.facebook.net/de_DE/sdk.js")
            .then(() => {
                if (!isObject(this.window.FB)) {
                    throw new Error("Facebook not loaded");
                }
                this.resolution.onWindowResize(() => {
                    this.window.FB.XFBML.parse();
                });
                this.window.FB.init({
                    appId: facebookId,
                    version: "v2.5"
                });
            })
            .then(() => {
                this.window.FB.XFBML.parse();
            });
        return this.facebookLoaded;
    }
}

export class EopSocialFacebook extends HTMLElement {
    public constructor(
        private loadAfterConfirmationEvents: LoadAfterConfirmationEvents = resolve(LoadAfterConfirmationEvents),
        private loadFacebookService: LoadFacebookService = resolve(LoadFacebookService)
    ) {
        super();
    }

    public connectedCallback(): void {
        const facebookId = this.getAttribute("facebook-id");

        if (facebookId) {
            this.loadAfterConfirmationEvents.onConfirmation("facebook", () => {
                return this.loadFacebookService.init(facebookId);
            });
        }
    }
}

customElements.define("eop-social-facebook", EopSocialFacebook);