import {
    SurveyGetQuestionRequest,
    SurveyGetStatisticsForQuestionRequest,
    SurveyPostAnswerRequest,
    SurveyPostSkipRequest,
    type SurveyQuestionResponse,
    SurveyService
} from "./surveyService";
import {resolve} from "../../container";
import {LocalStorage} from "../../common/clientStorage";
import * as UUID from "../../common/uuid";
import type {Question, StatisticsPerOption, SurveyNavigation} from "./abstractQuestion";
import {BinaryQuestion, type BinaryQuestionOptions} from "./binaryQuestion";
import {SingleChoiceQuestion} from "./singleChoiceQuestion";
import {EopNoFurtherQuestions} from "./noFurtherQuestions";
import {EOP_ERRORS, Promises, schedule} from "../../common/utils/promises";

const MILLIS_OF_90_DAYS = 1000 * 60 * 60 * 24 * 90;
const CLIENT_STORAGE_SURVEY_KEY = "survey";
export type SurveyClientStorageData = {
    userId: string;
};

enum QuestionTypes {
    BINARY = "binary",
    SINGLE_CHOICE = "singleChoice"
}

export class EopSurveyElement extends HTMLElement implements SurveyNavigation {

    private language: string;
    private overviewLink: string | undefined;
    private userId: string;
    private spinnerId: string;

    public constructor(
        private surveyService: SurveyService = resolve(SurveyService),
        private localStorage: LocalStorage = resolve(LocalStorage),
        private promises: Promises = resolve(Promises)
    ) {
        super();
        this.language = this.getAttribute("language") ?? "";
        this.overviewLink = this.getAttribute("overview-link") ?? undefined;
        this.spinnerId = this.getAttribute("spinner-id") ?? "";
    }

    public connectedCallback(): void {
        this.userId = this.getOrCreateUserId();
        void schedule(this.nextQuestion().catch(EOP_ERRORS)).as("next-question");
    }

    private getOrCreateUserId(): string {
        let userId = this.localStorage
            .fetch<SurveyClientStorageData>(CLIENT_STORAGE_SURVEY_KEY)
            ?.userId;
        if (!userId) {
            userId = UUID.newUUID();
            const date90DaysInFuture = new Date().getTime() + MILLIS_OF_90_DAYS;
            this.localStorage.save(CLIENT_STORAGE_SURVEY_KEY, {userId: userId}, {until: date90DaysInFuture});
        }
        return userId;
    }

    public async nextQuestion(): Promise<void> {
        return this.promises.decoratorFor(this.spinnerId)((async () => {
            this.classList.remove("survey-result-phase");
            this.classList.add("survey-prompt-phase");

            const getQuestionRequest = new SurveyGetQuestionRequest(this.language, this.userId);
            const response = await this.surveyService.getQuestion(getQuestionRequest);

            const questionElement = this.createQuestionElementFrom(response);
            this.querySelector(".survey-panel")!.replaceChildren(questionElement);
        })());
    }

    private createQuestionElementFrom(response: SurveyQuestionResponse | null): HTMLElement {
        if (!response) {
            return new EopNoFurtherQuestions(this.getAttribute("no-further-questions-text") ?? "");
        }

        const question = getQuestionFrom(response);
        return question.createInteractableElement(this, {overviewLink: this.overviewLink});
    }

    public async skipQuestion(questionId: number): Promise<void> {
        return this.promises.decoratorFor(this.spinnerId)((async () => {
            await this.surveyService.postSkip(new SurveyPostSkipRequest(this.userId, questionId));
            await this.nextQuestion();
        })());
    }

    public async answerAndGetStatistics(questionId: number, choice: string): Promise<StatisticsPerOption> {
        return this.promises.decoratorFor(this.spinnerId)((async () => {
            await this.surveyService.postAnswer(new SurveyPostAnswerRequest(this.userId, questionId, choice));
            const response = await this.surveyService.getStatisticsForQuestion(new SurveyGetStatisticsForQuestionRequest(questionId, this.language));

            this.classList.remove("survey-prompt-phase");
            this.classList.add("survey-result-phase");
            return response.options as StatisticsPerOption;
        })());
    }

}


export function getQuestionFrom(questionResponse: SurveyQuestionResponse): Question {
    switch (questionResponse.type) {
        case QuestionTypes.BINARY: {
            return new BinaryQuestion({
                id: questionResponse.id,
                text: questionResponse.text,
                topic: questionResponse.topic,
                options: questionResponse.options as BinaryQuestionOptions
            });
        }
        case QuestionTypes.SINGLE_CHOICE:
        default: {
            return new SingleChoiceQuestion({
                id: questionResponse.id,
                text: questionResponse.text,
                topic: questionResponse.topic,
                options: questionResponse.options
            });
        }
    }
}

customElements.define("eop-survey", EopSurveyElement);