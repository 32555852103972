import {isString} from "../../bootstrap/common/strings";
import {autoRegister, resolve} from "../../container";
import {Timeout} from "../../common/timeout";
import {Resolution} from "../../common/resolution";
import type {BootstrapBreakpoint} from "../../common/resolutionConstants";
import {MutationObserverFactory, ResizeObserverFactory} from "../../common/observation";

export class EqualHeightGroup {
    private group: string;
    private height: string | number;

    public constructor(group: string) {
        this.group = group;
        this.height = "auto";
    }

    public newElement(element: HTMLElement): EqualHeightElement {
        return new EqualHeightElement(element, this);
    }

    public contains(group: string): boolean {
        return this.group === group;
    }

    public getHeight(): string | number {
        return this.height;
    }

    public resetHeight(): void {
        this.height = "auto";
    }

    public proposeHeight(height: number): void {
        if (isString(this.height)) {
            this.height = height;
        } else {
            this.height = Math.max(this.height, height);
        }
    }

}

export class EqualHeightElement {
    private element: HTMLElement;
    private observed: Element[];
    private group: EqualHeightGroup;

    public constructor(
        element: HTMLElement,
        group: EqualHeightGroup
    ) {
        this.element = element;
        this.group = group;
        this.observed = [];
    }

    public getElementsToObserve(): [Element[], Element[]] {
        const added = [];
        const deleted = this.observed.slice();
        const toObserve = Array.from(this.element.childNodes)
            .filter(e => e instanceof Element)
            .map(e => e);
        for (const node of toObserve) {
            if (this.observed.indexOf(node) === -1) {
                added.push(node);
            } else {
                deleted.removeAll(node);
            }
        }
        this.observed = toObserve;
        return [added, deleted];
    }

    public getElement(): Element {
        return this.element;
    }

    public updateGroup(group: EqualHeightGroup): void {
        this.group = group;
    }

    public getGroup(): EqualHeightGroup {
        return this.group;
    }

    public applyHeightIfPossible(group: EqualHeightGroup): void {
        if (this.canChangeHeightFor(group)) {
            this.element.style.height = group.getHeight() + "px";
        }
    }

    public resetHeight(): void {
        this.element.style.height = "auto";
    }

    public getHeight(): number {
        return this.element.clientHeight ?? 0;
    }

    private isDisplayed(): boolean {
        return this.element.isVisible();
    }

    public canChangeHeightFor(group: EqualHeightGroup): boolean {
        return this.isDisplayed() && this.group === group;
    }
}

@autoRegister()
export class EqualHeightManager {
    private equalHeightElements: EqualHeightElement[];
    private groups: EqualHeightGroup[];
    private updates: ResponsiveUpdate[];
    private mutationObserver: MutationObserver;
    private resizeObserver: ResizeObserver;
    private renderer: Promise<void> | null;

    public constructor(
        private resolution: Resolution = resolve(Resolution),
        private timeout: Timeout = resolve(Timeout),
        private resizeObserverFactory: ResizeObserverFactory = resolve(ResizeObserverFactory),
        private mutationObserverFactory: MutationObserverFactory = resolve(MutationObserverFactory)
    ) {
        this.equalHeightElements = [];
        this.groups = [];
        this.updates = [];
        this.renderer = null;
        this.mutationObserver = this.mutationObserverFactory.create((mutations: MutationRecord[]) => {
            mutations
                .flatMap(mutation => this.groupsFor(mutation))
                .distinct()
                .forEach(group => this.schedule(group));
        });
        this.resizeObserver = this.resizeObserverFactory.create((entries: ReadonlyArray<ResizeObserverEntry>) => {
            entries
                .flatMap(entry => this.groupsFor(entry))
                .distinct()
                .forEach(group => this.schedule(group));
        });
        this.resolution.onBootstrapBreakpointChange(breakpoint => {
            const updates = this.updates.filter(update => update.element.isConnected);
            updates.forEach(update => this.register(update.element, update.groupName(breakpoint)));
            this.updates = updates;
        });

    }

    public registerRegion(element: Element): void {
        this.mutationObserver.observe(element, {subtree: true, childList: true, characterData: true});
    }

    public unregisterRegion(element: Element): void {
        // there is no unobserve, do not observe subtree and childList instead
        // https://github.com/whatwg/dom/issues/126
        this.mutationObserver.observe(element, {subtree: false, childList: false, characterData: true});
    }

    public registerResponsive(element: HTMLElement, responsiveGroupName: (bp?: BootstrapBreakpoint) => string): EqualHeightElement {
        const equalHeightElement = this.register(element, responsiveGroupName());
        this.resolution.onBootstrapBreakpointChange(bp => {
            element.setAttribute("group", responsiveGroupName(bp));
            this.register(element, responsiveGroupName(bp));
        });

        return equalHeightElement;
    }

    public register(element: HTMLElement, group: string): EqualHeightElement {
        const equalHeightGroup = this.fetchGroup(group);
        const equalHeightElement = this.fetchElement(element, equalHeightGroup);

        const [added, removed] = equalHeightElement.getElementsToObserve();
        removed.forEach(node => this.resizeObserver.unobserve(node));
        added.forEach(node => this.resizeObserver.observe(node));
        this.schedule(equalHeightElement.getGroup());

        return equalHeightElement;
    }

    private schedule(group: EqualHeightGroup): void {
        if (!this.renderer) {
            this.renderer = this.timeout.delay(() => {
                this.flushScheduledGroups();
                this.renderer = null;
            });
        }
        this.scheduleGroup(group);
    }

    private updateHeights(group: EqualHeightGroup): void {
        this.resetHeights(group);
        this.interpolateHeights(group);
        this.applyHeights(group);
    }

    private groupsFor(change: MutationRecord | ResizeObserverEntry): EqualHeightGroup[] {
        const elements = this.path(change.target);
        return this.equalHeightElements
            .filter(e => elements.some(element => element === e.getElement()))
            .distinct()
            .map(equalHeightElement => equalHeightElement.getGroup())
            .distinct();
    }

    private path(element: Node): HTMLElement[] {
        let current: Node | null = element;
        const path: HTMLElement[] = [];
        while (current !== null) {
            if (current instanceof HTMLElement) {
                path.push(current);
            }
            current = current.parentElement;
        }
        return path;
    }

    private findGroup(group: string): EqualHeightGroup | null {
        return this.equalHeightElements
            .map(element => element.getGroup())
            .findFirst(foundGroup => foundGroup.contains(group)) ?? null;
    }

    private fetchGroup(group: string): EqualHeightGroup {
        return this.findGroup(group) ?? new EqualHeightGroup(group);
    }

    private fetchElement(element: HTMLElement, group: EqualHeightGroup): EqualHeightElement {
        let equalHeightElement = this.equalHeightElements
            .findFirst(found => found.getElement() === element);
        if (!equalHeightElement) {
            equalHeightElement = group.newElement(element);
            this.equalHeightElements.push(equalHeightElement);
        } else if (equalHeightElement.getGroup() !== group) {
            equalHeightElement.updateGroup(group);
            equalHeightElement.resetHeight();
        }

        return equalHeightElement;
    }

    private applyHeights(group: EqualHeightGroup): void {
        this.equalHeightElements.forEach(element => {
            element.applyHeightIfPossible(group);
        });
    }

    private resetHeights(group: EqualHeightGroup): void {
        group.resetHeight();
        this.equalHeightElements
            .filter(element => element.canChangeHeightFor(group))
            .forEach(element => element.resetHeight());
    }

    private interpolateHeights(group: EqualHeightGroup): void {
        this.equalHeightElements
            .filter(element => element.canChangeHeightFor(group))
            .forEach(element => {
                const height = element.getHeight();
                group.proposeHeight(height);
            });
    }

    private flushScheduledGroups(): void {
        this.groups.forEach(group => this.updateHeights(group));
        this.groups = [];
    }

    private scheduleGroup(group: EqualHeightGroup): void {
        if (!this.groups.includes(group)) {
            this.groups.push(group);
        }
    }

}

class ResponsiveUpdate {
    public constructor(
        public element: HTMLElement,
        public groupName: (bp?: BootstrapBreakpoint) => string
    ) {
    }
}
